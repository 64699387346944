import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Lightbulb, Code, Settings, Rocket } from 'lucide-react';

const steps = [
  {
    icon: Lightbulb,
    title: 'Conception',
    description: "Analyse approfondie de vos besoins et élaboration d'une stratégie sur mesure.",
    color: 'bg-yellow-500',
    hoverGradient: 'from-yellow-500/20',
    iconColor: 'text-yellow-500',
    rotation: -3
  },
  {
    icon: Code,
    title: 'Développement',
    description: 'Création de votre solution avec les meilleures pratiques de développement.',
    color: 'bg-blue-500',
    hoverGradient: 'from-blue-500/20',
    iconColor: 'text-blue-500',
    rotation: -3
  },
  {
    icon: Settings,
    title: 'Tests',
    description: 'Tests rigoureux pour garantir la qualité et la performance.',
    color: 'bg-purple-500',
    hoverGradient: 'from-purple-500/20',
    iconColor: 'text-purple-500',
    rotation: -3
  },
  {
    icon: Rocket,
    title: 'Déploiement',
    description: 'Mise en ligne et support continu pour assurer votre succès.',
    color: 'bg-green-500',
    hoverGradient: 'from-green-500/20',
    iconColor: 'text-green-500',
    rotation: -3
  }
];

const Process = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 50, scale: 0.9 },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        duration: 0.7,
        ease: [0.43, 0.13, 0.23, 0.96]
      }
    }
  };

  const lineVariants = {
    hidden: { pathLength: 0, opacity: 0 },
    visible: {
      pathLength: 1,
      opacity: 1,
      transition: {
        duration: 1.5,
        ease: "easeInOut"
      }
    }
  };

  return (
    <section className="py-20 px-4 bg-black/30 relative" id="process">
      {/* Fond avec motif */}
      <div className="absolute inset-0 bg-[#0a0a0a] opacity-40" 
        style={{
          backgroundImage: `radial-gradient(circle at 1px 1px, rgb(239, 68, 68) 1px, transparent 0)`,
          backgroundSize: '40px 40px'
        }}
      />
      
      <div className="max-w-7xl mx-auto relative">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6 }}
          ref={ref}
          className="text-center mb-16"
        >
          <h2 className="text-2xl sm:text-4xl font-unbounded font-bold mb-4">Votre projet, étape par étape</h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-sm sm:text-base">
            Un processus clair et structuré pour transformer vos idées en réalité.
          </p>
        </motion.div>

        <div className="relative">
          {/* Connecting Lines */}
          <div className="absolute top-1/2 left-0 w-full h-0.5 hidden lg:block">
            <motion.div
              className="w-full h-full bg-gradient-to-r from-yellow-500 via-blue-500 to-green-500"
              initial={{ scaleX: 0, opacity: 0 }}
              animate={inView ? { scaleX: 1, opacity: 1 } : { scaleX: 0, opacity: 0 }}
              transition={{ duration: 1.5, ease: "easeInOut" }}
              style={{ transformOrigin: "left" }}
            />
          </div>

          <motion.div
            className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 lg:gap-6"
            variants={containerVariants}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
          >
            {steps.map((step, index) => (
              <motion.div
                key={index}
                variants={itemVariants}
                custom={index}
                style={{ 
                  rotate: step.rotation,
                  height: '280px'
                }}
                className="bg-black/50 backdrop-blur-lg p-4 sm:p-8 rounded-2xl relative group hover:rotate-0 transition-transform duration-500 flex flex-col items-center justify-center text-center"
                whileHover={{ 
                  scale: 1.05,
                  rotate: 0,
                  transition: { duration: 0.3 }
                }}
              >
                {/* Background gradient */}
                <div className={`absolute inset-0 bg-gradient-to-br ${step.hoverGradient} to-transparent rounded-2xl transition-opacity duration-500`} />
                
                {/* Icon container */}
                <motion.div
                  className={`w-12 h-12 sm:w-16 sm:h-16 ${step.color} rounded-2xl flex items-center justify-center mb-4 sm:mb-6 -rotate-12 group-hover:rotate-0 transition-transform duration-500`}
                  whileHover={{ scale: 1.1, rotate: 0 }}
                >
                  <step.icon className="w-6 h-6 sm:w-8 sm:h-8 text-white" />
                </motion.div>

                <div className="relative z-10">
                  <h3 className="text-lg sm:text-xl font-bold mb-2 sm:mb-4">{step.title}</h3>
                  <p className="text-sm sm:text-base text-gray-400">{step.description}</p>
                </div>

                {/* Number indicator */}
                <div className={`absolute -top-3 -right-3 w-8 h-8 ${step.color} rounded-full flex items-center justify-center text-white font-bold text-sm`}>
                  {index + 1}
                </div>

                {/* Bottom decoration line */}
                <motion.div
                  className={`absolute bottom-0 left-1/2 transform -translate-x-1/2 w-1/2 h-1 ${step.color} rounded-t-lg opacity-0 group-hover:opacity-100 transition-all duration-500`}
                  initial={{ scaleX: 0 }}
                  whileHover={{ scaleX: 1 }}
                />
              </motion.div>
            ))}
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Process;
import React, { useEffect } from 'react';
import { motion, useAnimationControls } from 'framer-motion';

interface AnimatedButtonProps {
  text: string;
  href: string;
  className?: string;
}

const AnimatedButton: React.FC<AnimatedButtonProps> = ({ text, href, className = '' }) => {
  const controls = useAnimationControls();
  
  useEffect(() => {
    const animateButton = async () => {
      // Attendre la fin de l'animation de typing (3s)
      await new Promise(resolve => setTimeout(resolve, 3000));
      
      // Animation de saut avec rebond
      await controls.start({
        y: [0, -20, 0, -10, 0, -5, 0],
        transition: {
          duration: 0.8,
          times: [0, 0.2, 0.4, 0.5, 0.6, 0.7, 0.8],
          ease: "easeInOut"
        }
      });
    };
    
    animateButton();
  }, [controls, text]);

  return (
    <motion.a
      href={href}
      className={`typing-animation ${className}`}
      animate={controls}
      data-text={text}
      style={{
        '--content-length': `${text.length}ch`,
      } as React.CSSProperties}
    >
      <span className="invisible">{text}</span>
      <span className="typing-text" aria-hidden="true">{text}</span>
    </motion.a>
  );
};

export default AnimatedButton;

import React, { useState } from 'react';
import { subscribeToNewsletter } from '../services/newsletter';
import toast from 'react-hot-toast';
import { motion } from 'framer-motion';
import { Mail } from 'lucide-react';

const NewsletterSubscribe: React.FC = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!email) {
      toast.error('Veuillez entrer votre adresse email.');
      return;
    }

    // Validation basique de l'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error('Veuillez entrer une adresse email valide.');
      return;
    }

    setIsLoading(true);

    try {
      const result = await subscribeToNewsletter(email);
      
      if (result.success) {
        toast.success(result.message);
        setEmail('');
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error('Une erreur est survenue. Veuillez réessayer.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="w-full max-w-md mx-auto">
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="relative">
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Votre adresse email"
            className="w-full px-4 py-3 rounded-lg bg-gray-900/50 backdrop-blur-md text-white placeholder-gray-400 border border-gray-700/30 focus:border-[#FF3366]/30 focus:outline-none focus:ring-1 focus:ring-[#FF3366] transition-all duration-200"
            disabled={isLoading}
          />
          <motion.button
            type="submit"
            disabled={isLoading}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
            className={`mt-3 w-full bg-[#FF3366] text-white py-3 px-6 rounded-lg font-medium 
              ${isLoading ? 'opacity-70 cursor-not-allowed' : 'hover:bg-[#FF3366]/90'} 
              transition-all duration-200 flex items-center justify-center gap-2`}
          >
            {isLoading ? (
              <div className="w-6 h-6 border-2 border-white border-t-transparent rounded-full animate-spin" />
            ) : (
              <>
                <Mail className="w-5 h-5" />
                S'abonner à la newsletter
              </>
            )}
          </motion.button>
        </div>
      </form>
    </div>
  );
};

export default NewsletterSubscribe;

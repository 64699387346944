import React from 'react';
import SEO from '../components/SEO/SEO';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Users, Award, Clock, Target, Rocket, Heart, Brain, Coffee, Code2 } from 'lucide-react';
import { pageSEOConfigs } from '../config/seo.config';
import TypewriterText from '../components/TypewriterText';
import RotatingText from '../components/RotatingText';

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const fadeInUp = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  const containerVariants = {
    hidden: {
      opacity: 0,
      scale: 1.5,
      rotate: -15
    },
    visible: {
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        type: 'spring',
        stiffness: 150,
        damping: 15,
        mass: 0.4,
        delay: 0.05
      }
    }
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      scale: 0,
      rotate: 45
    },
    visible: {
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        type: 'spring',
        stiffness: 150,
        damping: 12,
        mass: 0.4,
        delay: 0.1
      }
    }
  };

  const values = [
    {
      icon: Heart,
      title: 'Passion',
      description: 'Passionnés par l\'innovation et la technologie, nous mettons notre expertise au service de votre réussite.'
    },
    {
      icon: Brain,
      title: 'Expertise',
      description: 'Une équipe qualifiée et expérimentée dans les dernières technologies du web et du mobile.'
    },
    {
      icon: Rocket,
      title: 'Modernité',
      description: 'Toujours connectés aux tendances du digital pour vous offrir l\'excellence.'
    },
    {
      icon: Coffee,
      title: 'Engagement',
      description: 'Un accompagnement personnalisé et un engagement total dans chaque projet.'
    }
  ];

  const team = [
    {
      name: 'Designers',
      role: 'UI/UX & Design Graphique',
      image: '/Images/About/designers.AVIF',
      description: 'Création d\'interfaces modernes et d\'expériences utilisateur intuitives'
    },
    {
      name: 'Développeurs',
      role: 'Web & Mobile',
      image: '/Images/About/developers.AVIF',
      description: 'Experts en développement d\'applications web et mobiles performantes'
    },
    {
      name: 'Experts SEO',
      role: 'Référencement & Performance',
      image: '/Images/About/seo.AVIF',
      description: 'Optimisation du référencement et de la visibilité en ligne'
    }
  ];

  return (
    <div className="min-h-screen">
      {/* Métadonnées avec Helmet */}
      <SEO pageSEOConfig={pageSEOConfigs.about} />
      {/* Hero Section */}
      <section className="pt-36 sm:pt-40 lg:pt-48 pb-12 px-4 bg-gradient-to-b from-[#FF3366]/20 to-transparent">
        <div className="max-w-7xl mx-auto text-center">
          <motion.h1 
            className="font-unbounded text-2xl sm:text-3xl lg:text-4xl font-bold mb-8"
            variants={itemVariants}
          >
            <motion.div 
              className="relative inline-block my-2"
              variants={itemVariants}
            >
              <span className="relative z-10 text-white">À Propos</span>
              <motion.div
                className="absolute -left-1.5 sm:-left-3 -right-1.5 sm:-right-3 -top-1 -bottom-1 bg-[#FF3366] -skew-x-[20deg]"
                initial={{ scale: 0, rotate: 3 }}
                animate={{ scale: 1, rotate: 3 }}
                transition={{ duration: 0.6, delay: 0.3 }}
              />
            </motion.div>
          </motion.h1>
          <motion.p 
            className="text-gray-300 max-w-2xl mx-auto mb-8 text-sm sm:text-base lg:text-lg px-4 mt-6"
            variants={itemVariants}
          >
            Découvrez notre histoire, notre équipe et notre vision du développement web
          </motion.p>
        </div>
      </section>

      {/* Notre Parcours Section */}
      <section className="py-20 px-4 relative overflow-hidden">
        {/* Fond avec motif */}
        <div className="absolute inset-0 bg-[#0a0a0a] opacity-30" 
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgb(75, 85, 99) 1px, transparent 0)`,
            backgroundSize: '40px 40px'
          }}
        />
        
        <div className="max-w-7xl mx-auto relative">
          <motion.div 
            className="grid md:grid-cols-2 gap-12 items-center"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            {/* Texte */}
            <div className="relative z-10">
              <motion.h2 
                className="text-4xl md:text-5xl font-unbounded font-bold mb-8 bg-gradient-to-r from-white to-gray-400 text-transparent bg-clip-text"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.2, duration: 0.6 }}
              >
                Ce qui nous anime
              </motion.h2>
              <motion.div 
                className="space-y-8 text-lg text-gray-300"
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                viewport={{ once: true }}
                transition={{ delay: 0.4, duration: 0.6 }}
              >
                <p className="leading-relaxed">
                  Chez <span className="text-[#FF3366]">Izicode</span>, chaque projet est une opportunité de repousser les limites du digital.
                </p>
                <p className="leading-relaxed">
                  Qu'il s'agisse de concevoir des sites vitrines élégants ou de développer des applications mobiles cross-platform performantes, notre mission est claire : transformer vos idées en réalités numériques qui marquent.
                </p>
                <p className="leading-relaxed">
                  Nous croyons en un accompagnement sur-mesure, guidé par la passion de créer des solutions qui allient esthétisme, performance et impact.
                </p>
              </motion.div>
            </div>

            {/* Image */}
            <motion.div 
              className="relative z-10"
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2, duration: 0.6 }}
            >
              <div className="relative">
                {/* Cercle décoratif */}
                <div className="absolute -top-4 -right-4 w-24 h-24 bg-gradient-to-r from-[#FF3366] to-[#FF6666] rounded-full blur-2xl opacity-40" />
                
                {/* Image avec bordure et effet */}
                <div className="relative rounded-2xl overflow-hidden border border-gray-700 shadow-2xl">
                  <div className="absolute inset-0 bg-gradient-to-tr from-[#FF3366]/20 to-transparent mix-blend-overlay" />
                  <img
                    src="/Images/About/hero.AVIF"
                    alt="Notre équipe au travail"
                    className="w-full h-auto transform hover:scale-105 transition-transform duration-700"
                  />
                </div>

                {/* Points décoratifs */}
                <div className="absolute -bottom-4 -left-4 w-24 h-24 bg-blue-500/30 rounded-full blur-2xl" />
              </div>
            </motion.div>
          </motion.div>
        </div>
      </section>

      {/* Notre Philosophie */}
      <section className="py-20 px-4 bg-black/30">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <TypewriterText
              text="Notre Philosophie"
              className="text-4xl md:text-5xl font-unbounded font-bold mb-8"
              delay={80}
            />
            <p className="text-gray-400 max-w-2xl mx-auto">
              Chez <span className="text-[#FF3366]">Izicode</span>, chaque détail compte. Nous croyons qu'un projet réussi repose sur un équilibre parfait entre qualité, engagement et vision d'avenir.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <motion.div
              className="group relative bg-[#111111] dark:bg-[#111111] rounded-3xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="p-8 flex flex-col items-center text-center">
                <div className="mb-6 relative flex flex-col items-center">
                  <div className="absolute inset-0 bg-gradient-to-r from-[#FF3366]/20 to-purple-600/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <Code2 className="w-16 h-16 bg-gradient-to-r from-[#FF3366] to-purple-600 p-3 rounded-2xl text-white mb-4" />
                  <h3 className="text-2xl font-bold mb-2">La Minutie</h3>
                </div>
                <p className="text-gray-400">Un code propre et optimisé, conçu avec soin pour durer.</p>
              </div>
            </motion.div>

            <motion.div
              className="group relative bg-[#111111] dark:bg-[#111111] rounded-3xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="p-8 flex flex-col items-center text-center">
                <div className="mb-6 relative flex flex-col items-center">
                  <div className="absolute inset-0 bg-gradient-to-r from-[#FF3366]/20 to-purple-600/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <Heart className="w-16 h-16 bg-gradient-to-r from-[#FF3366] to-purple-600 p-3 rounded-2xl text-white mb-4" />
                  <h3 className="text-2xl font-bold mb-2">L'Engagement</h3>
                </div>
                <p className="text-gray-400">Plus qu'un service, une relation de confiance pour accompagner votre réussite.</p>
              </div>
            </motion.div>

            <motion.div
              className="group relative bg-[#111111] dark:bg-[#111111] rounded-3xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300"
              initial="hidden"
              animate="visible"
              variants={containerVariants}
            >
              <div className="p-8 flex flex-col items-center text-center">
                <div className="mb-6 relative flex flex-col items-center">
                  <div className="absolute inset-0 bg-gradient-to-r from-[#FF3366]/20 to-purple-600/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                  <Brain className="w-16 h-16 bg-gradient-to-r from-[#FF3366] to-purple-600 p-3 rounded-2xl text-white mb-4" />
                  <h3 className="text-2xl font-bold mb-2">La Vision</h3>
                </div>
                <p className="text-gray-400">Des solutions pensées pour aujourd'hui et prêtes pour demain.</p>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Nos Valeurs */}
      <section className="py-20 px-4 bg-black/30">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <h2 className="text-3xl font-unbounded font-bold mb-4">Nos Valeurs</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Des principes qui guident chacune de nos actions
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <motion.div
                key={index}
                className="relative group h-full"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
              >
                <div className="relative z-10 bg-gradient-to-b from-black/80 to-black/40 backdrop-blur-xl p-8 rounded-3xl border border-white/5 group-hover:border-[#FF3366]/20 transition-all duration-300 h-full flex flex-col">
                  {/* Effet de brillance */}
                  <div className="absolute inset-0 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                    <div className="absolute inset-x-0 top-0 h-px bg-gradient-to-r from-transparent via-[#FF3366]/40 to-transparent" />
                    <div className="absolute inset-x-0 bottom-0 h-px bg-gradient-to-r from-transparent via-[#FF3366]/40 to-transparent" />
                    <div className="absolute inset-y-0 left-0 w-px bg-gradient-to-b from-transparent via-[#FF3366]/40 to-transparent" />
                    <div className="absolute inset-y-0 right-0 w-px bg-gradient-to-b from-transparent via-[#FF3366]/40 to-transparent" />
                  </div>

                  <div className="relative flex flex-col items-center text-center h-full">
                    <div className="relative mb-6">
                      {/* Cercle décoratif */}
                      <div className="absolute inset-0 rounded-full bg-gradient-to-r from-[#FF3366]/20 to-purple-600/20 blur-lg transform group-hover:scale-150 transition-transform duration-500" />
                      <value.icon className="relative w-16 h-16 text-white p-3 transform group-hover:scale-110 transition-transform duration-300" />
                    </div>

                    <h3 className="text-2xl font-bold bg-gradient-to-r from-white to-white/80 bg-clip-text text-transparent mb-4">
                      {value.title}
                    </h3>
                    
                    <p className="text-gray-400 leading-relaxed flex-grow">
                      {value.description}
                    </p>

                    {/* Effet de particules */}
                    <div className="absolute inset-0 -z-10">
                      {[...Array(3)].map((_, i) => (
                        <motion.div
                          key={i}
                          className="absolute w-1 h-1 bg-[#FF3366]"
                          animate={{
                            opacity: [0, 1, 0],
                            scale: [0, 1.5, 0],
                            x: [0, Math.random() * 100 - 50],
                            y: [0, -100]
                          }}
                          transition={{
                            duration: 2,
                            repeat: Infinity,
                            delay: i * 0.6,
                            ease: "easeOut"
                          }}
                          style={{
                            left: `${50 + (i - 1) * 20}%`,
                            top: '80%'
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>

                {/* Effet de halo */}
                <div className="absolute -inset-1 bg-gradient-to-r from-[#FF3366]/0 via-[#FF3366]/10 to-purple-600/0 rounded-3xl blur-xl group-hover:via-[#FF3366]/20 transition-all duration-500 -z-10" />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Stats */}
      <section className="py-20 relative">
        {/* Fond avec motif */}
        <div className="absolute inset-0 bg-[#0a0a0a] opacity-40" 
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgb(239, 68, 68) 1px, transparent 0)`,
            backgroundSize: '40px 40px'
          }}
        />

        <div className="max-w-7xl mx-auto px-4">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-6 md:gap-10 relative">
            {[
              { icon: Users, value: '10+', label: 'Clients', color: '#FF3366' },
              { icon: Award, value: '10+', label: 'Projets', color: '#3B82F6' },
              { icon: Clock, value: '4+', label: 'Années', color: '#8B5CF6' },
              { icon: Target, value: '98%', label: 'Satisfaction', color: '#FF3366' }
            ].map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="group relative"
              >
                <div className="bg-black/30 p-8 rounded-2xl backdrop-blur-sm border border-gray-800">
                  {/* Ligne horizontale animée */}
                  <motion.div
                    className="absolute bottom-0 left-0 h-[2px]"
                    style={{ backgroundColor: stat.color }}
                    initial={{ width: 0 }}
                    whileInView={{ width: '100%' }}
                    viewport={{ once: true }}
                    transition={{ duration: 1, delay: index * 0.2 }}
                  />

                  {/* Contenu */}
                  <div className="flex flex-col items-start space-y-4">
                    {/* Icône */}
                    <div className="relative">
                      <motion.div
                        whileHover={{ scale: 1.1 }}
                        transition={{ type: "spring", stiffness: 400 }}
                      >
                        <stat.icon 
                          className="w-10 h-10" 
                          style={{ color: stat.color }}
                        />
                      </motion.div>
                    </div>

                    {/* Valeur */}
                    <motion.div
                      className="text-4xl md:text-5xl font-bold"
                      style={{ color: stat.color }}
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.5, delay: index * 0.1 + 0.3 }}
                    >
                      {stat.value}
                    </motion.div>

                    {/* Label */}
                    <p className="text-gray-400 font-medium tracking-wide text-sm uppercase">
                      {stat.label}
                    </p>
                  </div>

                  {/* Effet de survol */}
                  <div 
                    className="absolute inset-0 opacity-0 group-hover:opacity-10 transition-opacity duration-300"
                    style={{ 
                      background: `linear-gradient(45deg, transparent, ${stat.color}, transparent)`,
                    }}
                  />
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* L'Équipe */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center mb-16"
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
          >
            <h2 className="text-4xl md:text-5xl font-unbounded font-bold mb-8">Équipe et collaborateurs</h2>
            <p className="text-gray-400 max-w-2xl mx-auto">
              Collaboration avec des experts spécialisés pour chaque étape de votre projet.
            </p>
          </motion.div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {team.map((member, index) => (
              <motion.div
                key={index}
                className="bg-black/30 backdrop-blur-lg rounded-xl overflow-hidden group relative border border-gray-800/50 hover:border-[#FF3366]/50 transition-all duration-300"
                initial="hidden"
                animate="visible"
                variants={containerVariants}
              >
                <div className="relative overflow-hidden">
                  <img
                    src={member.image}
                    alt={member.name}
                    className="w-full h-64 object-cover transition-all duration-500 group-hover:scale-105"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent opacity-60 group-hover:opacity-80 transition-all duration-300" />
                </div>
                <div className="relative p-6 z-10">
                  <h3 className="text-2xl font-bold mb-2 group-hover:text-[#FF3366] transition-colors duration-300">{member.name}</h3>
                  <p className="text-[#FF3366] text-lg mb-3 opacity-90">{member.role}</p>
                  <p className="text-gray-400 group-hover:text-gray-300 transition-colors duration-300">{member.description}</p>
                </div>
                <div className="absolute inset-0 bg-gradient-to-r from-[#FF3366]/0 to-[#FF3366]/0 group-hover:from-[#FF3366]/5 group-hover:to-transparent transition-all duration-500" />
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* Conclusion */}
      <section className="py-20 px-4">
        <div className="max-w-7xl mx-auto">
          <motion.div 
            className="text-center"
            initial="hidden"
            animate="visible"
            variants={containerVariants}
          >
            <h2 className="text-3xl font-unbounded font-bold mb-6">
              Une Ambition Simple : {' '}
              <RotatingText
                words={['Votre Réussite', 'Votre Excellence', 'Votre Innovation']}
                delay={180}
              />
            </h2>
            <p className="text-xl text-gray-400 max-w-3xl mx-auto">
              Chez Izicode, chaque projet est un partenariat. Nous écoutons, nous comprenons, et nous concevons des solutions qui répondent à vos besoins spécifiques. Pas de solutions toutes faites, mais des créations uniques, pensées pour vous.
            </p>
          </motion.div>
        </div>
      </section>
    </div>
  );
};

export default About;
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { unsubscribeFromNewsletter } from '../services/newsletter';
import { motion } from 'framer-motion';

const Unsubscribe: React.FC = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const handleUnsubscribe = async () => {
      const token = searchParams.get('token');
      
      if (!token) {
        setStatus('error');
        setMessage('Token de désabonnement manquant.');
        return;
      }

      try {
        const result = await unsubscribeFromNewsletter(token);
        
        if (result.success) {
          setStatus('success');
        } else {
          setStatus('error');
        }
        setMessage(result.message);
      } catch (error) {
        setStatus('error');
        setMessage('Une erreur est survenue lors du désabonnement.');
      }
    };

    handleUnsubscribe();
  }, [searchParams]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full bg-white/5 backdrop-blur-xl p-8 rounded-2xl shadow-xl border border-white/10"
      >
        <div className="text-center">
          {status === 'loading' && (
            <div className="w-12 h-12 border-4 border-[#FF3366] border-t-transparent rounded-full animate-spin mx-auto" />
          )}
          
          {status === 'success' && (
            <div className="text-green-500 mb-4">
              <svg
                className="w-16 h-16 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          )}
          
          {status === 'error' && (
            <div className="text-red-500 mb-4">
              <svg
                className="w-16 h-16 mx-auto"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </div>
          )}

          <h2 className="text-2xl font-bold mb-4">
            {status === 'loading' ? 'Traitement en cours...' : 'Désabonnement Newsletter'}
          </h2>
          
          <p className="text-gray-300">
            {message}
          </p>

          {status !== 'loading' && (
            <a
              href="/"
              className="mt-6 inline-block bg-[#FF3366] text-white px-6 py-2 rounded-lg hover:bg-[#FF3366]/90 transition-colors"
            >
              Retour à l'accueil
            </a>
          )}
        </div>
      </motion.div>
    </div>
  );
};

export default Unsubscribe;

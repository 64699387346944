import { collection, addDoc, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../lib/firebase';
import { v4 as uuidv4 } from 'uuid';
import { NewsletterSubscriber } from '../types/newsletter';

const COLLECTION_NAME = 'newsletter_subscribers';

export const subscribeToNewsletter = async (email: string): Promise<{ success: boolean; message: string }> => {
  try {
    // Vérifier si l'email existe déjà
    const q = query(collection(db, COLLECTION_NAME), where("email", "==", email));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const doc = querySnapshot.docs[0];
      const subscriber = doc.data() as NewsletterSubscriber;
      
      if (subscriber.isActive) {
        return { success: false, message: 'Cet email est déjà inscrit à la newsletter.' };
      }
      if (!subscriber.isConfirmed) {
        return { success: false, message: 'Un email de confirmation vous a déjà été envoyé. Veuillez vérifier votre boîte de réception.' };
      }
    }

    // Créer les tokens
    const confirmToken = uuidv4();
    const unsubscribeToken = uuidv4();

    // Créer le nouvel abonné
    const subscriber: NewsletterSubscriber = {
      email,
      subscriptionDate: new Date(),
      isActive: false,
      isConfirmed: false,
      confirmToken,
      unsubscribeToken
    };

    // Ajouter à Firestore
    await addDoc(collection(db, COLLECTION_NAME), subscriber);

    // TODO: Envoyer l'email de confirmation via Brevo
    // Cette partie sera gérée par la Cloud Function

    return {
      success: true,
      message: 'Un email de confirmation vous a été envoyé. Veuillez vérifier votre boîte de réception pour finaliser votre inscription.'
    };
  } catch (error) {
    console.error('Erreur lors de l\'inscription à la newsletter:', error);
    return { success: false, message: 'Une erreur est survenue lors de l\'inscription.' };
  }
};

export const confirmNewsletterSubscription = async (token: string): Promise<{ success: boolean; message: string }> => {
  try {
    console.log('Tentative de confirmation avec le token:', token);
    const q = query(collection(db, COLLECTION_NAME), where("confirmToken", "==", token));
    const querySnapshot = await getDocs(q);

    console.log('Résultat de la recherche:', {
      empty: querySnapshot.empty,
      size: querySnapshot.size
    });

    if (querySnapshot.empty) {
      console.log('Aucun abonné trouvé avec ce token');
      return { success: false, message: 'Token de confirmation invalide.' };
    }

    const subscriberDoc = querySnapshot.docs[0];
    const subscriber = subscriberDoc.data() as NewsletterSubscriber;
    console.log('Abonné trouvé:', {
      id: subscriberDoc.id,
      email: subscriber.email,
      isConfirmed: subscriber.isConfirmed,
      isActive: subscriber.isActive
    });

    if (subscriber.isConfirmed) {
      console.log('Inscription déjà confirmée');
      return { success: false, message: 'Cette inscription a déjà été confirmée.' };
    }

    console.log('Mise à jour du document...');
    await updateDoc(doc(db, COLLECTION_NAME, subscriberDoc.id), {
      isConfirmed: true,
      isActive: true,
      confirmationDate: new Date()
    });
    console.log('Document mis à jour avec succès');

    return {
      success: true,
      message: 'Votre inscription à la newsletter a été confirmée avec succès !'
    };
  } catch (error) {
    console.error('Erreur lors de la confirmation de l\'inscription:', error);
    return { success: false, message: 'Une erreur est survenue lors de la confirmation.' };
  }
};

export const unsubscribeFromNewsletter = async (token: string): Promise<{ success: boolean; message: string }> => {
  try {
    const q = query(collection(db, COLLECTION_NAME), where("unsubscribeToken", "==", token));
    const querySnapshot = await getDocs(q);

    if (querySnapshot.empty) {
      return { success: false, message: 'Token de désabonnement invalide.' };
    }

    const subscriberDoc = querySnapshot.docs[0];
    await updateDoc(doc(db, COLLECTION_NAME, subscriberDoc.id), {
      isActive: false,
      unsubscriptionDate: new Date()
    });

    return {
      success: true,
      message: 'Vous avez été désinscrit de la newsletter avec succès.'
    };
  } catch (error) {
    console.error('Erreur lors du désabonnement:', error);
    return { success: false, message: 'Une erreur est survenue lors du désabonnement.' };
  }
};

import React from 'react';
import { motion } from 'framer-motion';
import { FileX } from 'lucide-react';
import { Link } from 'react-router-dom';

const NotFoundPost: React.FC = () => {
  return (
    <div className="min-h-[60vh] flex items-center justify-center px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-lg w-full text-center"
      >
        <div className="mb-8">
          <FileX className="w-20 h-20 mx-auto text-pink-500 mb-4" />
          <h1 className="text-3xl font-bold mb-4">Article non trouvé</h1>
          <p className="text-gray-400 mb-8">
            Désolé, l'article que vous recherchez n'existe pas ou a été supprimé. 
            Découvrez nos autres articles sur le blog !
          </p>
          <Link
            to="/blog"
            className="inline-flex items-center justify-center px-6 py-3 bg-pink-500 text-white rounded-lg hover:bg-pink-600 transition-colors"
          >
            Voir tous les articles
          </Link>
        </div>
        
        <div className="text-sm text-gray-500">
          Si vous pensez qu'il s'agit d'une erreur, n'hésitez pas à nous contacter.
        </div>
      </motion.div>
    </div>
  );
};

export default NotFoundPost;

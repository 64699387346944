import React, { useState, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { createPortal } from 'react-dom';
import { Code2, Smartphone, Search, PenTool, CheckCircle2, ArrowRight } from 'lucide-react';

const illustrations = {
  web: (color: string) => (
    <svg className="w-48 h-48 opacity-[0.02] absolute right-4 top-1/2 -translate-y-1/2" viewBox="0 0 24 24" fill="none">
      <path d="M3 3h18v18H3V3z" stroke={color} strokeWidth="1.5"/>
      <path d="M3 7h18M7 3v4M3 11l9 9 9-9" stroke={color} strokeWidth="1.5"/>
    </svg>
  ),
  mobile: (color: string) => (
    <svg className="w-48 h-48 opacity-[0.02] absolute right-4 top-1/2 -translate-y-1/2" viewBox="0 0 24 24" fill="none">
      <rect x="5" y="2" width="14" height="20" rx="2" stroke={color} strokeWidth="1.5"/>
      <path d="M10 20h4M12 4v12" stroke={color} strokeWidth="1.5"/>
    </svg>
  ),
  marketing: (color: string) => (
    <svg className="w-48 h-48 opacity-[0.02] absolute right-4 top-1/2 -translate-y-1/2" viewBox="0 0 24 24" fill="none">
      <circle cx="11" cy="11" r="8" stroke={color} strokeWidth="1.5"/>
      <path d="M21 21l-4.35-4.35M11 8v6M8 11h6" stroke={color} strokeWidth="1.5"/>
    </svg>
  ),
  design: (color: string) => (
    <svg className="w-48 h-48 opacity-[0.02] absolute right-4 top-1/2 -translate-y-1/2" viewBox="0 0 24 24" fill="none">
      <path d="M12 2l9 4.5L12 11 3 6.5 12 2z" stroke={color} strokeWidth="1.5"/>
      <path d="M3 6.5v11l9 4.5m0-11v11m9-15.5v11l-9 4.5" stroke={color} strokeWidth="1.5"/>
    </svg>
  )
};

const services = [
  {
    id: 'web',
    icon: Code2,
    title: 'Sites Web',
    color: 'text-blue-500',
    image: 'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2426&auto=format&fit=crop',
    features: [
      'Sites vitrines pour entreprises et indépendants',
      'E-commerce avec paiements sécurisés',
      'Sites sur-mesure adaptés à vos besoins',
      'Design responsive et moderne',
      'Optimisation SEO intégrée',
      'Hébergement et maintenance'
    ]
  },
  {
    id: 'mobile',
    icon: Smartphone,
    title: 'Apps Mobile',
    color: 'text-green-500',
    image: 'https://images.unsplash.com/photo-1512941937669-90a1b58e7e9c?q=80&w=2340&auto=format&fit=crop',
    features: [
      'Applications iOS et Android performantes',
      'Solutions hybrides avec React Native',
      'Maintenance et mises à jour',
      'Intégration des dernières technologies',
      'Tests et déploiement sur les stores',
      'Support technique continu'
    ]
  },
  {
    id: 'marketing',
    icon: Search,
    title: 'Marketing Digital',
    color: 'text-purple-500',
    image: 'https://images.unsplash.com/photo-1533750349088-cd871a92f312?q=80&w=2340&auto=format&fit=crop',
    features: [
      'Stratégies SEO pour augmenter votre visibilité',
      'Campagnes publicitaires Google Ads et Facebook Ads',
      'Gestion des réseaux sociaux',
      'Analyse de données et rapports',
      'Optimisation des conversions',
      'Stratégie de contenu'
    ]
  },
  {
    id: 'design',
    icon: PenTool,
    title: 'Design UX/UI',
    color: 'text-pink-500',
    image: 'https://images.unsplash.com/photo-1561070791-2526d30994b5?q=80&w=2264&auto=format&fit=crop',
    features: [
      'Interfaces modernes et ergonomiques',
      'Prototypage et amélioration de l\'expérience utilisateur',
      'Design System personnalisé',
      'Maquettes responsive',
      'Tests utilisateurs',
      'Guidelines et documentation'
    ]
  }
];

const tabVariants = {
  active: {
    scale: 1.05,
    transition: {
      type: "spring",
      stiffness: 300,
      damping: 20
    }
  },
  inactive: {
    scale: 1
  }
};

const contentVariants = {
  enter: {
    opacity: 0,
    y: 20,
  },
  center: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.3
    }
  },
  exit: {
    opacity: 0,
    y: -20,
    transition: {
      duration: 0.3
    }
  }
};

const sectionVariants = {
  hidden: {
    opacity: 0,
    y: 50
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.8,
      ease: [0.43, 0.13, 0.23, 0.96],
      staggerChildren: 0.1
    }
  }
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.5,
      ease: [0.43, 0.13, 0.23, 0.96]
    }
  }
};

import Modal from './Modal';

const Services = () => {
  const [activeTab, setActiveTab] = useState('web');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [ref, inView] = useInView({
    threshold: 0.3,
    rootMargin: '-10% 0px -10% 0px',
    triggerOnce: true
  });

  const changeTab = (newTab: string, shouldScroll: boolean = false) => {
    setActiveTab(newTab);
  };

  const handleTabClick = (tabId: string) => {
    changeTab(tabId, true);
  };

  const activeService = services.find(service => service.id === activeTab);
  const activeColor = activeService?.color.replace('text-', 'bg-') || '';

  return (
    <motion.section 
      ref={ref}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      variants={{
        hidden: {
          opacity: 0,
          y: 20
        },
        visible: {
          opacity: 1,
          y: 0,
          transition: {
            duration: 0.6,
            ease: "easeOut",
            staggerChildren: 0.2
          }
        }
      }}
      className="px-5 relative overflow-hidden mt-16 pt-2"
      id="services"
    >
      <div className="max-w-7xl mx-auto">
        <motion.div variants={itemVariants}>
          <h2 className="text-center font-unbounded font-bold text-2xl sm:text-4xl mb-4">
            Ce que nous proposons
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-center text-sm sm:text-base mb-16">
            Des solutions sur mesure pour répondre à tous vos besoins digitaux
          </p>
        </motion.div>

        <motion.div variants={itemVariants}>
          {/* Tabs Navigation */}
          <div 
            className="overflow-x-auto pb-4 -mb-4 mb-8 sm:mb-8 scroll-smooth pt-2 scrollbar-none"
            style={{
              msOverflowStyle: 'none',
              scrollbarWidth: 'none',
              WebkitOverflowScrolling: 'touch'
            }}
          >
            <style jsx>{`
              div::-webkit-scrollbar {
                display: none;
              }
            `}</style>
            <div 
              className="flex justify-start md:justify-center gap-4 min-w-max px-4 py-1"
            >
              {services.map((service) => (
                <motion.button
                  key={service.id}
                  variants={tabVariants}
                  animate={activeTab === service.id ? "active" : "inactive"}
                  onClick={() => handleTabClick(service.id)}
                  className={`flex items-center gap-2 px-3 sm:px-5 py-2 sm:py-3 rounded-full transition-colors text-sm sm:text-base whitespace-nowrap
                    sm:border-0 border border-white/5
                    ${activeTab === service.id 
                      ? `${service.color} ${service.color.replace('text', 'bg')}/10 backdrop-blur-sm font-medium` 
                      : 'text-gray-400 bg-white/5 hover:text-gray-300'
                    }`}
                >
                  <service.icon className="w-4 h-4 sm:w-5 sm:h-5" />
                  <span className="font-medium">{service.title}</span>
                </motion.button>
              ))}
            </div>
          </div>
        </motion.div>

        <motion.div variants={itemVariants}>
          {/* Content */}
          <AnimatePresence mode="wait">
            <motion.div
              key={activeTab}
              variants={contentVariants}
              initial="enter"
              animate="center"
              exit="exit"
              className="relative"
            >
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
                <motion.div 
                  variants={itemVariants}
                  className="relative p-4 sm:p-8 rounded-3xl bg-neutral-900/50 backdrop-blur-sm border border-white/5 flex flex-col items-center h-full"
                >
                  <div className="flex flex-col items-center w-full">
                    <div className="w-full h-32 sm:h-48 md:h-64 rounded-2xl overflow-hidden mb-4 sm:mb-8 relative">
                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent z-10" />
                      <img 
                        src={activeService?.image} 
                        alt={activeService?.title}
                        className="w-full h-full object-cover"
                        loading="eager"
                      />
                    </div>
                    <div className="text-center z-10">
                      <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3">{activeService?.title}</h3>
                      <p className="text-sm sm:text-base text-white/60">Découvrez nos solutions adaptées à vos besoins</p>
                    </div>
                  </div>
                </motion.div>

                <motion.div 
                  variants={itemVariants}
                  onClick={() => setIsModalOpen(true)}
                  className="p-4 sm:p-8 rounded-3xl bg-neutral-900/50 backdrop-blur-sm border border-white/5
                    cursor-pointer hover:scale-105 transition-all duration-300 ease-out
                    hover:shadow-lg hover:shadow-blue-500/10"
                >
                  <ul className="space-y-4">
                    {activeService?.features.map((feature, index) => (
                      <motion.li 
                        key={index}
                        variants={itemVariants}
                        className="flex items-start gap-3"
                      >
                        <CheckCircle2 className={`w-5 h-5 mt-0.5 ${activeService.color}`} />
                        <span className="text-white/70">{feature}</span>
                      </motion.li>
                    ))}
                  </ul>
                </motion.div>
              </div>
            </motion.div>
          </AnimatePresence>

          {/* CTA Button */}
          <div className="flex justify-center mt-12">
            <a
              href="/services"
              className={`inline-flex items-center gap-2 px-6 py-3 rounded-full font-medium transition-colors
                bg-black/60 backdrop-blur-sm ${activeService?.color} hover:bg-black/40
                sm:border-0 border border-white/5`}
            >
              Tous nos services
              <ArrowRight className="w-4 h-4" />
            </a>
          </div>
        </motion.div>

        <AnimatePresence mode="wait">
          {isModalOpen && (
            <Modal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              title={activeService?.title || ''}
            >
              <div className="space-y-6">
                {activeService?.features.map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ delay: index * 0.1 }}
                    className="flex items-start gap-4"
                  >
                    <CheckCircle2 className={`w-6 h-6 mt-0.5 ${activeService.color}`} />
                    <div>
                      <p className="text-lg text-white/90">{feature}</p>
                    </div>
                  </motion.div>
                ))}
              </div>
            </Modal>
          )}
        </AnimatePresence>
      </div>
    </motion.section>
  );
};

export default Services;
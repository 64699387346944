import React, { useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoClose } from 'react-icons/io5';
import { Monitor, MessageSquareCode } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { ArrowRight } from 'lucide-react';

interface QuoteModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const QuoteModal: React.FC<QuoteModalProps> = ({ isOpen, onClose }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  const handleNavigation = (path: string) => {
    onClose();
    navigate(path);
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="absolute inset-0 bg-black/80 backdrop-blur-sm"
          />
          
          {/* Modal */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.95 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="relative w-[95%] max-w-2xl mx-auto"
          >
            <div className="relative bg-[#0A0A0A]/60 backdrop-blur-xl border border-white/5 rounded-2xl p-10 shadow-2xl">
              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute right-5 top-5 text-white/60 hover:text-white transition-colors"
              >
                <IoClose size={24} />
              </button>

              {/* Content */}
              <div className="text-center mb-10">
                <h3 className="text-2xl font-semibold mb-3">Choisissez votre méthode de devis</h3>
                <p className="text-gray-400">Sélectionnez l'option qui vous convient le mieux</p>
              </div>

              <div className="grid gap-6">
                {/* Bouton Chat IA */}
                <button
                  onClick={() => handleNavigation('/devis-chat')}
                  className="flex items-center justify-between w-full p-6 bg-gradient-to-r from-[#FF3366]/10 to-[#FF3366]/5 hover:from-[#FF3366]/20 hover:to-[#FF3366]/10 border border-[#FF3366]/20 rounded-xl transition-all duration-300"
                >
                  <div className="flex items-center gap-5">
                    <div className="w-14 h-14 rounded-lg bg-[#FF3366]/20 flex items-center justify-center">
                      <MessageSquareCode className="w-7 h-7 text-[#FF3366]" />
                    </div>
                    <div className="text-left">
                      <div className="flex items-center gap-2 mb-1">
                        <h4 className="font-medium text-lg">Notre Chat IA</h4>
                        <span className="px-2 py-0.5 text-xs font-medium bg-blue-500/20 text-blue-400 rounded-full">beta</span>
                      </div>
                      <p className="text-sm text-gray-400">Obtenez un devis rapide avec notre assistant virtuel</p>
                    </div>
                  </div>
                  <ArrowRight className="w-6 h-6 text-[#FF3366]" />
                </button>

                {/* Bouton Formulaire classique */}
                <button
                  onClick={() => handleNavigation('/devis')}
                  className="flex items-center justify-between w-full p-6 bg-white/5 hover:bg-white/10 border border-white/10 rounded-xl transition-all duration-300"
                >
                  <div className="flex items-center gap-5">
                    <div className="w-14 h-14 rounded-lg bg-white/10 flex items-center justify-center">
                      <Monitor className="w-7 h-7 text-white" />
                    </div>
                    <div className="text-left">
                      <h4 className="font-medium text-lg mb-1">Formulaire classique</h4>
                      <p className="text-sm text-gray-400">Remplissez notre formulaire détaillé</p>
                    </div>
                  </div>
                  <ArrowRight className="w-6 h-6" />
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default QuoteModal;

import React from 'react';
import { useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';

interface ErrorMessages {
  [key: string]: {
    title: string;
    message: string;
    image?: string;
  };
}

const errorMessages: ErrorMessages = {
  '404': {
    title: 'Page introuvable !',
    message: 'Oups ! On dirait que vous vous êtes égaré dans le code source. Même notre IA n\'arrive pas à trouver cette page ! 🤖',
  },
  '500': {
    title: 'Erreur serveur',
    message: 'Notre serveur fait sa crise d\'adolescence... Il refuse de coopérer pour le moment ! 🎭',
  },
  '503': {
    title: 'Service indisponible',
    message: 'Notre site fait une petite sieste technique. On revient plus fort que jamais ! 😴',
  },
  '408': {
    title: 'Temps d\'attente dépassé',
    message: 'Le temps s\'est écoulé plus vite qu\'un développeur devant sa deadline ! ⏰',
  },
  '418': {
    title: 'Je suis une théière',
    message: 'Je suis une théière, et je refuse catégoriquement de faire du café ! ☕',
  }
};

const ErrorPage: React.FC = () => {
  const location = useLocation();
  const errorCode = location.pathname.split('/error/')[1] || '404';
  const error = errorMessages[errorCode] || errorMessages['404'];

  return (
    <div className="min-h-screen flex flex-col items-center justify-center p-8 text-center">
      <h1 className="text-[150px] font-extrabold mb-4 bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text">
        {errorCode}
      </h1>
      <h2 className="text-4xl font-bold mb-6 text-gray-800 dark:text-white">
        {error.title}
      </h2>
      <p className="text-2xl mb-12 text-gray-700 dark:text-gray-300 max-w-2xl">
        {error.message}
      </p>
      <Link
        to="/"
        className="text-xl bg-gradient-to-r from-blue-500 to-purple-600 text-white font-semibold px-8 py-4 rounded-lg hover:opacity-90 transition-opacity"
      >
        Retour à l'accueil
      </Link>
    </div>
  );
};

export default ErrorPage;

import { Rocket, Code, Layout, Search, DollarSign, User } from 'lucide-react';

export const steps = [
  { icon: Rocket, label: "Informations générales" },
  { icon: Code, label: "Spécifications techniques" },
  { icon: Layout, label: "Design" },
  { icon: Search, label: "SEO & Visibilité" },
  { icon: DollarSign, label: "Budget" },
  { icon: User, label: "Contact" }
];

import { SEOProps } from '../types/seo.types';

const BASE_URL = 'https://izicode.fr';

const DEFAULT_OG_IMAGE = `${BASE_URL}/og.png`;


export const pageSEOConfigs: Record<string, SEOProps> = {
  home: {
    title: 'Izicode | Solutions Web & Mobile Sur Mesure',
    description: 'Créez votre présence en ligne avec Izicode. Solutions web et mobiles sur mesure, expertise technique et accompagnement personnalisé.',
    keywords: ['création site web', 'développement application', 'solutions numériques', 'agence web Amiens', 'développeur freelance'],
    canonical: `${BASE_URL}`,
    ogType: 'website',
    ogTitle: 'Izicode | Développement Web & Mobile Sur Mesure',
    ogDescription: 'Agence de développement web et mobile à Amiens. Expertise technique et solutions numériques personnalisées pour votre entreprise.',
    ogImage: `${DEFAULT_OG_IMAGE}`,
    twitterCard: 'summary_large_image',
    language: 'fr',
  },
  about: {
    title: 'À Propos | Izicode',
    description: 'Découvrez Izicode, votre partenaire digital à Amiens. Une expertise technique pointue au service de vos projets numériques.',
    keywords: ['agence web Amiens', 'expertise numérique', 'développeur professionnel', 'solutions digitales', 'projets web'],
    canonical: `${BASE_URL}/about`,
    ogType: 'website',
    ogTitle: 'À Propos | Izicode - Votre Partenaire Digital',
    ogDescription: 'Découvrez Izicode, votre partenaire digital à Amiens. Une expertise technique pointue au service de vos projets numériques.',
    ogImage: `${DEFAULT_OG_IMAGE}`,
    twitterCard: 'summary_large_image',
    language: 'fr',
  },
  services: {
    title: 'Services | Izicode',
    description: 'Sites web, applications mobiles et solutions e-commerce sur mesure. Transformez votre vision en réalité avec Izicode.',
    keywords: ['création site internet', 'développement application mobile', 'e-commerce', 'site vitrine', 'application web', 'maintenance web'],
    canonical: `${BASE_URL}/services`,
    ogType: 'website',
    ogTitle: 'Services Web & Mobile | Izicode',
    ogDescription: 'Sites web, applications mobiles et solutions e-commerce sur mesure. Transformez votre vision en réalité avec Izicode.',
    ogImage: `${DEFAULT_OG_IMAGE}`,
    twitterCard: 'summary_large_image',
    language: 'fr',
  },
  projects: {
    title: 'Nos Réalisations | Izicode',
    description: 'Découvrez nos réalisations web et mobile. Des solutions innovantes et performantes adaptées aux besoins de nos clients.',
    keywords: ['portfolio web', 'réalisations numériques', 'projets applications', 'références clients', 'cas clients'],
    canonical: `${BASE_URL}/projects`,
    ogType: 'website',
    ogTitle: 'Portfolio | Izicode - Nos Réalisations',
    ogDescription: 'Découvrez nos réalisations web et mobile. Des solutions innovantes et performantes adaptées aux besoins de nos clients.',
    ogImage: `${DEFAULT_OG_IMAGE}`,
    twitterCard: 'summary_large_image',
    language: 'fr',
  },
  faq: {
    title: 'FAQs | Izicode',
    description: 'Trouvez les réponses à vos questions sur nos services de développement web et mobile. Guide complet pour comprendre nos solutions.',
    keywords: ['faq développement web', 'questions fréquentes', 'aide izicode', 'support technique', 'guide services web'],
    canonical: `${BASE_URL}/faq`,
    ogType: 'website',
    ogTitle: 'FAQs | Izicode - Questions Fréquentes',
    ogDescription: 'Consultez nos FAQs pour tout savoir sur nos services de développement web et mobile. Réponses claires à vos questions techniques.',
    ogImage: `${DEFAULT_OG_IMAGE}`,
    twitterCard: 'summary_large_image',
    language: 'fr',
  },
  blog: {
    title: 'Blog | Izicode',
    description: 'Conseils, tendances et actualités du numérique. Restez informé des meilleures pratiques pour votre présence en ligne.',
    keywords: ['blog tech', 'actualités web', 'conseils numériques', 'tendances digitales', 'astuces développement'],
    canonical: `${BASE_URL}/blog`,
    ogType: 'website',
    ogTitle: 'Blog Izicode | Actualités & Conseils',
    ogDescription: 'Conseils, tendances et actualités du numérique. Restez informé des meilleures pratiques pour votre présence en ligne.',
    ogImage: `${DEFAULT_OG_IMAGE}`,
    twitterCard: 'summary_large_image',
    language: 'fr',
  },
  quoteAI: {
    title: 'Devis Instantané | Izicode',
    description: 'Obtenez un devis instantané pour votre projet web ou mobile grâce à notre assistant IA. Estimation rapide et personnalisée.',
    keywords: ['devis en ligne', 'estimation projet web', 'tarif site internet', 'coût application', 'devis instantané'],
    canonical: `${BASE_URL}/quoteAI`,
    ogType: 'website',
    ogTitle: 'Devis Instantané avec IA | Izicode',
    ogDescription: 'Obtenez un devis instantané pour votre projet web ou mobile grâce à notre assistant IA. Estimation rapide et personnalisée.',
    ogImage: `${DEFAULT_OG_IMAGE}`,
    twitterCard: 'summary_large_image',
    language: 'fr',
  },
  quote: {
    title: 'Demande de Devis | Izicode',
    description: 'Décrivez votre projet et obtenez un devis personnalisé. Notre équipe étudie vos besoins pour une proposition sur mesure.',
    keywords: ['devis personnalisé', 'estimation projet', 'tarification web', 'coût développement', 'budget site internet'],
    canonical: `${BASE_URL}/quote`,
    ogType: 'website',
    ogTitle: 'Demande de Devis Personnalisé | Izicode',
    ogDescription: 'Décrivez votre projet et obtenez un devis personnalisé. Notre équipe étudie vos besoins pour une proposition sur mesure.',
    ogImage: `${DEFAULT_OG_IMAGE}`,
    twitterCard: 'summary_large_image',
    language: 'fr',
  },
  contact: {
    title: 'Contact | Izicode',
    description: 'Contactez Izicode pour discuter de votre projet. Notre équipe est à votre écoute pour transformer vos idées en solutions.',
    keywords: ['contact agence web', 'développeur Amiens', 'consultation gratuite', 'devis projet', 'agence digitale'],
    canonical: `${BASE_URL}/contact`,
    ogType: 'website',
    ogTitle: 'Contactez Izicode | Discutons de Votre Projet',
    ogDescription: 'Contactez Izicode pour discuter de votre projet. Notre équipe est à votre écoute pour transformer vos idées en solutions.',
    ogImage: `${DEFAULT_OG_IMAGE}`,
    twitterCard: 'summary_large_image',
    language: 'fr',
  },
  mentionsLegales: {
    title: 'Mentions Légales | Izicode',
    description: 'Mentions légales et informations juridiques d\'Izicode. Consultez nos obligations légales et informations d\'entreprise.',
    keywords: ['mentions légales', 'informations juridiques', 'conditions légales'],
    canonical: `${BASE_URL}/mentions-legales`,
    ogType: 'website',
    noindex: true,
    language: 'fr',
  },
  cgu: {
    title: 'CGU | Izicode',
    description: 'Conditions générales d\'utilisation des services Izicode. Consultez nos termes et conditions pour l\'utilisation de nos services.',
    keywords: ['conditions utilisation', 'CGU', 'termes services', 'règles utilisation'],
    canonical: `${BASE_URL}/cgu`,
    ogType: 'website',
    noindex: true,
    language: 'fr',
  },
  politiqueConfidentialite: {
    title: 'Politique de Confidentialité | Izicode',
    description: 'Notre engagement pour la protection de vos données personnelles. Découvrez comment nous protégeons votre vie privée.',
    keywords: ['confidentialité', 'protection données', 'RGPD', 'vie privée', 'données personnelles'],
    canonical: `${BASE_URL}/politique-confidentialite`,
    ogType: 'website',
    noindex: true,
    language: 'fr',
  },
  politiqueCookies: {
    title: 'Politique de Cookies | Izicode',
    description: 'Informations sur l\'utilisation des cookies sur notre site. Comprendre et gérer les cookies pour une meilleure expérience.',
    keywords: ['politique cookies', 'gestion cookies', 'traceurs', 'confidentialité web'],
    canonical: `${BASE_URL}/politique-cookies`,
    ogType: 'website',
    noindex: true,
    language: 'fr',
  },
  unsubscribe: {
    title: 'Désinscription | Izicode',
    description: 'Gérez vos préférences de communication. Désinscrivez-vous de notre newsletter en un clic.',
    keywords: ['désinscription newsletter', 'gestion abonnement', 'préférences email'],
    canonical: `${BASE_URL}/unsubscribe`,
    ogType: 'website',
    noindex: true,
    language: 'fr',
  },
  confirmNewsletter: {
    title: 'Confirmation Newsletter | Izicode',
    description: 'Confirmez votre inscription à notre newsletter pour recevoir nos actualités et conseils.',
    keywords: ['confirmation newsletter', 'inscription actualités', 'abonnement email'],
    canonical: `${BASE_URL}/confirm-newsletter`,
    ogType: 'website',
    noindex: true,
    language: 'fr',
  },
};

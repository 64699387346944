import React, { useState, useCallback, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { FormData } from '../types/form';
import { 
  Zap, 
  Diamond, 
  Building2, 
  Lightbulb, 
  Gamepad2, 
  HelpCircle,
  Palette,
  Link2,
  FileText,
  X,
  Send,
  Loader2,
  Upload,
  File,
  Check,
  AlertTriangle,
  Paperclip,
  AlertCircle
} from 'lucide-react';
import { useDropzone } from 'react-dropzone';

type Props = {
  data: FormData;
  onChange: (field: keyof FormData, value: any) => void;
};

export const FormStep3: React.FC<Props> = ({ data, onChange }) => {
  const [currentLink, setCurrentLink] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [linkPreviews, setLinkPreviews] = useState<{
    url: string;
    metadata?: {
      title?: string;
      description?: string;
      image?: string;
    }
  }[]>(data.inspirationSites || []);

  // États pour le téléchargement de la charte graphique
  const [styleGuideFiles, setStyleGuideFiles] = useState<File[]>(data.styleGuideFiles || []);
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'uploading' | 'success' | 'error'>('idle');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadError, setUploadError] = useState<string | null>(null);
  const [showToast, setShowToast] = useState<{
    message: string;
    type: 'error' | 'success';
  } | null>(null);

  const allowedTypes = [
    'application/pdf',
    'application/msword', 
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'text/plain',
    'image/png',
    'image/jpeg',
    'image/gif'
  ];

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Calculer la taille totale des fichiers existants et nouveaux
    const currentTotalSize = styleGuideFiles.reduce((total, file) => total + file.size, 0);
    const newFilesToAdd = acceptedFiles.filter(file => {
      // Vérifier le type de fichier
      if (!allowedTypes.includes(file.type)) {
        setShowToast({
          message: `Type de fichier non pris en charge pour ${file.name}. Utilisez PDF, DOC, DOCX, TXT, PNG, JPEG ou GIF.`,
          type: 'error'
        });
        return false;
      }

      // Vérifier la taille du fichier individuel
      if (file.size > 2 * 1024 * 1024) { // 2MB max par fichier
        setShowToast({
          message: `Le fichier ${file.name} est trop volumineux. Taille maximale : 2 Mo.`,
          type: 'error'
        });
        return false;
      }

      return true;
    });

    // Vérifier le nombre total de fichiers et la taille totale
    const totalFiles = styleGuideFiles.length + newFilesToAdd.length;
    const totalSize = currentTotalSize + newFilesToAdd.reduce((total, file) => total + file.size, 0);

    if (totalFiles > 10) {
      setShowToast({
        message: 'Vous ne pouvez pas ajouter plus de 10 fichiers.',
        type: 'error'
      });
      return;
    }

    if (totalSize > 10 * 1024 * 1024) { // 10MB max au total
      setShowToast({
        message: 'La taille totale des fichiers ne doit pas dépasser 10 Mo.',
        type: 'error'
      });
      return;
    }

    // Ajouter les nouveaux fichiers
    const updatedFiles = [...styleGuideFiles, ...newFilesToAdd];
    setStyleGuideFiles(updatedFiles);
    onChange('styleGuideFiles', updatedFiles);

    // Simuler le téléchargement si des fichiers sont valides
    if (newFilesToAdd.length > 0) {
      setUploadStatus('uploading');
      setUploadProgress(0);
      
      // Simuler la progression du téléchargement
      let progress = 0;
      const uploadInterval = setInterval(() => {
        progress += 10;
        setUploadProgress(progress);
        
        if (progress >= 100) {
          clearInterval(uploadInterval);
          setTimeout(() => {
            setUploadStatus('success');
            
            // Réinitialiser après 2 secondes
            setTimeout(() => {
              setUploadStatus('idle');
            }, 2000);
          }, 500);
        }
      }, 100);
    }

    // Afficher un toast de succès
    if (newFilesToAdd.length > 0) {
      setShowToast({
        message: `${newFilesToAdd.length} fichier(s) ajouté(s) avec succès`,
        type: 'success'
      });
    }
  }, [onChange, styleGuideFiles]);

  // Effet pour masquer le toast après quelques secondes
  useEffect(() => {
    if (showToast) {
      const timer = setTimeout(() => {
        setShowToast(null);
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showToast]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt'],
      'image/png': ['.png'],
      'image/jpeg': ['.jpg', '.jpeg'],
      'image/gif': ['.gif']
    },
    maxSize: 2 * 1024 * 1024, // 2MB par fichier
    multiple: true
  });

  const removeFile = (fileToRemove: File) => {
    const updatedFiles = styleGuideFiles.filter(file => file !== fileToRemove);
    setStyleGuideFiles(updatedFiles);
    onChange('styleGuideFiles', updatedFiles);

    // Réinitialiser le statut de téléchargement si aucun fichier ne reste
    if (updatedFiles.length === 0) {
      setUploadStatus('idle');
      setUploadError(null);
    }
  };

  // Fonction pour générer un aperçu du fichier
  const generateFilePreview = (file: File) => {
    // Pour les images, créer un aperçu
    if (file.type.startsWith('image/')) {
      return URL.createObjectURL(file);
    }

    // Pour les PDF, utiliser une icône spécifique
    if (file.type === 'application/pdf') {
      return '/icons/pdf-icon.png';
    }

    // Pour les documents Word
    if (
      file.type === 'application/msword' || 
      file.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      return '/icons/word-icon.png';
    }

    // Pour les fichiers texte
    if (file.type === 'text/plain') {
      return '/icons/txt-icon.png';
    }

    // Fallback
    return null;
  };

  // Nettoyer les URL d'objets créés
  useEffect(() => {
    return () => {
      styleGuideFiles.forEach(file => {
        if (file.type.startsWith('image/')) {
          URL.revokeObjectURL(generateFilePreview(file) || '');
        }
      });
    };
  }, [styleGuideFiles]);

  const extractMetadata = async (url: string) => {
    try {
      // Fetch the webpage content
      const response = await fetch(`https://api.allorigins.win/get?url=${encodeURIComponent(url)}`);
      const data = await response.json();
      const parser = new DOMParser();
      const doc = parser.parseFromString(data.contents, 'text/html');

      // Extract metadata
      const title = doc.querySelector('title')?.textContent || '';
      const descriptionMeta = doc.querySelector('meta[name="description"]');
      const description = descriptionMeta ? descriptionMeta.getAttribute('content') || '' : '';
      
      // Try to find an image
      const ogImage = doc.querySelector('meta[property="og:image"]');
      const image = ogImage ? ogImage.getAttribute('content') : '';

      return { title, description, image };
    } catch (error) {
      console.error('Metadata extraction failed', error);
      return { title: url, description: '', image: '' };
    }
  };

  const handleLinkSubmit = async () => {
    if (!currentLink.trim()) return;

    // Validate URL
    const urlPattern = /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?$/;
    if (!urlPattern.test(currentLink)) {
      setShowToast({
        message: 'URL invalide',
        type: 'error'
      });
      return;
    }

    // Ensure URL starts with http/https
    const formattedUrl = currentLink.startsWith('http') 
      ? currentLink 
      : `https://${currentLink}`;

    try {
      setIsLoading(true);
      const metadata = await extractMetadata(formattedUrl);
      
      const newPreview = {
        url: formattedUrl,
        metadata: metadata
      };

      const updatedPreviews = [...linkPreviews, newPreview];
      setLinkPreviews(updatedPreviews);
      onChange('inspirationSites', updatedPreviews);
      
      // Reset input
      setCurrentLink('');
    } catch (error) {
      // Fallback if preview fails
      const newPreview = {
        url: formattedUrl,
        metadata: {}
      };

      const updatedPreviews = [...linkPreviews, newPreview];
      setLinkPreviews(updatedPreviews);
      onChange('inspirationSites', updatedPreviews);
      
      // Reset input
      setCurrentLink('');
    } finally {
      setIsLoading(false);
    }
  };

  const handleRemoveLink = (urlToRemove: string) => {
    const updatedPreviews = linkPreviews.filter(preview => preview.url !== urlToRemove);
    setLinkPreviews(updatedPreviews);
    onChange('inspirationSites', updatedPreviews);
  };

  const visualStyles = [
    { 
      icon: Zap, 
      label: 'Moderne', 
      value: 'modern',
      description: 'Design contemporain et élégant'
    },
    { 
      icon: Diamond, 
      label: 'Minimaliste', 
      value: 'minimalist',
      description: 'Épuré et sans fioritures'
    },
    { 
      icon: Building2, 
      label: 'Corporate', 
      value: 'corporate',
      description: 'Professionnel et institutionnel'
    },
    { 
      icon: Lightbulb, 
      label: 'Créatif', 
      value: 'creative',
      description: 'Original et innovant'
    },
    { 
      icon: Gamepad2, 
      label: 'Ludique', 
      value: 'playful',
      description: 'Dynamique et amusant'
    },
    { 
      icon: HelpCircle, 
      label: 'Je ne sais pas', 
      value: 'unknown',
      description: 'Besoin de conseils'
    },
  ];

  const colorPalettes = [
    { 
      icon: Palette, 
      label: 'Couleurs claires', 
      value: 'light',
      description: 'Palette lumineuse et aérée'
    },
    { 
      icon: Palette, 
      label: 'Couleurs sombres', 
      value: 'dark',
      description: 'Palette profonde et élégante'
    },
    { 
      icon: Palette, 
      label: 'Les deux', 
      value: 'both',
      description: 'Mélange de tons clairs et sombres'
    },
  ];

  const toggleSingleField = (field: 'visualStyle' | 'colorPalette', value: string) => {
    onChange(field, value);
  };

  const renderSection = (
    title: string, 
    items: any[], 
    field: 'visualStyle' | 'colorPalette', 
    currentValue: string
  ) => (
    <div>
      <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
        {title}
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
        {items.map((item) => (
          <motion.div
            key={item.value}
            onClick={() => toggleSingleField(field, item.value)}
            whileTap={{ scale: 0.95 }}
            className={`
              cursor-pointer 
              rounded-xl 
              p-3 
              transition-all 
              duration-300 
              flex 
              flex-col 
              items-start 
              space-y-2
              ${currentValue === item.value 
                ? 'bg-[#370C15] border-2 border-[#FF3367]' 
                : 'bg-black/40 backdrop-blur-sm hover:bg-black/50'}
            `}
          >
            <div className={`
              p-2 rounded-lg
              ${currentValue === item.value 
                ? 'bg-[#FF3367]/20' 
                : 'bg-white/10'}
            `}>
              <item.icon 
                className={`
                  w-5 h-5
                  ${currentValue === item.value 
                    ? 'text-[#FF3367]' 
                    : 'text-white/60'}
                `} 
              />
            </div>
            <div>
              <h3 className={`
                font-semibold text-sm
                ${currentValue === item.value 
                  ? 'text-white' 
                  : 'text-white/70'}
              `}>
                {item.label}
              </h3>
              <p className={`
                text-xs mt-1
                ${currentValue === item.value 
                  ? 'text-white/70' 
                  : 'text-white/50'}
              `}>
                {item.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-6 relative"
    >
      {renderSection(
        'Style visuel souhaité', 
        visualStyles, 
        'visualStyle', 
        data.visualStyle
      )}
      
      {renderSection(
        'Palette de couleurs', 
        colorPalettes, 
        'colorPalette', 
        data.colorPalette
      )}

      <div>
        <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
          Sites d'inspiration
        </h2>
        <motion.div 
          whileTap={{ scale: 0.98 }}
          className="bg-black/40 backdrop-blur-sm rounded-xl p-3 mb-4"
        >
          <div className="flex items-center space-x-3">
            <div className="p-2 rounded-lg bg-white/10">
              <Link2 
                className="w-5 h-5 text-white/60" 
              />
            </div>
            <input
              type="text"
              value={currentLink}
              onChange={(e) => setCurrentLink(e.target.value)}
              onKeyDown={(e) => e.key === 'Enter' && handleLinkSubmit()}
              placeholder="https://example.com"
              className="
                w-full 
                bg-transparent 
                text-white 
                placeholder-white/50 
                focus:outline-none
                text-sm
                pr-10
              "
            />
            <motion.button
              onClick={handleLinkSubmit}
              whileTap={{ scale: 0.9 }}
              className="
                absolute 
                right-4 
                text-white/60 
                hover:text-white 
                transition
              "
              disabled={isLoading}
            >
              {isLoading ? (
                <motion.div
                  animate={{ rotate: 360 }}
                  transition={{ 
                    repeat: Infinity, 
                    duration: 1, 
                    ease: "linear" 
                  }}
                >
                  <Loader2 className="w-5 h-5" />
                </motion.div>
              ) : (
                <Send className="w-5 h-5" />
              )}
            </motion.button>
          </div>
        </motion.div>

        {/* Previews horizontales scrollables */}
        <div className="flex overflow-x-auto space-x-3 pb-3 no-scrollbar">
          <AnimatePresence>
            {linkPreviews.map((preview, index) => (
              <motion.div
                key={preview.url}
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.3 }}
                className="
                  flex-shrink-0 
                  w-64 
                  bg-black/40 
                  backdrop-blur-sm 
                  rounded-xl 
                  p-3 
                  relative
                  flex 
                  flex-col
                "
              >
                {preview.metadata?.image && (
                  <img 
                    src={preview.metadata.image} 
                    alt="Site preview" 
                    className="w-full h-32 object-cover rounded-lg mb-3"
                  />
                )}
                <div className="flex-grow">
                  <h3 className="text-white font-semibold text-sm mb-1">
                    {preview.metadata?.title || preview.url}
                  </h3>
                  <p className="text-white/70 text-xs line-clamp-2">
                    {preview.metadata?.description || 'Aucune description disponible'}
                  </p>
                </div>
                <button 
                  onClick={() => handleRemoveLink(preview.url)}
                  className="
                    absolute 
                    top-2 
                    right-2 
                    bg-white/10 
                    rounded-full 
                    p-1 
                    hover:bg-white/20
                    transition
                  "
                >
                  <X className="w-4 h-4 text-white/60" />
                </button>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>
      </div>

      <div>
        <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
          Charte graphique
        </h2>
        <div className="grid grid-cols-2 gap-3 mb-6">
          {[
            { label: 'Oui', value: true },
            { label: 'Non', value: false }
          ].map((option) => (
            <motion.div
              key={option.label}
              onClick={() => {
                onChange('hasGraphicCharter', option.value);
              }}
              className={`
                flex 
                items-center 
                justify-center 
                py-2 
                px-4 
                rounded-lg 
                transition-all 
                duration-300 
                cursor-pointer
                ${data.hasGraphicCharter === option.value 
                  ? 'bg-[#370C15] border-2 border-[#FF3367]' 
                  : 'bg-black/40 backdrop-blur-sm hover:bg-black/50'}
              `}
            >
              <span className={`
                font-semibold text-sm
                ${data.hasGraphicCharter === option.value 
                  ? 'text-white' 
                  : 'text-white/70'}
              `}>
                {option.label}
              </span>
            </motion.div>
          ))}
        </div>

        {/* Champ de téléchargement de la charte graphique */}
        {data.hasGraphicCharter === true && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            {styleGuideFiles.length > 0 && (
              <motion.div 
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
                className="mb-4 bg-black/40 backdrop-blur-sm rounded-xl p-3"
              >
                <div className="flex flex-wrap gap-3">
                  {styleGuideFiles.map((file, index) => (
                    <motion.div
                      key={`${file.name}-${index}`}
                      initial={{ opacity: 0, scale: 0.8 }}
                      animate={{ opacity: 1, scale: 1 }}
                      transition={{ duration: 0.2 }}
                      className="relative"
                    >
                      <div className="flex items-center space-x-2 bg-white/10 rounded-lg p-2">
                        {generateFilePreview(file) && (
                          <img 
                            src={generateFilePreview(file)} 
                            alt="Fichier prévisualisé" 
                            className="w-10 h-10 object-cover rounded-lg"
                          />
                        )}
                        <div>
                          <p className="text-xs text-white truncate max-w-[100px]">
                            {file.name}
                          </p>
                          <p className="text-xs text-white/50">
                            {(file.size / 1024).toFixed(1)} Ko
                          </p>
                        </div>
                        <button 
                          onClick={() => removeFile(file)}
                          className="
                            bg-white/10 
                            rounded-full 
                            p-1 
                            hover:bg-white/20
                            transition
                          "
                        >
                          <X className="w-3 h-3 text-white/60" />
                        </button>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </motion.div>
            )}

            <div 
              {...getRootProps()}
              className={`
                p-6 
                border-2 
                border-dashed 
                rounded-xl 
                text-center 
                transition-colors 
                duration-300
                relative
                cursor-pointer
                ${isDragActive 
                  ? 'border-[#FF3367] bg-[#370C15]' 
                  : 'border-white/20 hover:border-white/40'}
                ${uploadError 
                  ? 'border-red-500' 
                  : ''}
              `}
            >
              <input {...getInputProps()} />
              
              {/* Toast de notification */}
              {showToast && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: 20 }}
                  className={`
                    absolute 
                    bottom-0 
                    left-0 
                    right-0 
                    z-50 
                    px-4 
                    py-2 
                    rounded-b-xl 
                    flex 
                    items-center 
                    space-x-3
                    ${showToast.type === 'error' 
                      ? 'bg-red-500/80 text-white' 
                      : 'bg-green-500/80 text-white'}
                  `}
                >
                  {showToast.type === 'error' ? (
                    <AlertCircle className="w-5 h-5" />
                  ) : (
                    <Check className="w-5 h-5" />
                  )}
                  <p className="text-xs">{showToast.message}</p>
                </motion.div>
              )}

              <div className="flex flex-col items-center justify-center space-y-3">
                {uploadStatus === 'idle' && (
                  <>
                    <Upload 
                      className={`
                        w-10 h-10 
                        ${isDragActive 
                          ? 'text-[#FF3367]' 
                          : 'text-white/60'}
                      `} 
                    />
                    <p className="text-sm text-white/70">
                      {isDragActive 
                        ? 'Déposez les fichiers ici' 
                        : 'Glissez et déposez vos fichiers de charte graphique ou cliquez pour sélectionner'}
                    </p>
                    <p className="text-xs text-white/50">
                      10 fichiers max, 2 Mo par fichier, total 10 Mo (PDF, DOC, DOCX, TXT, PNG, JPEG, GIF)
                    </p>
                  </>
                )}

                {uploadStatus === 'uploading' && (
                  <div className="flex flex-col items-center space-y-3">
                    <Loader2 
                      className="w-10 h-10 animate-spin text-[#FF3367]" 
                    />
                    <p className="text-sm text-white/70">
                      Téléchargement en cours...
                    </p>
                    <div className="w-full bg-white/20 rounded-full h-2.5">
                      <div 
                        className="bg-[#FF3367] h-2.5 rounded-full" 
                        style={{ width: `${uploadProgress}%` }}
                      ></div>
                    </div>
                  </div>
                )}

                {uploadStatus === 'success' && (
                  <div className="flex flex-col items-center space-y-3">
                    <Check 
                      className="w-10 h-10 text-green-500" 
                    />
                    <p className="text-sm text-white/70">
                      Téléchargement réussi
                    </p>
                  </div>
                )}

                {uploadStatus === 'error' && (
                  <div className="flex flex-col items-center space-y-3">
                    <AlertTriangle 
                      className="w-10 h-10 text-red-500" 
                    />
                    <p className="text-sm text-red-500">
                      {uploadError || 'Erreur de téléchargement'}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </motion.div>
        )}
      </div>

      <div>
        <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
          Notes additionnelles
        </h2>
        <motion.div 
          whileTap={{ scale: 0.98 }}
          className="bg-black/40 backdrop-blur-sm rounded-xl p-3"
        >
          <div className="flex items-center space-x-3">
            <div className="p-2 rounded-lg bg-white/10">
              <FileText 
                className="w-5 h-5 text-white/60" 
              />
            </div>
            <textarea
              value={data.designNotes}
              onChange={(e) => onChange('designNotes', e.target.value)}
              placeholder="Ajoutez des détails supplémentaires..."
              className="
                w-full 
                bg-transparent 
                text-white 
                placeholder-white/50 
                focus:outline-none
                text-sm
                h-24
                resize-none
              "
            />
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

import React, { useEffect, useState } from 'react';

interface RotatingTextProps {
  words: string[];
  className?: string;
  delay?: number;
}

const RotatingText: React.FC<RotatingTextProps> = ({ 
  words, 
  className = '',
  delay = 100 
}) => {
  const [displayText, setDisplayText] = useState('');
  const [wordIndex, setWordIndex] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    const currentWord = words[wordIndex];
    
    const timeout = setTimeout(() => {
      if (isWaiting) {
        setIsWaiting(false);
        setIsDeleting(true);
        return;
      }

      if (isDeleting) {
        if (displayText === '') {
          setIsDeleting(false);
          setWordIndex((prev) => (prev + 1) % words.length);
        } else {
          setDisplayText(prev => prev.slice(0, -1));
        }
      } else {
        if (displayText === currentWord) {
          setIsWaiting(true);
        } else {
          setDisplayText(currentWord.slice(0, displayText.length + 1));
        }
      }
    }, isDeleting ? delay / 2 : isWaiting ? 2000 : delay);

    return () => clearTimeout(timeout);
  }, [displayText, wordIndex, isDeleting, isWaiting, words, delay]);

  return (
    <span className={className}>
      {displayText}
      <span className="animate-pulse">|</span>
    </span>
  );
};

export default RotatingText;

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, ExternalLink, Github, Calendar, Users, Code2 } from 'lucide-react';

interface ProjectModalProps {
  isOpen: boolean;
  onClose: () => void;
  project: {
    title: string;
    category: string;
    description: string;
    image: string;
    technologies: string[];
    link: string;
    details?: {
      client?: string;
      duration?: string;
      team?: string;
      challenge?: string;
      solution?: string;
      results?: string[];
    };
  };
}

const ProjectModal = ({ isOpen, onClose, project }: ProjectModalProps) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onClose}
          className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black/50 backdrop-blur-sm"
        >
          <motion.div
            initial={{ scale: 0.95, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.95, opacity: 0 }}
            onClick={(e) => e.stopPropagation()}
            className="relative w-full max-w-4xl max-h-[90vh] overflow-auto bg-[#111111] dark:bg-[#111111] rounded-2xl shadow-xl"
          >
            {/* Header avec image */}
            <div className="relative h-64">
              <img
                src={project.image}
                alt={project.title}
                className="w-full h-full object-cover rounded-t-2xl"
              />
              <div className="absolute inset-0 bg-gradient-to-b from-transparent to-black/75 rounded-t-2xl" />
              <button
                onClick={onClose}
                className="absolute top-4 right-4 p-2 bg-black/20 hover:bg-black/40 rounded-full transition-colors"
              >
                <X className="w-6 h-6 text-white" />
              </button>
              <div className="absolute bottom-4 left-6">
                <span className="px-3 py-1 bg-[#FF3366] text-white text-sm rounded-full">
                  {project.category}
                </span>
              </div>
            </div>

            {/* Contenu */}
            <div className="p-6">
              <h2 className="text-3xl font-bold mb-4">{project.title}</h2>
              
              {/* Technologies */}
              <div className="flex flex-wrap gap-2 mb-6">
                {project.technologies.map((tech, index) => (
                  <span
                    key={index}
                    className="px-3 py-1 bg-[#1a1a1a] text-[#FF3366] rounded-full text-sm"
                  >
                    {tech}
                  </span>
                ))}
              </div>

              {/* Description */}
              <p className="text-gray-600 dark:text-gray-400 mb-8">
                {project.description}
              </p>

              {/* Détails du projet */}
              {project.details && (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
                  {project.details.client && (
                    <div className="flex items-start gap-3">
                      <Users className="w-5 h-5 text-[#FF3366] mt-1" />
                      <div>
                        <h3 className="font-bold mb-1">Client</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                          {project.details.client}
                        </p>
                      </div>
                    </div>
                  )}
                  
                  {project.details.duration && (
                    <div className="flex items-start gap-3">
                      <Calendar className="w-5 h-5 text-[#FF3366] mt-1" />
                      <div>
                        <h3 className="font-bold mb-1">Durée</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                          {project.details.duration}
                        </p>
                      </div>
                    </div>
                  )}
                  
                  {project.details.team && (
                    <div className="flex items-start gap-3">
                      <Users className="w-5 h-5 text-[#FF3366] mt-1" />
                      <div>
                        <h3 className="font-bold mb-1">Équipe</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                          {project.details.team}
                        </p>
                      </div>
                    </div>
                  )}
                  
                  {project.details.challenge && (
                    <div className="flex items-start gap-3">
                      <Code2 className="w-5 h-5 text-[#FF3366] mt-1" />
                      <div>
                        <h3 className="font-bold mb-1">Challenge</h3>
                        <p className="text-gray-600 dark:text-gray-400">
                          {project.details.challenge}
                        </p>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Solution */}
              {project.details?.solution && (
                <div className="mb-8">
                  <h3 className="text-xl font-bold mb-4">Notre Solution</h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    {project.details.solution}
                  </p>
                </div>
              )}

              {/* Résultats */}
              {project.details?.results && (
                <div className="mb-8">
                  <h3 className="text-xl font-bold mb-4">Résultats</h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600 dark:text-gray-400">
                    {project.details.results.map((result, index) => (
                      <li key={index}>{result}</li>
                    ))}
                  </ul>
                </div>
              )}

              {/* Liens */}
              <div className="flex gap-4">
                <a
                  href={project.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 bg-[#FF3366] text-white rounded-lg hover:bg-[#FF3366]/90 transition-colors"
                >
                  <ExternalLink className="w-4 h-4" />
                  Voir le projet
                </a>
                <a
                  href="#"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-2 px-4 py-2 border border-gray-700 rounded-lg hover:bg-[#1a1a1a] dark:hover:bg-[#1a1a1a] transition-colors"
                >
                  <Github className="w-4 h-4" />
                  Voir le code
                </a>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ProjectModal;
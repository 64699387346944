import { getFunctions, httpsCallable } from 'firebase/functions';
import type { ProjectInfo } from './openai';
import { functions } from '../lib/firebase';

interface ContactFormData {
  name: string;
  email: string;
  message: string;
}

interface ProjectEmailData extends ProjectInfo {
  chatHistory?: string;
}

const FIREBASE_PROJECT_ID = import.meta.env.VITE_FIREBASE_PROJECT_ID || 'izicode-fe7fc';

export const sendProjectEmails = async (projectInfo: ProjectInfo, chatHistory: string): Promise<void> => {
  try {
    console.log('Sending project emails with data:', {
      projectInfo,
      chatHistoryLength: chatHistory?.length || 0
    });
    
    const functions = getFunctions();
    const sendEmails = httpsCallable<ProjectEmailData, { success: boolean }>(
      functions, 
      'sendProjectEmailsHandler'
    );
    
    const emailData: ProjectEmailData = {
      ...projectInfo,
      chatHistory
    };
    
    const result = await sendEmails(emailData);
    console.log('Project emails sent successfully, result:', result);
  } catch (error) {
    console.error('Error sending project emails:', error);
    throw error;
  }
};

export const sendContactFormEmail = async (data: ContactFormData) => {
  try {
    console.log('Sending contact form email with data:', data);
    console.log('Using project ID:', FIREBASE_PROJECT_ID);
    
    const region = 'us-central1';
    const url = `https://${region}-${FIREBASE_PROJECT_ID}.cloudfunctions.net/sendContactEmailHttp`;
    
    console.log('Sending request to:', url);
    
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Origin': window.location.origin,
      },
      credentials: 'same-origin',
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      let errorMessage = 'Failed to send email';
      try {
        const errorData = await response.json();
        errorMessage = errorData.error || errorMessage;
      } catch (e) {
        console.error('Error parsing error response:', e);
      }
      throw new Error(errorMessage);
    }

    const result = await response.json();
    console.log('Email sent successfully:', result);
    return result;
  } catch (error) {
    console.error('Error sending contact form email:', error);
    throw error;
  }
};

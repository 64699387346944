import { FormData } from '../types/form';

export const useFormValidation = () => {
  const isStepValid = (step: number, data: FormData): boolean => {
    switch (step) {
      case 1:
        return (
          data.projectType.length > 0 &&
          data.projectDescription.length >= 50 &&
          data.projectDescription.length <= 1500 &&
          data.deadline.length > 0 &&
          (!data.hasSpecifications || (data.hasSpecifications && !!data.specificationFile))
        );
      case 2:
        return data.features.length > 0 && data.compatibility.length > 0;
      case 6:
        return (
          data.firstName.length > 0 &&
          data.lastName.length > 0 &&
          data.email.length > 0 &&
          data.phone.length > 0
        );
      default:
        return true;
    }
  };

  const getStepErrors = (step: number, data: FormData): string[] => {
    const errors: string[] = [];

    switch (step) {
      case 1:
        if (data.projectType.length === 0) {
          errors.push('Veuillez sélectionner au moins un type de projet');
        }
        if (data.projectDescription.length === 0) {
          errors.push('Veuillez fournir une description du projet');
        } else if (data.projectDescription.length < 50) {
          errors.push('La description doit contenir au moins 50 caractères');
        } else if (data.projectDescription.length > 1500) {
          errors.push('La description ne doit pas dépasser 1500 caractères');
        }
        if (data.deadline.length === 0) {
          errors.push('Veuillez sélectionner un délai');
        }
        if (data.hasSpecifications && !data.specificationFile) {
          errors.push('Veuillez téléverser un cahier des charges');
        }
        break;
      case 2:
        if (data.features.length === 0) {
          errors.push('Veuillez sélectionner au moins une fonctionnalité');
        }
        if (data.compatibility.length === 0) {
          errors.push('Veuillez sélectionner au moins une option de compatibilité');
        }
        break;
      case 6:
        if (data.firstName.length === 0) {
          errors.push('Le prénom est requis');
        }
        if (data.lastName.length === 0) {
          errors.push('Le nom est requis');
        }
        if (data.email.length === 0) {
          errors.push("L'email est requis");
        }
        if (data.phone.length === 0) {
          errors.push('Le téléphone est requis');
        }
        break;
    }

    return errors;
  };

  return {
    isStepValid,
    getStepErrors,
  };
};

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, User, Clock, ArrowLeft, Linkedin, Facebook, Instagram, Link2, Check } from 'lucide-react';
import SEO from '../components/SEO/SEO';
import { getBlogPost, normalizeSlug } from '../lib/firebase';
import type { BlogPost } from '../lib/firebase';
import NotFoundPost from '../components/NotFoundPost';

const BlogPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [showCopiedTooltip, setShowCopiedTooltip] = useState(false);
  const [post, setPost] = useState<BlogPost | null>(null);
  const [similarPosts, setSimilarPosts] = useState<BlogPost[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [retryCount, setRetryCount] = useState(0);

  const handleRetry = () => {
    setLoading(true);
    setError(null);
    setRetryCount(prev => prev + 1);
  };

  useEffect(() => {
    let isMounted = true;
    let timeoutId: NodeJS.Timeout;

    const fetchPost = async () => {
      if (!slug) {
        console.error('No slug provided');
        if (isMounted) {
          setError('Article non trouvé');
          setLoading(false);
        }
        return;
      }

      console.log('Current URL:', window.location.href);
      console.log('Fetching post with slug:', slug);
      console.log('Normalized slug:', normalizeSlug(slug));

      try {
        console.log('Starting getBlogPost call');
        const fetchedPost = await getBlogPost(slug);
        console.log('Fetched post:', fetchedPost);
        
        if (isMounted) {
          if (fetchedPost) {
            console.log('Setting post:', fetchedPost);
            setPost(fetchedPost);
            setError(null);
          } else {
            console.error('No post found for slug:', slug);
            setPost(null);
            setError('Article non trouvé');
          }
          setLoading(false);
        }
      } catch (err) {
        console.error('Error fetching post:', err);
        if (isMounted) {
          setPost(null);
          setError('Une erreur est survenue lors du chargement de l\'article.');
          setLoading(false);
        }
      }
    };

    setLoading(true);
    setError(null);
    setPost(null);
    fetchPost();

    return () => {
      isMounted = false;
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [slug]);

  const seoProps = {
    title: post?.title,
    description: post?.excerpt,
    image: post?.image,
  };

  // Afficher le loader pendant le chargement
  if (loading) {
    return (
      <div className="min-h-screen bg-black flex items-center justify-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-pink-500"></div>
      </div>
    );
  }

  // Afficher NotFoundPost uniquement si nous avons explicitement un error "Article non trouvé"
  if (!post && error === 'Article non trouvé') {
    return <NotFoundPost />;
  }

  // Afficher une erreur générique pour les autres types d'erreurs
  if (!post && error) {
    return (
      <div className="min-h-screen bg-black flex flex-col items-center justify-center p-8">
        <p className="text-red-500 mb-4">{error}</p>
        <div className="flex gap-4">
          <button
            onClick={handleRetry}
            className="bg-pink-500 text-white px-6 py-2 rounded-lg hover:bg-pink-600 transition-colors"
          >
            Réessayer
          </button>
          <button
            onClick={() => navigate('/blog')}
            className="bg-gray-700 text-white px-6 py-2 rounded-lg hover:bg-gray-600 transition-colors"
          >
            Retour au blog
          </button>
        </div>
      </div>
    );
  }

  // Si nous n'avons pas de post valide, ne rien afficher
  if (!post) {
    return null;
  }

  const formatContent = (content: string) => {
    if (!content) return <p className="text-gray-400">Contenu en cours de rédaction...</p>;

    const paragraphs = content.split('\n\n');
    let inBlueBox = false;
    let blueBoxContent = [];

    return (
      <div className="space-y-8">
        {paragraphs.map((paragraph, index) => {
          // Début de l'encadré bleu
          if (paragraph.startsWith('***') && !paragraph.endsWith('***')) {
            inBlueBox = true;
            blueBoxContent = [paragraph.replace('***', '').trim()];
            return null;
          }
          // Fin de l'encadré bleu
          else if (paragraph.endsWith('***') && inBlueBox) {
            inBlueBox = false;
            blueBoxContent.push(paragraph.replace('***', '').trim());
            const fullContent = blueBoxContent.join('\n\n');
            return (
              <div key={index} className="bg-blue-900/30 border border-blue-500/30 rounded-lg p-6 my-8">
                <div className="text-gray-300 leading-relaxed">
                  {fullContent.split('\n').map((line, i) => (
                    <p key={i} className="mb-2">{line}</p>
                  ))}
                </div>
              </div>
            );
          }
          // Contenu à l'intérieur de l'encadré bleu
          else if (inBlueBox) {
            blueBoxContent.push(paragraph);
            return null;
          }
          // Sous-titre (entouré de **) - Vérifié AVANT les titres avec *
          else if (paragraph.match(/^\*\*(.*)\*\*$/)) {
            const subtitle = paragraph.replace(/^\*\*(.*)\*\*$/, '$1');
            // Si c'est "Conclusion", on le met en rose
            if (subtitle.toLowerCase() === 'conclusion') {
              return (
                <h2 key={index} className="text-2xl font-semibold text-[#FF3366] mb-4">
                  {subtitle}
                </h2>
              );
            }
            return (
              <h2 key={index} className="text-2xl font-semibold text-white mb-4">
                {subtitle}
              </h2>
            );
          }
          // Titre principal (entouré de *)
          else if (paragraph.match(/^\*(.*)\*$/)) {
            const title = paragraph.replace(/^\*(.*)\*$/, '$1');
            return (
              <h1 key={index} className="text-3xl font-bold text-white mb-6">
                {title}
              </h1>
            );
          }
          // Sections numérotées
          else if (/^\d+\./.test(paragraph)) {
            const lines = paragraph.split('\n');
            const title = lines[0];
            const content = lines.slice(1).join('\n');
            
            return (
              <div key={index}>
                <h2 className="text-2xl font-semibold text-white mb-4">{title}</h2>
                {content && (
                  <p className="text-gray-300 leading-relaxed">{content}</p>
                )}
              </div>
            );
          }
          // Liste à puces
          else if (paragraph.includes('•')) {
            const items = paragraph.split('\n').map(item => item.trim());
            return (
              <ul key={index} className="space-y-2 text-gray-300 ml-4">
                {items.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            );
          }
          // Paragraphe normal
          else {
            return (
              <p key={index} className="text-gray-300 leading-relaxed">
                {paragraph}
              </p>
            );
          }
        })}
      </div>
    );
  };

  const handleShare = (platform: string) => {
    const currentUrl = window.location.href;
    const title = post?.title || 'Article Izicode';
    const text = post?.excerpt || 'Découvrez cet article intéressant sur Izicode';
    const hashtags = 'Izicode,Développement,Web,Digital';

    switch (platform) {
      case 'linkedin':
        const linkedinText = `${title}\n\n${text}\n\n#${hashtags.replace(/,/g, ' #')}`;
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(currentUrl)}&title=${encodeURIComponent(title)}&summary=${encodeURIComponent(text)}`,
          '_blank',
          'width=600,height=600'
        );
        break;
      case 'facebook':
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}&quote=${encodeURIComponent(`${title}\n\n${text}\n\n#${hashtags.replace(/,/g, ' #')}`)}`,
          '_blank',
          'width=600,height=600'
        );
        break;
      case 'twitter':
        const twitterText = `${title}\n\n${text}\n\n${hashtags.split(',').map(tag => '#' + tag).join(' ')}`;
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(twitterText)}`,
          '_blank',
          'width=600,height=600'
        );
        break;
      case 'copy':
        const copyText = `${title}\n\n${text}\n\nLire l'article complet : ${currentUrl}`;
        navigator.clipboard.writeText(copyText);
        setShowCopiedTooltip(true);
        setTimeout(() => setShowCopiedTooltip(false), 2000);
        break;
    }
  };

  return (
    <>
      {post && <SEO {...seoProps} />}
      <div className="min-h-screen pt-28">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="max-w-4xl mx-auto px-4 py-12"
        >
          <button
            onClick={() => navigate('/blog')}
            className="text-[#FF3366] hover:text-white transition-colors flex items-center gap-2 mb-8"
          >
            <ArrowLeft className="w-5 h-5" />
            Retour au blog
          </button>

          <img
            src={post.image}
            alt={post.title}
            className="w-full h-[400px] object-cover rounded-xl mb-8"
          />

          <div className="space-y-6">
            <div className="flex flex-wrap items-center gap-y-3 gap-x-4 text-sm text-gray-400">
              <span className="bg-[#FF3366] text-white px-3 py-1 rounded-full whitespace-nowrap">
                {post.category}
              </span>
              <div className="flex items-center gap-1 whitespace-nowrap">
                <User className="w-4 h-4 flex-shrink-0" />
                {post.author}
              </div>
              <div className="flex items-center gap-1 whitespace-nowrap">
                <Calendar className="w-4 h-4 flex-shrink-0" />
                {post.date}
              </div>
              <div className="flex items-center gap-1 whitespace-nowrap">
                <Clock className="w-4 h-4 flex-shrink-0" />
                {post.readTime}
              </div>
            </div>

            <h1 className="text-4xl font-bold">{post.title}</h1>
            <p className="text-xl text-gray-400">{post.excerpt}</p>
            
            {/* Contenu de l'article */}
            <div className="max-w-3xl mx-auto">
              <div className="flex flex-wrap gap-2 mb-8">
                {post.tags?.map((tag) => (
                  <span
                    key={tag}
                    className="bg-gray-800 text-gray-300 px-3 py-1 rounded-full text-sm"
                  >
                    #{tag}
                  </span>
                ))}
              </div>

              <div className="space-y-8">
                {formatContent(post.content)}
              </div>
            </div>

            {/* Section de partage */}
            <div className="border-t border-gray-800 mt-16 pt-8 max-w-3xl mx-auto">
              <h3 className="text-xl font-bold mb-6">Partager cet article</h3>
              <div className="flex flex-wrap gap-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleShare('linkedin')}
                  className="flex items-center gap-2 px-4 py-2 bg-[#0077B5] rounded-lg hover:bg-opacity-90 transition-colors"
                >
                  <Linkedin className="w-5 h-5" />
                  LinkedIn
                </motion.button>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleShare('facebook')}
                  className="flex items-center gap-2 px-4 py-2 bg-[#1877F2] rounded-lg hover:bg-opacity-90 transition-colors"
                >
                  <Facebook className="w-5 h-5" />
                  Facebook
                </motion.button>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleShare('twitter')}
                  className="flex items-center gap-2 px-4 py-2 bg-[#1DA1F2] rounded-lg hover:bg-opacity-90 transition-colors"
                >
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M14.258 10.152L23.176 0h-2.113l-7.747 8.813L7.133 0H0l9.352 13.328L0 23.973h2.113l8.176-9.309 6.531 9.309h7.133zm-2.895 3.293l-.949-1.328L2.875 1.56h3.246l6.086 8.523.945 1.328 7.91 11.078h-3.246zm0 0" />
                  </svg>
                  Twitter
                </motion.button>

                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleShare('copy')}
                  className="flex items-center gap-2 px-4 py-2 bg-gray-700 rounded-lg hover:bg-opacity-90 transition-colors relative"
                >
                  <Link2 className="w-5 h-5" />
                  Copier le lien
                  <AnimatePresence>
                    {showCopiedTooltip && (
                      <motion.div
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                        className="absolute -top-10 left-1/2 transform -translate-x-1/2 bg-green-500 text-white px-3 py-1 rounded-lg flex items-center gap-1 text-sm whitespace-nowrap"
                      >
                        <Check className="w-4 h-4" />
                        Copié !
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.button>
              </div>
            </div>

            {/* Section Articles similaires */}
            {similarPosts.length > 0 && (
              <div className="border-t border-gray-800 mt-16 pt-8 max-w-3xl mx-auto">
                <h3 className="text-xl font-bold mb-6">Articles similaires</h3>
                <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
                  {similarPosts.map((similarPost) => (
                    <motion.div
                      key={similarPost.title}
                      whileHover={{ scale: 1.02 }}
                      className="bg-gray-900 rounded-lg overflow-hidden cursor-pointer"
                      onClick={() => {
                        navigate(`/blog/${similarPost.title.toLowerCase().replace(/\s+/g, '-')}`);
                        window.scrollTo(0, 0);
                      }}
                    >
                      <div className="p-6">
                        <h4 className="text-lg font-semibold text-white mb-2 line-clamp-2">
                          {similarPost.title}
                        </h4>
                        <p className="text-gray-400 text-sm line-clamp-3">
                          {similarPost.excerpt}
                        </p>
                        <div className="flex items-center gap-2 mt-4 text-sm text-gray-500">
                          <Clock size={16} />
                          {similarPost.date}
                        </div>
                      </div>
                    </motion.div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default BlogPost;

import React from 'react';

const PolitiqueCookies = () => {
  return (
    <div className="min-h-screen bg-white dark:bg-[#111826]">
      <div className="max-w-3xl mx-auto px-4 py-12">
        <a 
          href="/"
          className="inline-flex items-center gap-2 text-gray-600 dark:text-gray-400 hover:text-[#FF3366] dark:hover:text-[#FF3366] transition-colors mb-8"
        >
          Retour
        </a>

        <h1 className="text-3xl font-bold text-gray-900 dark:text-white mb-8">
          Politique de Cookies
        </h1>

        <div className="prose dark:prose-invert max-w-none">
          <h2>Qu'est-ce qu'un cookie ?</h2>
          <p>
            Un cookie est un petit fichier texte qui est placé sur votre ordinateur, tablette ou smartphone lorsque vous visitez un site web. 
            Les cookies nous permettent de reconnaître votre appareil et de mémoriser certaines informations sur votre visite.
          </p>

          <h2>Comment utilisons-nous les cookies ?</h2>
          <p>
            Nous utilisons différents types de cookies pour différentes raisons :
          </p>
          <ul>
            <li>
              <strong>Cookies essentiels :</strong> Ces cookies sont nécessaires au fonctionnement du site. Ils vous permettent de naviguer sur le site et d'utiliser ses fonctionnalités.
            </li>
            <li>
              <strong>Cookies analytiques :</strong> Ces cookies nous permettent de comprendre comment les visiteurs utilisent notre site, de mesurer le nombre de visiteurs et de voir comment les visiteurs naviguent sur le site.
            </li>
            <li>
              <strong>Cookies de préférences :</strong> Ces cookies permettent au site de mémoriser vos choix (comme votre nom d'utilisateur, votre langue ou la région où vous vous trouvez) et de fournir des fonctionnalités améliorées et plus personnelles.
            </li>
          </ul>

          <h2>Gestion des cookies</h2>
          <p>
            Vous pouvez à tout moment modifier vos préférences en matière de cookies en cliquant sur le bouton "Gérer les cookies" en bas de page.
            Vous pouvez également configurer votre navigateur pour qu'il refuse tous les cookies ou qu'il vous avertisse lorsqu'un cookie est envoyé.
          </p>

          <h2>Durée de conservation des cookies</h2>
          <p>
            La durée de conservation des cookies varie selon leur type :
          </p>
          <ul>
            <li>Cookies de session : supprimés lorsque vous fermez votre navigateur</li>
            <li>Cookies persistants : peuvent rester jusqu'à 13 mois sur votre appareil</li>
          </ul>

          <h2>Mise à jour de notre politique</h2>
          <p>
            Nous nous réservons le droit de modifier cette politique de cookies à tout moment. 
            Toute modification prendra effet immédiatement. 
            Nous vous encourageons à consulter régulièrement cette page pour rester informé des éventuelles modifications.
          </p>

          <h2>Contact</h2>
          <p>
            Pour toute question concernant notre utilisation des cookies, n'hésitez pas à nous contacter.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PolitiqueCookies;

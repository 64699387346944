import React from 'react';

const PolitiqueConfidentialite = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <h1 className="text-4xl font-bold mb-8 text-[#FF3366]">Politique de confidentialité</h1>
        <p className="text-gray-400 mb-8">Dernière mise à jour : 1 décembre 2024</p>

        <div className="text-gray-300 mb-12">
          <p>
            Chez Izicode, nous accordons une grande importance à la protection de votre vie privée. 
            Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons 
            vos données personnelles lorsque vous utilisez notre site internet www.izicode.com.
          </p>
        </div>

        <div className="space-y-12">
          {/* Section 1 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">1. Données personnelles collectées</h2>
            <p className="text-gray-300 mb-4">
              Sur notre site vitrine, nous collectons uniquement les informations que vous fournissez 
              volontairement via notre formulaire de contact. Ces informations comprennent :
            </p>
            <ul className="list-none space-y-2 text-gray-300">
              <li>• Nom (obligatoire)</li>
              <li>• Adresse email (obligatoire)</li>
              <li>• Message (obligatoire)</li>
            </ul>
            <p className="text-gray-300 mt-4">
              Nous ne collectons aucune donnée supplémentaire, y compris des cookies ou des données de navigation.
            </p>
          </section>

          {/* Section 2 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">2. Finalité de la collecte des données</h2>
            <p className="text-gray-300 mb-4">
              Les informations collectées via le formulaire de contact sont utilisées uniquement pour :
            </p>
            <ul className="list-none space-y-2 text-gray-300">
              <li>• Répondre à vos demandes ou questions soumises via le formulaire</li>
              <li>• Vous fournir des informations ou devis liés à nos services, si vous en faites la demande</li>
            </ul>
            <p className="text-gray-300 mt-4">
              Nous n'utilisons pas vos données à des fins de marketing, sauf si vous y avez consenti explicitement.
            </p>
          </section>

          {/* Section 3 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">3. Base légale</h2>
            <p className="text-gray-300">
              Conformément au RGPD, la base légale pour le traitement de vos données personnelles est :
            </p>
            <ul className="list-none space-y-2 text-gray-300 mt-4">
              <li>• Votre consentement, exprimé en soumettant le formulaire de contact</li>
            </ul>
          </section>

          {/* Section 4 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">4. Durée de conservation des données</h2>
            <p className="text-gray-300">
              Les données collectées via le formulaire de contact sont conservées pendant une durée 
              maximale de 12 mois après votre dernier contact. Après cette période, elles sont 
              supprimées de manière sécurisée.
            </p>
          </section>

          {/* Section 5 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">5. Partage des données</h2>
            <p className="text-gray-300 mb-4">
              Nous ne partageons pas vos données personnelles avec des tiers, sauf dans les cas suivants :
            </p>
            <ul className="list-none space-y-2 text-gray-300">
              <li>• Obligation légale : si une autorité administrative ou judiciaire nous demande de fournir ces données</li>
              <li>• Maintenance technique : en cas de nécessité pour l'hébergeur du site (Hostinger), uniquement pour des raisons techniques</li>
            </ul>
          </section>

          {/* Section 6 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">6. Droits des utilisateurs</h2>
            <p className="text-gray-300 mb-4">
              Conformément au RGPD, vous disposez des droits suivants sur vos données personnelles :
            </p>
            <ul className="list-none space-y-2 text-gray-300">
              <li>• Droit d'accès : savoir quelles données nous détenons sur vous</li>
              <li>• Droit de rectification : corriger des données inexactes ou incomplètes</li>
              <li>• Droit à l'effacement : demander la suppression de vos données</li>
              <li>• Droit à la limitation du traitement : restreindre l'utilisation de vos données</li>
              <li>• Droit à la portabilité : recevoir vos données dans un format lisible ou les transférer à un tiers</li>
              <li>• Droit d'opposition : vous opposer à tout traitement basé sur votre consentement</li>
            </ul>
            <p className="text-gray-300 mt-4">
              Pour exercer vos droits, contactez-nous à l'adresse suivante :
            </p>
            <p className="text-gray-300 mt-2">• Email : contact@izicode.com</p>
            <p className="text-gray-300 mt-4">
              Nous nous engageons à répondre à votre demande dans un délai maximal de 30 jours.
            </p>
          </section>

          {/* Section 7 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">7. Sécurité des données</h2>
            <p className="text-gray-300 mb-4">
              Nous mettons en œuvre des mesures techniques et organisationnelles pour protéger 
              vos données personnelles contre :
            </p>
            <ul className="list-none space-y-2 text-gray-300">
              <li>• Les accès non autorisés</li>
              <li>• Les modifications, divulgations ou destructions non autorisées</li>
            </ul>
            <p className="text-gray-300 mt-4">
              Ces mesures incluent l'utilisation de serveurs sécurisés et d'une communication 
              cryptée pour toutes les données sensibles.
            </p>
          </section>

          {/* Section 8 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">8. Absence de cookies</h2>
            <p className="text-gray-300">
              Nous n'utilisons pas de cookies sur ce site. Aucune donnée de navigation ou 
              comportementale n'est collectée lorsque vous visitez notre site.
            </p>
          </section>

          {/* Section 9 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">9. Modification de cette politique</h2>
            <p className="text-gray-300">
              Cette politique de confidentialité peut être mise à jour à tout moment pour refléter 
              les évolutions légales ou techniques. En cas de modification importante, un avis sera 
              affiché sur notre site.
            </p>
          </section>

          {/* Section 10 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">10. Contact</h2>
            <p className="text-gray-300 mb-4">
              Pour toute question relative à cette politique ou au traitement de vos données, 
              vous pouvez nous contacter à :
            </p>
            <ul className="list-none space-y-2 text-gray-300">
              <li>• Email : contact@izicode.com</li>
              <li>• Adresse : 76 rue du Professeur Christian Cabrol, 80000 Amiens, France</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default PolitiqueConfidentialite;

import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import ProjectModal from '../components/ProjectModal';
import CircleLoader from '../components/CircleLoader';
import { Helmet } from 'react-helmet';
import { getProjects } from '../lib/firebase';
import type { Project } from '../lib/firebase';
import SEO from '../components/SEO/SEO';
import { pageSEOConfigs } from '../config/seo.config';

const Projects = () => {
  const [ref, inView] = useInView({
    triggerOnce: false,
    threshold: 0.2
  });

  const [projects, setProjects] = useState<Project[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [selectedProject, setSelectedProject] = useState<Project | null>(null);
  const [count, setCount] = useState({ projects: 0, satisfaction: 0 });
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dragging, setDragging] = useState(false);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setIsLoading(true);
        const projectsData = await getProjects();
        setProjects(projectsData);
      } catch (err) {
        setError('Erreur lors du chargement des projets');
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    if (inView) {
      const projectsInterval = setInterval(() => {
        setCount(prev => ({
          ...prev,
          projects: prev.projects >= 150 ? 150 : prev.projects + 1
        }));
      }, 15);

      const satisfactionInterval = setInterval(() => {
        setCount(prev => ({
          ...prev,
          satisfaction: prev.satisfaction >= 98 ? 98 : prev.satisfaction + 1
        }));
      }, 20);

      return () => {
        clearInterval(projectsInterval);
        clearInterval(satisfactionInterval);
      };
    }
  }, [inView]);

  const titleVariants = {
    hidden: {
      opacity: 0,
      scale: 0.5,
      rotate: 45
    },
    visible: {
      opacity: 1,
      scale: 1,
      rotate: 0,
      transition: {
        type: 'spring',
        stiffness: 150,
        damping: 15,
        mass: 0.4,
        delay: 0.1
      }
    }
  };

  const slideLeft = () => {
    setCurrentIndex((prev) => (prev === 0 ? projects.length - 1 : prev - 1));
  };

  const slideRight = () => {
    setCurrentIndex((prev) => (prev === projects.length - 1 ? 0 : prev + 1));
  };

  const getCardStyle = (index: number) => {
    const diff = (index - currentIndex + projects.length) % projects.length;
    const absoluteDiff = Math.min(diff, projects.length - diff);
    
    let x = 0;
    let scale = 1;
    let zIndex = 1;
    let opacity = 1;

    if (absoluteDiff === 0) {
      x = 0;
      scale = 1;
      zIndex = 30;
      opacity = 1;
    } else if (absoluteDiff === 1) {
      x = diff === 1 ? 200 : -200;
      scale = 0.85;
      zIndex = 20;
      opacity = 0.6;
    } else {
      x = diff === 2 ? 400 : -400;
      scale = 0.7;
      zIndex = 10;
      opacity = 0.3;
    }

    return {
      zIndex,
      x,
      scale,
      opacity,
      rotateY: diff === 1 ? -15 : diff === -1 ? 15 : 0
    };
  };

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <SEO {...pageSEOConfigs.projects} />
      <Helmet>
        <title>Projets - Izicode | Nos Réalisations</title>
        <meta
          name="description"
          content="Découvrez les projets innovants d'Izicode : plateformes e-commerce, applications mobiles, sites vitrines et plus encore. Notre expertise au service de vos besoins."
        />
        <meta name="keywords" content="projets, réalisations, Izicode, développement web, applications mobiles, design, innovation" />
        <meta name="author" content="Izicode" />
        <link rel="canonical" href="https://izicode.com/projects" />

        <meta property="og:title" content="Projets - Izicode | Nos Réalisations" />
        <meta
          property="og:description"
          content="Explorez nos projets réalisés avec les dernières technologies et méthodologies : plateformes web, applications mobiles et sites créatifs."
        />
        <meta property="og:image" content="/Images/meta/projects.AVIF" />
        <meta property="og:url" content="https://izicode.com/projects" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Projets - Izicode | Nos Réalisations" />
        <meta
          name="twitter:description"
          content="Découvrez nos réalisations en développement web, applications mobiles et design moderne. Contactez-nous pour concrétiser vos idées."
        />
        <meta name="twitter:image" content="/Images/meta/projects.AVIF" />

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Portfolio",
            "name": "Projets - Izicode",
            "description":
              "Izicode vous présente ses projets innovants dans le domaine du développement web et mobile, du design et de la performance.",
            "url": "https://izicode.com/projects",
            "hasPart": projects.map((project) => ({
              "@type": "CreativeWork",
              "name": project.title,
              "description": project.description,
              "url": project.link,
              "image": project.image,
              "about": project.category,
              "author": {
                "@type": "Organization",
                "name": "Izicode"
              },
              "result": project.details?.results || []
            }))
          })}
        </script>
      </Helmet>
      <section className="pt-36 sm:pt-40 lg:pt-48 pb-12 px-4 bg-gradient-to-b from-[#FF3366]/20 to-transparent">
        <motion.div
          className="max-w-7xl mx-auto text-center"
          initial="hidden"
          animate="visible"
          variants={titleVariants}
        >
          <motion.h1 
            className="font-unbounded text-2xl sm:text-3xl lg:text-4xl font-bold mb-8"
          >
            <motion.div 
              className="relative inline-block my-2"
            >
              <span className="relative z-10 text-white">Nos Projets</span>
              <motion.div
                className="absolute -left-1.5 sm:-left-3 -right-1.5 sm:-right-3 -top-1 -bottom-1 bg-[#FF3366] -skew-x-[20deg]"
                initial={{ scale: 0, rotate: 3 }}
                animate={{ scale: 1, rotate: 3 }}
                transition={{ duration: 0.6, delay: 0.3 }}
              />
            </motion.div>
          </motion.h1>
          <motion.p 
            className="text-gray-300 max-w-2xl mx-auto mb-8 text-sm sm:text-base lg:text-lg px-4 mt-6"
          >
            Découvrez nos dernières réalisations et projets en développement web et design digital
          </motion.p>
        </motion.div>
      </section>

      <div className="container mx-auto px-2 sm:px-4 lg:px-6">
        <div className="relative h-[600px] -mt-32 sm:mt-0 flex items-center justify-center">
          <div className="relative w-full max-w-4xl h-full flex items-center justify-center">
            {isLoading ? (
              <CircleLoader />
            ) : error ? (
              <div className="col-span-full text-center text-red-500">
                {error}
                <button
                  onClick={() => {
                    setError(null);
                    fetchProjects();
                  }}
                  className="ml-4 text-blue-500 hover:underline"
                >
                  Réessayer
                </button>
              </div>
            ) : (
              projects.map((project, index) => (
                <motion.div
                  key={project.id}
                  className="absolute w-full max-w-xl cursor-pointer"
                  style={{ 
                    transformOrigin: "center",
                    perspective: "1000px",
                    backfaceVisibility: "hidden",
                    WebkitBackfaceVisibility: "hidden",
                    willChange: "transform",
                    transform: "translateZ(0)"
                  }}
                  animate={getCardStyle(index)}
                  transition={{
                    type: "spring",
                    stiffness: 200,
                    damping: 45,
                    mass: 1.2,
                    opacity: { duration: 0.3 },
                    scale: { duration: 0.4 },
                    rotateY: { duration: 0.5 }
                  }}
                  drag="x"
                  dragElastic={0.12}
                  dragConstraints={{ left: 0, right: 0 }}
                  dragTransition={{ bounceStiffness: 500, bounceDamping: 40 }}
                  onDragStart={() => setDragging(true)}
                  onDragEnd={(e, { offset, velocity }) => {
                    setDragging(false);
                    if (Math.abs(offset.x) > 50 || Math.abs(velocity.x) > 500) {
                      if (offset.x > 0 || velocity.x > 500) {
                        slideLeft();
                      } else {
                        slideRight();
                      }
                    }
                  }}
                  onClick={() => {
                    if (!dragging && index === currentIndex) {
                      setSelectedProject(project);
                    }
                  }}
                >
                  <div 
                    className="relative bg-black/30 backdrop-blur-md border border-white/5 rounded-3xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 relative group"
                    style={{
                      transform: `translateZ(${index === currentIndex ? '0' : '-100px'})`,
                      transformStyle: 'preserve-3d',
                      willChange: 'transform, opacity'
                    }}
                  >
                    <div className="relative aspect-[16/9]">
                      <img
                        src={project.image}
                        alt={project.title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-gradient-to-b from-transparent via-black/20 to-black/80" />
                    </div>

                    <div className="p-5 relative z-10">
                      <div className="mb-2">
                        <span className="text-sm text-[#FF3366] font-medium bg-[#FF3366]/10 px-3 py-1 rounded-full">
                          {project.category}
                        </span>
                      </div>
                      <h3 className="text-xl font-bold mb-3 text-white group-hover:text-[#FF3366] transition-colors">
                        {project.title}
                      </h3>
                      <p className="text-gray-400 text-sm mb-4">
                        {project.description}
                      </p>

                      <div className="grid grid-cols-2 gap-3">
                        {project.details?.results?.slice(0, 2).map((result, idx) => (
                          <div key={idx} className="bg-white/5 backdrop-blur-sm p-3 rounded-xl border border-white/5">
                            <div className="text-2xl font-bold text-[#FF3366] mb-1">
                              {result.split(' ')[0]}
                            </div>
                            <div className="text-xs text-gray-400">
                              {result.split(' ').slice(1).join(' ')}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))
            )}
          </div>

          {/* Navigation mobile */}
          <div className="sm:hidden">
            {/* Compteur au-dessus */}
            <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2 text-white/60 text-sm backdrop-blur-sm bg-black/30 px-3 py-1 rounded-full z-40">
              {currentIndex + 1} / {projects.length}
            </div>
            
            {/* Flèches alignées avec les points */}
            <div className="absolute bottom-4 left-0 right-0 flex justify-between items-center px-4 z-40">
              <button
                onClick={slideLeft}
                className="text-white bg-[#FF3366]/80 hover:bg-[#FF3366] p-2 rounded-full backdrop-blur-sm transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
                </svg>
              </button>
              
              {/* Points au centre */}
              <div className="flex justify-center gap-2">
                {projects.map((_, idx) => (
                  <button
                    key={idx}
                    onClick={() => setCurrentIndex(idx)}
                    className={`w-2 h-2 rounded-full transition-all duration-300 ${
                      idx === currentIndex 
                        ? 'bg-[#FF3366] w-6' 
                        : 'bg-white/30 hover:bg-white/50'
                    }`}
                    aria-label={`Aller au projet ${idx + 1}`}
                  />
                ))}
              </div>

              <button
                onClick={slideRight}
                className="text-white bg-[#FF3366]/80 hover:bg-[#FF3366] p-2 rounded-full backdrop-blur-sm transition-colors"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
              </button>
            </div>
          </div>

          {/* Navigation desktop */}
          <div className="hidden sm:block">
            <button
              onClick={slideLeft}
              className="absolute left-4 z-40 text-white bg-[#FF3366]/80 hover:bg-[#FF3366] p-3 rounded-full backdrop-blur-sm transition-colors"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
              </svg>
            </button>

            <button
              onClick={slideRight}
              className="absolute right-4 z-40 text-white bg-[#FF3366]/80 hover:bg-[#FF3366] p-3 rounded-full backdrop-blur-sm transition-colors"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
              </svg>
            </button>

            {/* Points desktop */}
            <div className="absolute bottom-[-1rem] left-0 right-0 flex justify-center gap-2 z-40">
              {projects.map((_, idx) => (
                <button
                  key={idx}
                  onClick={() => setCurrentIndex(idx)}
                  className={`w-2 h-2 rounded-full transition-all duration-300 ${
                    idx === currentIndex 
                      ? 'bg-[#FF3366] w-6' 
                      : 'bg-white/30 hover:bg-white/50'
                  }`}
                  aria-label={`Aller au projet ${idx + 1}`}
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <section className="py-20 bg-gradient-to-b from-transparent to-[#0a0a0a] relative overflow-hidden">
        <div className="container mx-auto px-4">
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {[
              { 
                number: count.projects,
                suffix: "+",
                label: "Projets Réalisés",
                animate: true
              },
              { 
                number: count.satisfaction,
                suffix: "%",
                label: "Clients Satisfaits",
                animate: true
              },
              { 
                number: "24/7",
                label: "Support Client",
                animate: false
              },
              { 
                number: "100",
                suffix: "%",
                label: "Passion Code",
                animate: false
              }
            ].map((stat, index) => (
              <div
                key={index}
                className="text-center p-6 bg-[#111111]/50 backdrop-blur-sm rounded-2xl border border-gray-800/50 hover:border-[#FF3366]/50 transition-all duration-300 relative group"
              >
                <h3
                  className="text-4xl font-bold bg-gradient-to-r from-blue-500 to-purple-600 text-transparent bg-clip-text mb-2 flex items-center justify-center"
                >
                  <span>{stat.animate ? stat.number : stat.number}</span>
                  {stat.suffix && (
                    <span className="ml-1 inline-block">
                      {stat.suffix}
                    </span>
                  )}
                </h3>
                <p className="text-gray-400">{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 px-4">
        <div className="max-w-3xl mx-auto text-center">
          <h2 className="text-3xl font-unbounded font-bold mb-6">
            Voulez-vous travailler avec nous ?
          </h2>
          <p className="text-gray-400 mb-8">
            Nous sommes toujours à la recherche de nouveaux défis et de projets passionnants.
            Contactez-nous pour discuter de votre projet !
          </p>
          <button
            className="bg-[#FF3366] text-white px-8 py-3 rounded-full hover:bg-[#FF3366]/90 transition-colors"
          >
            Contactez-nous
          </button>
        </div>
      </section>

      {selectedProject && (
        <ProjectModal
          isOpen={!!selectedProject}
          project={selectedProject}
          onClose={() => setSelectedProject(null)}
        />
      )}
    </div>
  );
};

export default Projects;
import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Mail, User, Phone, Building2, MapPin } from 'lucide-react';
import { FormData } from '../types/form';

type Props = {
  data: FormData;
  onChange: (field: keyof FormData, value: any) => void;
};

export const FormStep6: React.FC<Props> = ({ data, onChange }) => {
  const [cityOptions, setCityOptions] = useState<string[]>([]);
  const inputRefs = {
    firstName: useRef<HTMLInputElement>(null),
    lastName: useRef<HTMLInputElement>(null),
    email: useRef<HTMLInputElement>(null),
    phone: useRef<HTMLInputElement>(null),
    company: useRef<HTMLInputElement>(null),
    location: useRef<HTMLInputElement>(null),
    terms: useRef<HTMLInputElement>(null)
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>, 
    currentField: keyof typeof inputRefs
  ) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      const fields = Object.keys(inputRefs) as (keyof typeof inputRefs)[];
      const currentIndex = fields.indexOf(currentField);
      const nextField = fields[currentIndex + 1];
      
      if (nextField && inputRefs[nextField]?.current) {
        inputRefs[nextField].current?.focus();
      }
    }
  };

  const fetchCities = async (query: string) => {
    if (query.length < 2) {
      setCityOptions([]);
      return;
    }

    try {
      // Fetch communes with all postal codes
      const response = await fetch(`https://geo.api.gouv.fr/communes?nom=${encodeURIComponent(query)}&boost=population&limit=10`);
      const data = await response.json();

      // Generate city options with all postal codes
      const cities = data.flatMap((city: any) => 
        city.codesPostaux.map((postalCode: string) => 
          `${city.nom} ${postalCode}`
        )
      );
      setCityOptions(cities);
    } catch (error) {
      console.error('Error fetching cities:', error);
      setCityOptions([]);
    }
  };

  const handleCityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    onChange('location', value);
    fetchCities(value);
  };

  const contactFields = [
    { 
      ref: inputRefs.firstName,
      icon: User, 
      label: 'Prénom', 
      value: 'firstName',
      placeholder: 'Votre prénom',
      type: 'text'
    },
    { 
      ref: inputRefs.lastName,
      icon: User, 
      label: 'Nom', 
      value: 'lastName',
      placeholder: 'Votre nom',
      type: 'text'
    },
    { 
      ref: inputRefs.email,
      icon: Mail, 
      label: 'Email', 
      value: 'email',
      placeholder: 'Votre email',
      type: 'email'
    },
    { 
      ref: inputRefs.phone,
      icon: Phone, 
      label: 'Téléphone', 
      value: 'phone',
      placeholder: 'Votre numéro de téléphone',
      type: 'tel'
    },
    { 
      ref: inputRefs.company,
      icon: Building2, 
      label: 'Entreprise (optionnel)', 
      value: 'company',
      placeholder: 'Nom de votre entreprise',
      type: 'text'
    },
    { 
      ref: inputRefs.location,
      icon: MapPin, 
      label: 'Localisation', 
      value: 'location',
      placeholder: 'Votre ville',
      type: 'text'
    }
  ];

  return (
    <div className="space-y-6">
      <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
        Vos coordonnées
      </h2>
      <div className="grid grid-cols-2 gap-4">
        {contactFields.slice(0, 4).map((field) => (
          <motion.div 
            key={field.value}
            className="flex flex-col space-y-2"
            whileTap={{ scale: 0.95 }}
          >
            <label className="text-white/80 text-sm">{field.label}</label>
            <div className={`
              flex items-center 
              bg-black/40 
              backdrop-blur-sm 
              rounded-xl 
              border 
              border-transparent 
              focus-within:border-[#FF3367]
              transition-all 
              duration-300
            `}>
              <div className={`
                p-2 
                text-white/50 
                border-r 
                border-white/10
              `}>
                <field.icon size={20} />
              </div>
              <input
                ref={field.ref}
                type={field.type}
                value={data[field.value as keyof FormData] || ''}
                onChange={(e) => onChange(field.value as keyof FormData, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, field.value as keyof typeof inputRefs)}
                placeholder={field.placeholder}
                className="
                  w-full 
                  bg-transparent 
                  p-3 
                  text-white 
                  placeholder-white/50 
                  outline-none
                "
              />
            </div>
          </motion.div>
        ))}
      </div>

      <div className="grid grid-cols-2 gap-4">
        {contactFields.slice(4).map((field) => (
          <motion.div 
            key={field.value}
            className="flex flex-col space-y-2"
            whileTap={{ scale: 0.95 }}
          >
            <label className="text-white/80 text-sm">{field.label}</label>
            <div className={`
              flex items-center 
              bg-black/40 
              backdrop-blur-sm 
              rounded-xl 
              border 
              border-transparent 
              focus-within:border-[#FF3367]
              transition-all 
              duration-300
            `}>
              <div className={`
                p-2 
                text-white/50 
                border-r 
                border-white/10
              `}>
                <field.icon size={20} />
              </div>
              <input
                ref={field.ref}
                type={field.type}
                value={data[field.value as keyof FormData] || ''}
                onChange={field.value === 'location' ? handleCityChange : (e) => onChange(field.value as keyof FormData, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, field.value as keyof typeof inputRefs)}
                placeholder={field.placeholder}
                className="
                  w-full 
                  bg-transparent 
                  p-3 
                  text-white 
                  placeholder-white/50 
                  outline-none
                "
              />
            </div>
          </motion.div>
        ))}
        
        {cityOptions.length > 0 && (
          <div className="col-span-2 bg-black/40 rounded-xl p-2 mt-2 max-h-40 overflow-y-auto">
            {cityOptions.map((city, index) => (
              <div 
                key={index} 
                className="p-2 hover:bg-[#FF3367]/20 cursor-pointer"
                onClick={() => {
                  onChange('location', city);
                  setCityOptions([]);
                }}
              >
                {city}
              </div>
            ))}
          </div>
        )}
      </div>

      <motion.div 
        className="flex items-center space-x-3 mt-4"
        whileTap={{ scale: 0.98 }}
      >
        <input
          ref={inputRefs.terms}
          type="checkbox"
          id="terms"
          checked={data.termsAccepted || false}
          onChange={(e) => onChange('termsAccepted', e.target.checked)}
          onKeyDown={(e) => handleKeyDown(e, 'terms')}
          className="
            form-checkbox 
            h-5 
            w-5 
            text-[#FF3367] 
            bg-black/40 
            border-white/20 
            rounded 
            focus:ring-[#FF3367] 
            focus:ring-opacity-50
          "
        />
        <label 
          htmlFor="terms" 
          className="text-white/80 text-sm cursor-pointer"
        >
          J'accepte les conditions générales de service
        </label>
      </motion.div>
    </div>
  );
};

import React from 'react';
import { motion } from 'framer-motion';
import { 
  Search, 
  Settings, 
  FileText, 
  Link, 
  Target, 
  TrendingUp, 
  BarChart2, 
  Globe,
  HelpCircle
} from 'lucide-react';
import { FormData } from '../types/form';

type Props = {
  data: FormData;
  onChange: (field: keyof FormData, value: any) => void;
};

export const FormStep4: React.FC<Props> = ({ data, onChange }) => {
  const seoServices = [
    { 
      icon: Search, 
      label: 'Analyse des mots-clés', 
      value: 'keywords',
      description: 'Identification des meilleures stratégies'
    },
    { 
      icon: Settings, 
      label: 'Optimisation on-page', 
      value: 'onpage',
      description: 'Amélioration technique du site'
    },
    { 
      icon: FileText, 
      label: 'Marketing de contenu', 
      value: 'content',
      description: 'Création de contenu optimisé'
    },
    { 
      icon: Link, 
      label: 'Stratégie de backlinks', 
      value: 'backlinks',
      description: 'Renforcement de l\'autorité'
    },
    { 
      icon: TrendingUp, 
      label: 'Suivi des performances', 
      value: 'tracking',
      description: 'Analyse et reporting'
    },
    { 
      icon: HelpCircle, 
      label: 'Je ne sais pas', 
      value: 'unknown',
      description: 'Besoin de conseil SEO'
    },
  ];

  const socialNetworks = [
    { 
      icon: Globe, 
      label: 'Facebook', 
      value: 'facebook',
      description: 'Réseau social principal' 
    },
    { 
      icon: Globe, 
      label: 'Twitter', 
      value: 'twitter',
      description: 'Communication rapide'
    },
    { 
      icon: Globe, 
      label: 'Instagram', 
      value: 'instagram',
      description: 'Marketing visuel'
    },
    { 
      icon: Globe, 
      label: 'LinkedIn', 
      value: 'linkedin',
      description: 'Réseau professionnel'
    },
    { 
      icon: Globe, 
      label: 'TikTok', 
      value: 'tiktok',
      description: 'Vidéos courtes'
    },
    { 
      icon: HelpCircle, 
      label: 'Je ne sais pas', 
      value: 'unknown',
      description: 'Besoin de recommandations'
    },
  ];

  const toggleArrayField = (field: 'seoServices' | 'socialNetworks', value: string) => {
    const currentValues = data[field] as string[];
    
    // Si "Je ne sais pas" est sélectionné, réinitialiser toutes les autres sélections
    if (value === 'unknown') {
      onChange(field, ['unknown']);
      return;
    }

    // Si "Je ne sais pas" était déjà sélectionné, remplacer par la nouvelle valeur
    if (currentValues.includes('unknown')) {
      onChange(field, [value]);
      return;
    }

    // Logique de sélection/désélection normale
    const newValues = currentValues.includes(value)
      ? currentValues.filter((v) => v !== value)
      : [...currentValues, value];
    
    onChange(field, newValues);
  };

  const renderSection = (
    title: string, 
    items: any[], 
    field: 'seoServices' | 'socialNetworks',
    currentValues: string[]
  ) => (
    <div>
      <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
        {title}
      </h2>
      <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
        {items.map((item) => (
          <motion.div
            key={item.value}
            onClick={() => toggleArrayField(field, item.value)}
            whileTap={{ scale: 0.95 }}
            className={`
              cursor-pointer 
              rounded-xl 
              p-3 
              transition-all 
              duration-300 
              flex 
              flex-col 
              items-start 
              space-y-2
              ${currentValues.includes(item.value) 
                ? 'bg-[#370C15] border-2 border-[#FF3367]' 
                : 'bg-black/40 backdrop-blur-sm hover:bg-black/50'}
            `}
          >
            <div className={`
              p-2 rounded-lg
              ${currentValues.includes(item.value) 
                ? 'bg-[#FF3367]/20' 
                : 'bg-white/10'}
            `}>
              <item.icon className="w-6 h-6 text-white/60" />
            </div>
            <div>
              <h3 className={`
                text-sm font-semibold 
                ${currentValues.includes(item.value) 
                  ? 'text-white' 
                  : 'text-white/70'}
              `}>
                {item.label}
              </h3>
              <p className={`
                text-xs 
                ${currentValues.includes(item.value) 
                  ? 'text-white/80' 
                  : 'text-white/50'}
              `}>
                {item.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-8"
    >
      <div>
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold">Voulez-vous bénéficier de nos services SEO ?</h2>
          <div className="relative inline-block w-14 h-7">
            <input
              type="checkbox"
              className="opacity-0 w-0 h-0"
              checked={data.needsSeo}
              onChange={(e) => onChange('needsSeo', e.target.checked)}
              id="seo-toggle"
            />
            <label
              htmlFor="seo-toggle"
              className={`absolute cursor-pointer top-0 left-0 right-0 bottom-0 rounded-full transition-colors ${
                data.needsSeo ? 'bg-pink-500' : 'bg-gray-300'
              }`}
            >
              <span
                className={`absolute left-1 top-1 w-5 h-5 rounded-full transition-transform bg-white ${
                  data.needsSeo ? 'transform translate-x-7' : ''
                }`}
              />
            </label>
          </div>
        </div>

        {!data.needsSeo && (
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="
              bg-black/40 
              backdrop-blur-sm 
              rounded-xl 
              p-4 
              border-2 
              border-transparent 
              hover:border-white/20
            "
          >
            <div className="flex items-start space-x-4">
              <div className="p-2 rounded-lg bg-white/10">
                <Globe className="w-6 h-6 text-white/60" />
              </div>
              <div>
                <h3 className="text-white font-semibold mb-2">
                  Pourquoi choisir nos services SEO ?
                </h3>
                <p className="text-white/70 text-sm mb-3">
                  Le SEO (Search Engine Optimization) est essentiel pour améliorer la visibilité de votre site web sur les moteurs de recherche comme Google.
                </p>
                <div className="space-y-2">
                  {[
                    { icon: <Target className="w-4 h-4 text-[#FF3367]" />, text: "Analyse approfondie des mots-clés pour cibler votre audience" },
                    { icon: <TrendingUp className="w-4 h-4 text-[#FF3367]" />, text: "Optimisation technique de votre site web" },
                    { icon: <BarChart2 className="w-4 h-4 text-[#FF3367]" />, text: "Création de contenu optimisé et stratégie de backlinks" },
                    { icon: <Search className="w-4 h-4 text-[#FF3367]" />, text: "Suivi et amélioration continue des performances" }
                  ].map((item, index) => (
                    <div key={index} className="flex items-center space-x-2">
                      {item.icon}
                      <span className="text-xs text-white/70">{item.text}</span>
                    </div>
                  ))}
                </div>
                <p className="text-white/60 text-xs mt-3 italic">
                  Activez le bouton ci-dessus pour accéder à nos services SEO et améliorer votre présence en ligne.
                </p>
              </div>
            </div>
          </motion.div>
        )}

        {data.needsSeo && (
          <div className="space-y-8">
            {renderSection(
              'Services SEO souhaités', 
              seoServices, 
              'seoServices', 
              data.seoServices || []
            )}

            <div>
              <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
                Public cible
              </h2>
              <textarea
                value={data.targetAudience}
                onChange={(e) => onChange('targetAudience', e.target.value)}
                placeholder="Décrivez votre audience cible..."
                className="
                  w-full 
                  h-32 
                  p-3 
                  bg-black/40 
                  backdrop-blur-sm 
                  rounded-xl 
                  border-2 
                  border-transparent 
                  hover:border-white/20 
                  text-white 
                  placeholder-white/50 
                  focus:border-[#FF3367] 
                  transition-colors
                "
              />
            </div>

            {renderSection(
              'Réseaux sociaux à intégrer', 
              socialNetworks, 
              'socialNetworks', 
              data.socialNetworks || []
            )}
          </div>
        )}
      </div>
    </motion.div>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import { useInView } from 'react-intersection-observer';
import { 
  Code2, Smartphone, Search, PenTool, Database, Cloud, 
  MessageSquare, Zap, CheckCircle, Clock, Settings, Users,
  Coffee, Rocket, Code, TestTube, Upload, Headphones,
  Plus, DollarSign, Shield, Award, ArrowRight, Globe2, Megaphone, Server, Wrench, GraduationCap, Repeat, Film, ChevronDown
} from 'lucide-react';
import { Helmet } from 'react-helmet';
import Carousel from 'react-spring-3d-carousel';
import { config } from '@react-spring/web';
import '../styles/animatedButton.css';
import AnimatedButton from '../components/AnimatedButton';
import SEO from '../components/SEO/SEO';
import { pageSEOConfigs } from '../config/seo.config';
import { motion } from 'framer-motion';

const itemVariants = {
  hidden: {
    opacity: 0,
    x: '-100vw'
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 60,
      damping: 10,
      mass: 0.6,
      delay: 0.2
    }
  }
};

const titleVariants = {
  hidden: {
    opacity: 0,
    scale: 0.5,
    rotate: 45
  },
  visible: {
    opacity: 1,
    scale: 1,
    rotate: 0,
    transition: {
      type: 'spring',
      stiffness: 150,
      damping: 15,
      mass: 0.4,
      delay: 0.1
    }
  }
};

const cardVariants = {
  hidden: {
    opacity: 0,
    y: 20,
    scale: 0.95
  },
  visible: (index: number) => ({
    opacity: 1,
    y: 0,
    scale: 1,
    transition: {
      duration: 0.5,
      ease: [0.23, 1, 0.32, 1],
      delay: index * 0.1 // Décalage de 0.1s entre chaque card
    }
  })
};

const Services = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const [processRef, processInView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const [techRef, techInView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const [faqRef, faqInView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const [count, setCount] = useState({
    projects: 0,
    clients: 0,
  });

  useEffect(() => {
    if (techInView) {
      const projectsInterval = setInterval(() => {
        setCount(prev => ({
          ...prev,
          projects: prev.projects >= 48 ? 48 : prev.projects + 1
        }));
      }, 50);

      const clientsInterval = setInterval(() => {
        setCount(prev => ({
          ...prev,
          clients: prev.clients >= 36 ? 36 : prev.clients + 1
        }));
      }, 60);

      return () => {
        clearInterval(projectsInterval);
        clearInterval(clientsInterval);
      };
    }
  }, [techInView]);

  const [goToSlide, setGoToSlide] = useState(0);

  const processSteps = [
    { icon: Coffee, title: "Consultation", description: "Discussion approfondie pour comprendre vos besoins", color: "text-[#FF3366]" },
    { icon: Rocket, title: "Planification", description: "Élaboration d'une stratégie sur mesure", color: "text-blue-500" },
    { icon: Code, title: "Développement", description: "Création de votre solution avec les meilleures pratiques", color: "text-green-500" },
    { icon: TestTube, title: "Tests", description: "Validation rigoureuse de chaque fonctionnalité", color: "text-purple-500" },
    { icon: Upload, title: "Déploiement", description: "Mise en ligne sécurisée de votre projet", color: "text-yellow-500" },
    { icon: Headphones, title: "Support", description: "Accompagnement continu et maintenance", color: "text-cyan-500" }
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      setGoToSlide(state => (state + 1) % processSteps.length);
    }, 4000);
    return () => clearInterval(timer);
  }, [processSteps.length]);

  const slides = processSteps.map((step, index) => ({
    key: index,
    content: (
      <div 
        className={`w-[400px] process-card p-10 rounded-xl group transition-all duration-300 ${
          index === goToSlide ? 'active' : ''
        }`}
      >
        <div className="relative z-10">
          <step.icon className={`w-16 h-16 ${step.color} mb-8 mx-auto`} />
          <h3 className="text-3xl font-bold mb-6 text-center">{step.title}</h3>
          <p className="text-gray-400 text-center text-lg">{step.description}</p>
        </div>
      </div>
    )
  }));

  const [expandedCard, setExpandedCard] = useState<number | null>(null);
  const servicesContainerRef = useRef<HTMLDivElement>(null);
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const container = servicesContainerRef.current;
    if (!container) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.getAttribute('data-index') || '0');
            setActiveSlide(index);
          }
        });
      },
      {
        root: container,
        threshold: 0.5,
        rootMargin: '0px'
      }
    );

    container.querySelectorAll('.service-card').forEach((card) => {
      observer.observe(card);
    });

    return () => observer.disconnect();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;
      if (!target.closest('.service-card')) {
        setExpandedCard(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleExpand = (index: number) => {
    if (expandedCard === index) {
      setExpandedCard(null);
      if (servicesContainerRef.current) {
        const yOffset = -150; 
        const y = servicesContainerRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    } else {
      setExpandedCard(index);
    }
  };

  const services = [
    {
      icon: Globe2,
      title: 'Création de sites web',
      description: 'Des solutions web adaptées à vos besoins, de la vitrine au e-commerce.',
      categories: [
        {
          name: 'Sites vitrines',
          features: [
            'Développement rapide avec des designs modernes',
            'Intégration d\'un formulaire de contact et d\'outils de suivi',
            'Optimisation SEO et performances',
            'Responsive design pour tous les appareils'
          ]
        },
        {
          name: 'Sites e-commerce',
          features: [
            'Intégration de CMS (Shopify, WooCommerce ou sur-mesure)',
            'Systèmes de paiement sécurisés (Stripe, PayPal)',
            'Gestion des stocks et des commandes',
            'Tableau de bord administrateur personnalisé'
          ]
        },
        {
          name: 'Sites sur-mesure',
          features: [
            'Applications web progressives (PWA)',
            'Intégration d\'API et de fonctionnalités spécifiques',
            'Architecture scalable et performante',
            'Solutions techniques innovantes'
          ]
        }
      ],
      color: 'text-blue-500'
    },
    {
      icon: Smartphone,
      title: 'Développement d\'applications mobiles',
      description: 'Applications mobiles natives et cross-platform pour iOS et Android.',
      categories: [
        {
          name: 'Applications natives',
          features: [
            'Développement iOS (Swift, SwiftUI)',
            'Développement Android (Kotlin)',
            'Performances optimales et expérience utilisateur native',
            'Accès aux fonctionnalités avancées des appareils'
          ]
        },
        {
          name: 'Applications cross-platform',
          features: [
            'Développement React Native',
            'Développement Flutter',
            'Code unique pour iOS et Android',
            'Maintenance simplifiée'
          ]
        }
      ],
      color: 'text-green-500'
    },
    {
      icon: PenTool,
      title: 'Design et UX/UI',
      description: 'Création d\'interfaces utilisateur intuitives et d\'expériences exceptionnelles.',
      categories: [
        {
          name: 'Design d\'interface (UI)',
          features: [
            'Création d\'interfaces modernes et attrayantes',
            'Design system et composants réutilisables',
            'Cohérence visuelle et identité de marque',
            'Prototypes interactifs'
          ]
        },
        {
          name: 'Expérience utilisateur (UX)',
          features: [
            'Recherche utilisateur et personas',
            'Tests d\'utilisabilité',
            'Architecture de l\'information',
            'Parcours utilisateur optimisés'
          ]
        }
      ],
      color: 'text-pink-500'
    },
    {
      icon: Megaphone,
      title: 'Marketing digital',
      description: 'Stratégies marketing complètes pour augmenter votre visibilité en ligne.',
      categories: [
        {
          name: 'SEO',
          features: [
            'Audit et optimisation technique',
            'Stratégie de contenu et mots-clés',
            'Optimisation on-page et off-page',
            'Suivi des performances'
          ]
        },
        {
          name: 'Marketing de contenu',
          features: [
            'Création de contenu engageant',
            'Stratégie éditoriale',
            'Distribution multicanal',
            'Analyse des performances'
          ]
        }
      ],
      color: 'text-purple-500'
    },
    {
      icon: Server,
      title: 'Hébergement et maintenance',
      description: 'Solutions d\'hébergement sécurisées et maintenance proactive.',
      categories: [
        {
          name: 'Hébergement',
          features: [
            'Solutions cloud scalables',
            'Certificats SSL et sécurité',
            'Backups automatisés',
            'Monitoring 24/7'
          ]
        },
        {
          name: 'Maintenance',
          features: [
            'Mises à jour de sécurité',
            'Optimisation des performances',
            'Support technique',
            'Résolution des problèmes'
          ]
        }
      ],
      color: 'text-orange-500'
    },
    {
      icon: Wrench,
      title: 'Développement d\'outils sur-mesure',
      description: 'Outils et applications personnalisés pour optimiser vos processus.',
      categories: [
        {
          name: 'Automatisation',
          features: [
            'Scripts et bots personnalisés',
            'Intégration de systèmes',
            'Workflows automatisés',
            'APIs sur mesure'
          ]
        },
        {
          name: 'Outils internes',
          features: [
            'Dashboards personnalisés',
            'CRM et ERP sur mesure',
            'Outils de gestion',
            'Solutions métier'
          ]
        }
      ],
      color: 'text-yellow-500'
    },
    {
      icon: GraduationCap,
      title: 'Formation et consulting',
      description: 'Accompagnement et formation pour vos équipes.',
      categories: [
        {
          name: 'Formation',
          features: [
            'Formation technique',
            'Workshops pratiques',
            'Documentation personnalisée',
            'Accompagnement continu'
          ]
        },
        {
          name: 'Consulting',
          features: [
            'Audit technique',
            'Recommandations stratégiques',
            'Architecture de solutions',
            'Optimisation des processus'
          ]
        }
      ],
      color: 'text-cyan-500'
    },
    {
      icon: Repeat,
      title: 'Services d\'abonnement',
      description: 'Services récurrents pour un accompagnement continu.',
      categories: [
        {
          name: 'Maintenance',
          features: [
            'Mises à jour régulières',
            'Support prioritaire',
            'Monitoring continu',
            'Rapports mensuels'
          ]
        },
        {
          name: 'Marketing',
          features: [
            'Gestion des réseaux sociaux',
            'Création de contenu régulier',
            'Campagnes publicitaires',
            'Reporting mensuel'
          ]
        }
      ],
      color: 'text-indigo-500'
    },
    {
      icon: Film,
      title: 'Création de contenu',
      description: 'Création de contenu engageant pour votre présence en ligne.',
      categories: [
        {
          name: 'Contenu web',
          features: [
            'Rédaction web SEO',
            'Articles de blog',
            'Pages de vente',
            'Newsletters'
          ]
        },
        {
          name: 'Contenu visuel',
          features: [
            'Photos et vidéos',
            'Infographies',
            'Motion design',
            'Illustrations'
          ]
        }
      ],
      color: 'text-rose-500'
    }
  ];

  return (
    <>
      <SEO {...pageSEOConfigs.services} />
      <div className="min-h-screen">
        {/* Hero Section */}
        <section className="pt-36 sm:pt-40 lg:pt-48 pb-12 px-4 bg-gradient-to-b from-[#FF3366]/20 to-transparent">
          <motion.div
            className="max-w-7xl mx-auto text-center"
            initial="hidden"
            animate="visible"
            variants={titleVariants}
          >
            <motion.h1 
              className="font-unbounded text-2xl sm:text-3xl lg:text-4xl font-bold mb-8"
            >
              <motion.div 
                className="relative inline-block my-2"
              >
                <span className="relative z-10 text-white">Nos Services</span>
                <motion.div
                  className="absolute -left-1.5 sm:-left-3 -right-1.5 sm:-right-3 -top-1 -bottom-1 bg-[#FF3366] -skew-x-[20deg]"
                  initial={{ scale: 0, rotate: 3 }}
                  animate={{ scale: 1, rotate: 3 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                />
              </motion.div>
            </motion.h1>
            <p 
              className="text-gray-300 max-w-2xl mx-auto mb-8 text-sm sm:text-base lg:text-lg px-4 mt-6"
            >
              Découvrez nos services sur mesure en développement web et design digital
            </p>
          </motion.div>
        </section>

        {/* Services Grid */}
        <section className="pt-2 pb-4 md:py-20 relative">
          {/* Fond avec motif */}
          <div className="absolute inset-0 -top-40 bg-[#0a0a0a] opacity-40" 
            style={{
              backgroundImage: `radial-gradient(circle at 1px 1px, rgb(75, 85, 99) 1px, transparent 0)`,
              backgroundSize: '40px 40px',
              maskImage: 'linear-gradient(to bottom, transparent 0%, black 15%, black 100%)',
              WebkitMaskImage: 'linear-gradient(to bottom, transparent 0%, black 15%, black 100%)'
            }}
          />
          
          <div 
            className="max-w-7xl mx-auto relative z-10"
          >
            {/* Indicateurs de pagination - visible uniquement sur mobile */}
            <div className="flex justify-center gap-2 mb-6 md:hidden">
              {services.map((_, index) => (
                <div
                  key={index}
                  className={`w-2 h-2 rounded-full transition-all duration-300 
                            ${activeSlide === index 
                              ? 'bg-white/80 w-4' 
                              : 'bg-white/20'}`}
                />
              ))}
            </div>

            {/* Container pour le scroll horizontal sur mobile */}
            <div ref={servicesContainerRef}
                 className={`md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-8 
                          flex overflow-x-auto snap-x snap-mandatory 
                          scrollbar-hide services-scroll-container
                          -mx-4 px-8 md:px-4 md:mx-0
                          md:overflow-x-visible
                          pt-8 mt-4
                          ${expandedCard !== null ? 'pb-[60vh]' : 'pb-6'}`}>
              {services.map((service, index) => (
                <motion.div
                  key={index}
                  variants={cardVariants}
                  initial="hidden"
                  whileInView="visible"
                  viewport={{ once: true, margin: "-100px" }}
                  custom={index}
                  data-index={index}
                  className={`service-card relative
                            flex-shrink-0 w-[80vw] md:w-auto 
                            snap-center
                            transition-all duration-300 ease-out
                            ${index > 0 ? 'ml-6 md:ml-0' : ''}`}
                >
                  <div
                    className={`backdrop-blur-xl border border-white/5 shadow-xl p-8 rounded-[2rem] 
                              hover:border-white/10 transition-all duration-300 relative flex flex-col 
                              md:bg-black/40 md:hover:bg-black/50 
                              bg-[#111111] hover:bg-[#111111]/90
                              transform-gpu transition-transform duration-300
                              h-[320px] md:h-[280px]
                              ${expandedCard === index 
                                ? 'scale-[1.02] shadow-2xl !h-auto min-h-[320px] md:min-h-[280px] absolute inset-x-0 top-0 z-50' 
                                : ''}`}
                  >
                    <div className="flex items-center gap-4 mb-4">
                      <service.icon className={`w-12 h-12 ${service.color}`} />
                      <h3 className="text-2xl font-bold">{service.title}</h3>
                    </div>
                    <p className="text-gray-400 mb-4">{service.description}</p>
                    
                    {/* Liste des catégories avec leur contenu */}
                    <div className="flex-grow">
                      {service.categories.map((category, catIndex) => (
                        <div key={catIndex} className="mb-2 last:mb-0">
                          <div className={`font-medium transition-colors duration-300 ${
                            expandedCard === index ? service.color : 'text-gray-200'
                          }`}>
                            {category.name}
                          </div>
                          
                          <div 
                            className={`transform-gpu transition-all duration-300 ease-in-out overflow-hidden ${
                              expandedCard === index ? 'max-h-[500px] opacity-100' : 'max-h-0 opacity-0'
                            }`}
                          >
                            <ul className="space-y-2 ml-4 mt-2">
                              {category.features.map((feature, featureIndex) => (
                                <li key={featureIndex} className="flex items-start gap-2 text-gray-300">
                                  <div className={`w-1.5 h-1.5 rounded-full ${service.color.replace('text-', 'bg-')} mt-2`} />
                                  {feature}
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ))}
                    </div>

                    {/* Conteneur pour le bouton */}
                    <div className="absolute -bottom-6 left-0 right-0 flex justify-center">
                      <button
                        onClick={() => handleExpand(index)}
                        className={`w-12 h-12 rounded-full flex items-center justify-center bg-white/10 backdrop-blur-sm border-2 border-white/20 hover:bg-white/20 hover:border-white/30 transition-all duration-300 shadow-lg transform-gpu ${
                          expandedCard === index ? 'rotate-180' : ''
                        }`}
                      >
                        <ChevronDown className="w-6 h-6 text-white/80" />
                      </button>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>

          </div>
        </section>

        {/* Process Section */}
        <section className="relative py-20 px-4 overflow-hidden" ref={processRef}>
          <div 
            className="absolute inset-0 bg-cover bg-center bg-fixed opacity-10"
            style={{
              backgroundImage: 'url("/Images/services/background.AVIF")',
            }}
          ></div>
          <div className="absolute inset-0 bg-gradient-to-b from-black/50 to-black/80"></div>
          <div
            className="relative max-w-7xl mx-auto"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl font-unbounded font-bold mb-6">Notre Processus</h2>
              <p className="text-gray-400 max-w-2xl mx-auto">
                Une méthodologie éprouvée pour transformer votre vision en réalité
              </p>
            </div>

            <div className="relative w-full max-w-[1400px] mx-auto">
              <div className="h-[600px]">
                <Carousel
                  slides={slides}
                  goToSlide={goToSlide}
                  offsetRadius={2}
                  showNavigation={false}
                  animationConfig={config.slow}
                  offsetFn={(offsetFromCenter) => ({
                    opacity: 1 - Math.abs(offsetFromCenter) * 0.3,
                    scale: 1 - Math.abs(offsetFromCenter) * 0.2
                  })}
                />
              </div>
            </div>

            <div className="flex justify-center gap-2 mt-8">
              {processSteps.map((_, index) => (
                <button
                  key={index}
                  className={`w-3 h-3 rounded-full transition-all duration-300 ${
                    index === goToSlide ? 'bg-[#FF3366]' : 'bg-gray-600 hover:bg-[#FF3366]/50'
                  }`}
                  onClick={() => setGoToSlide(index)}
                />
              ))}
            </div>
          </div>
        </section>

        {/* Technologies Section */}
        <section className="py-20 px-4 bg-black/30" ref={techRef}>
          <div
            className="max-w-7xl mx-auto"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl font-unbounded font-bold mb-6">Technologies</h2>
              <p className="text-gray-400 max-w-2xl mx-auto">
                Notre stack technologique pour des solutions performantes
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  category: "Frontend",
                  techs: ["React", "Vue.js", "Next.js", "TypeScript", "Tailwind CSS"],
                  icon: Code2,
                  color: "from-[#FF3366] to-purple-600"
                },
                {
                  category: "Backend",
                  techs: ["Node.js", "Python", "PHP", "Java", "MongoDB"],
                  icon: Database,
                  color: "from-blue-500 to-[#FF3366]"
                },
                {
                  category: "Mobile & DevOps",
                  techs: ["React Native", "Flutter", "Docker", "AWS", "CI/CD"],
                  icon: Smartphone,
                  color: "from-purple-600 to-blue-500"
                }
              ].map((stack, index) => (
                <div
                  key={index}
                  className="group relative bg-[#111111] dark:bg-[#111111] rounded-3xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300"
                >
                  <div className="p-8">
                    <div className="mb-6 relative">
                      <div className="absolute inset-0 bg-gradient-to-r from-[#FF3366]/20 to-purple-600/20 blur-xl opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
                      <stack.icon className={`w-16 h-16 bg-gradient-to-r ${stack.color} p-3 rounded-2xl text-white mb-4`} />
                      <h3 className="text-2xl font-bold mb-2">{stack.category}</h3>
                    </div>
                    
                    <div className="space-y-3">
                      {stack.techs.map((tech, idx) => (
                        <div
                          key={idx}
                          className="flex items-center space-x-3 bg-black/20 p-4 rounded-xl group-hover:bg-black/30 transition-colors duration-300"
                        >
                          <div className={`w-2 h-2 rounded-full bg-gradient-to-r ${stack.color}`} />
                          <span className="text-gray-300">{tech}</span>
                        </div>
                      ))}
                    </div>

                    {/* Particules d'arrière-plan */}
                    <div className="absolute inset-0 -z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                      {[...Array(5)].map((_, i) => (
                        <div
                          key={i}
                          className="absolute w-2 h-2 bg-[#FF3366]/20 rounded-full"
                          style={{
                            left: `${20 + (i * 15)}%`,
                            bottom: "10%"
                          }}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* FAQ Section */}
        <section className="py-20 px-4" ref={faqRef}>
          <div
            className="max-w-7xl mx-auto"
          >
            <div className="text-center mb-16">
              <h2 className="text-4xl font-unbounded font-bold mb-6">Questions Fréquentes</h2>
              <p className="text-gray-400 max-w-2xl mx-auto">
                Réponses à vos interrogations sur nos services
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {[
                {
                  q: "Combien de temps faut-il pour développer un site web ?",
                  a: "Le délai varie selon la complexité du projet, généralement de 4 à 12 semaines."
                },
                {
                  q: "Proposez-vous un support après la mise en ligne ?",
                  a: "Oui, nous offrons un support continu et une maintenance régulière."
                },
                {
                  q: "Comment assurez-vous la sécurité des applications ?",
                  a: "Nous suivons les meilleures pratiques de sécurité et effectuons des tests réguliers."
                },
                {
                  q: "Puis-je modifier mon site web moi-même ?",
                  a: "Oui, nous fournissons un CMS intuitif pour gérer votre contenu."
                }
              ].map((faq, index) => (
                <div
                  key={index}
                  className="bg-black/50 backdrop-blur-lg p-8 rounded-xl"
                >
                  <h3 className="text-2xl font-bold mb-4 flex items-center gap-3">
                    <MessageSquare className="w-6 h-6 text-[#FF3366]" />
                    {faq.q}
                  </h3>
                  <p className="text-gray-400">{faq.a}</p>
                </div>
              ))}
            </div>
            <div className="text-center mt-12">
              <a
                href="/faq"
                className="inline-flex items-center gap-2 px-8 py-4 bg-[#FF3366] text-white rounded-full font-semibold transition-all duration-300"
              >
                Voir la FAQ
                <ArrowRight className="w-5 h-5" />
              </a>
            </div>
          </div>
        </section>

        {/* Garanties Section */}
        <section className="py-20 px-4 bg-black/30 relative">
          {/* Fond avec motif */}
          <div className="absolute inset-0 bg-[#0a0a0a] opacity-40 z-0" 
            style={{
              backgroundImage: `radial-gradient(circle at 1px 1px, rgb(75, 85, 99) 1px, transparent 0)`,
              backgroundSize: '40px 40px'
            }}
          />
          
          <div
            className="max-w-7xl mx-auto relative z-20"
          >
            <div className="text-center mb-16 relative z-20">
              <h2 className="text-4xl font-unbounded font-bold mb-6">Nos Garanties</h2>
              <p className="text-gray-400 max-w-2xl mx-auto">
                Notre engagement pour votre satisfaction
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {[
                {
                  icon: Shield,
                  title: "Sécurité Garantie",
                  description: "Protection des données et conformité RGPD",
                  color: "text-[#FF3366]"
                },
                {
                  icon: Clock,
                  title: "Respect des Délais",
                  description: "Livraison dans les temps convenus",
                  color: "text-blue-500"
                },
                {
                  icon: Award,
                  title: "Qualité Premium",
                  description: "Standards de qualité les plus élevés",
                  color: "text-green-500"
                }
              ].map((garantie, index) => (
                <div
                  key={index}
                  className="bg-black/50 backdrop-blur-lg p-8 rounded-xl text-center relative z-20"
                >
                  <garantie.icon className={`w-12 h-12 ${garantie.color} mx-auto mb-6`} />
                  <h3 className="text-2xl font-bold mb-4">{garantie.title}</h3>
                  <p className="text-gray-400">{garantie.description}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 px-4 bg-black/30">
          <div 
            className="max-w-3xl mx-auto text-center"
          >
            <h2 
              className="text-3xl font-unbounded font-bold mb-4"
            >
              Prêt à Démarrer?
            </h2>
            <p 
              className="text-gray-400 mb-8"
            >
              Contactez-nous pour discuter de votre projet et découvrir comment nous pouvons vous aider
            </p>
            <AnimatedButton
              text="Demander un Devis"
              href="/devis"
              className="bg-gradient-to-r from-[#FF3366] to-[#FF6666] text-white px-10 py-4 rounded-full hover:bg-gradient-to-r hover:from-[#FF3366]/90 hover:to-[#FF6666]/90 transition-all transform hover:scale-105 inline-block text-xl"
            />
          </div>
        </section>
      </div>
    </>
  );
};

export default Services;
import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Globe, Smartphone, Search, Paintbrush, Calendar, Rocket, Code, Layout, Target, DollarSign, User, Check, Home } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { pageSEOConfigs } from '../config/seo.config';
import { FormData } from '../components/QuoteForm/types/form';
import { StepIndicator } from '../components/QuoteForm/StepIndicator';
import { FormStep1 } from '../components/QuoteForm/steps/FormStep1';
import { FormStep2 } from '../components/QuoteForm/steps/FormStep2';
import { FormStep3 } from '../components/QuoteForm/steps/FormStep3';
import { FormStep4 } from '../components/QuoteForm/steps/FormStep4';
import { FormStep5 } from '../components/QuoteForm/steps/FormStep5';
import { FormStep6 } from '../components/QuoteForm/steps/FormStep6';
import { FormSummary } from '../components/QuoteForm/steps/FormSummary';
import { useFormValidation } from '../components/QuoteForm/hooks/useFormValidation';
import { steps } from '../components/QuoteForm/constants/steps';

const Quote = () => {
  const [currentStep, setCurrentStep] = useState(1);
  const [showSummary, setShowSummary] = useState(false);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  const navigate = useNavigate();
  const totalSteps = 6;
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const stepsRef = useRef<(HTMLDivElement | null)[]>([]);

  const [formData, setFormData] = useState<FormData>({
    projectType: [],
    projectDescription: '',
    deadline: '',
    hasSpecifications: false,
    features: [],
    technologies: [],
    compatibility: [],
    visualStyle: '',
    colorPalette: '',
    designInspiration: '',
    hasGraphicCharter: false,
    designNotes: '',
    needsSeo: false,
    seoServices: [],
    targetAudience: '',
    socialNetworks: [],
    budget: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
  });

  const { isStepValid, getStepErrors } = useFormValidation();

  const handleChange = (field: keyof FormData, value: any) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
        delayChildren: 0.3
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.43, 0.13, 0.23, 0.96]
      }
    }
  };

  const handleNext = () => {
    if (currentStep < totalSteps) {
      setCurrentStep((prev) => prev + 1);
    } else {
      setShowSummary(true);
    }
  };

  const handlePrevious = () => {
    if (showSummary) {
      setShowSummary(false);
      setCurrentStep(totalSteps);
    } else if (currentStep > 1) {
      setCurrentStep((prev) => prev - 1);
    }
  };

  const handleEditStep = (step: number) => {
    setShowSummary(false);
    setCurrentStep(step);
  };

  const handleSubmit = (e?: React.FormEvent) => {
    if (e) {
      e.preventDefault(); // Empêche le rechargement de la page
    }
    console.log('Form submitted:', formData);
    // Ajoutez ici votre logique de soumission de formulaire
  };

  const renderStep = () => {
    if (showSummary) {
      return <FormSummary data={formData} onEditStep={handleEditStep} />;
    }

    switch (currentStep) {
      case 1:
        return <FormStep1 data={formData} onChange={handleChange} />;
      case 2:
        return <FormStep2 data={formData} onChange={handleChange} />;
      case 3:
        return <FormStep3 data={formData} onChange={handleChange} />;
      case 4:
        return <FormStep4 data={formData} onChange={handleChange} />;
      case 5:
        return <FormStep5 data={formData} onChange={handleChange} />;
      case 6:
        return <FormStep6 data={formData} onChange={handleChange} />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (scrollContainerRef.current && stepsRef.current[currentStep - 1]) {
      const container = scrollContainerRef.current;
      const activeStep = stepsRef.current[currentStep - 1];
      
      if (activeStep) {
        const containerWidth = container.offsetWidth;
        const stepLeft = activeStep.offsetLeft;
        const stepWidth = activeStep.offsetWidth;
        
        const scrollPosition = stepLeft - (containerWidth / 2) + (stepWidth / 2);
        
        container.scrollTo({
          left: scrollPosition,
          behavior: 'smooth'
        });
      }
    }
  }, [currentStep]);

  return (
    <>
      <Helmet>
        <title>{pageSEOConfigs.quote.title}</title>
        <meta name="description" content={pageSEOConfigs.quote.description} />
        <meta name="keywords" content={pageSEOConfigs.quote.keywords.join(', ')} />
        <link rel="canonical" href={pageSEOConfigs.quote.canonical} />
        <meta property="og:type" content={pageSEOConfigs.quote.ogType} />
        <meta property="og:title" content={pageSEOConfigs.quote.ogTitle} />
        <meta property="og:description" content={pageSEOConfigs.quote.ogDescription} />
        <meta property="og:image" content={pageSEOConfigs.quote.ogImage} />
        <meta name="twitter:card" content={pageSEOConfigs.quote.twitterCard} />
        <meta name="language" content={pageSEOConfigs.quote.language} />
      </Helmet>
      
      <form onSubmit={handleSubmit} className="relative min-h-screen pt-32 sm:pt-24 md:pt-32 lg:pt-52 pb-12 sm:pb-16 md:pb-20 px-3 sm:px-4 overflow-hidden">
        {/* Fond avec dégradé */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#FF3366]/20 via-[#FF3366]/10 to-transparent z-0" />
        
        {/* Motif de points avec masque de dégradé */}
        <div 
          className="absolute inset-0 z-0 opacity-40"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgb(75, 85, 99) 1px, transparent 0)`,
            backgroundSize: '40px 40px',
            maskImage: 'linear-gradient(to bottom, transparent, black 10%, black)',
            WebkitMaskImage: 'linear-gradient(to bottom, transparent, black 10%, black)'
          }}
        />

        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
          className="relative z-10 max-w-[90%] sm:max-w-2xl md:max-w-4xl lg:max-w-5xl mx-auto"
        >
          <motion.div variants={itemVariants}>
            <h1 className="font-unbounded text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-4 sm:mb-6 md:mb-8">
              <span className="relative">
                Demander un devis
                <motion.div
                  className="absolute -left-4 -right-4 top-0 bottom-0 bg-[#FF3366] -skew-x-[20deg] -z-10"
                  initial={{ scale: 0, rotate: 3 }}
                  animate={{ scale: 1, rotate: 3 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                />
              </span>
            </h1>
            <p className="text-sm sm:text-base md:text-lg text-center text-white/60 mb-6 sm:mb-8 md:mb-12">
              Parlons de votre projet et voyons comment nous pouvons vous aider à le réaliser.
            </p>
          </motion.div>

          <motion.div 
            variants={itemVariants}
            className="bg-black/40 backdrop-blur-sm rounded-xl sm:rounded-2xl p-4 sm:p-6 md:p-8 shadow-xl border border-white/10"
          >
            <StepIndicator currentStep={showSummary ? totalSteps : currentStep} steps={steps} />

            <style jsx global>{`
              .overflow-x-auto {
                -ms-overflow-style: none;
                scrollbar-width: none;
              }
              .overflow-x-auto::-webkit-scrollbar {
                display: none;
              }
            `}</style>

            <AnimatePresence mode="wait">
              {renderStep()}
            </AnimatePresence>

            <div className="flex justify-between mt-8">
              <motion.button
                type="button"
                onClick={handlePrevious}
                disabled={currentStep === 1 && !showSummary}
                className={`flex items-center px-4 sm:px-6 py-2 sm:py-3 rounded-full ${
                  currentStep === 1 && !showSummary
                    ? 'bg-white/5 text-gray-400 cursor-not-allowed'
                    : 'bg-white/10 hover:bg-white/20 text-white'
                } transition-colors`}
              >
                <ChevronLeft className="w-4 h-4 sm:w-5 sm:h-5 mr-1 sm:mr-2" />
                Précédent
              </motion.button>

              {!showSummary && currentStep !== totalSteps && (
                <motion.button
                  type="button"
                  onClick={handleNext}
                  disabled={!isStepValid(currentStep, formData)}
                  className={`flex items-center px-4 sm:px-6 py-2 sm:py-3 rounded-full ${
                    isStepValid(currentStep, formData)
                      ? 'bg-[#FF3366] hover:bg-[#FF3366]/90 text-white'
                      : 'bg-white/10 text-gray-400 cursor-not-allowed'
                  } transition-colors`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Suivant
                  <ChevronRight className="w-4 h-4 sm:w-5 sm:h-5 ml-1 sm:ml-2" />
                </motion.button>
              )}

              {currentStep === totalSteps && !showSummary && (
                <motion.button
                  type="button"
                  onClick={() => setShowSummary(true)}
                  disabled={!isStepValid(currentStep, formData)}
                  className={`flex items-center px-4 sm:px-6 py-2 sm:py-3 rounded-full ${
                    isStepValid(currentStep, formData)
                      ? 'bg-[#FF3366] hover:bg-[#FF3366]/90 text-white'
                      : 'bg-white/10 text-gray-400 cursor-not-allowed'
                  } transition-colors`}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Voir le récapitulatif
                  <Check className="w-4 h-4 sm:w-5 sm:h-5 ml-1 sm:ml-2" />
                </motion.button>
              )}

              {showSummary && (
                <motion.button
                  type="button"
                  onClick={handleSubmit}
                  className="flex items-center px-4 sm:px-6 py-2 sm:py-3 rounded-full bg-[#FF3366] hover:bg-[#FF3366]/90 text-white transition-colors"
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  Envoyer la demande
                </motion.button>
              )}
            </div>
          </motion.div>
        </motion.div>
      </form>
    </>
  );
};

export default Quote;
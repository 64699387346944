import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Phone, Mail, MessageCircle, Clock, MapPin, ArrowRight, Users, Bot, Rocket, PhoneCall, Send, FormInput, Calendar } from 'lucide-react';
import { BsWhatsapp, BsFillEnvelopeFill, BsLinkedin, BsInstagram, BsFacebook, BsTwitterX } from "react-icons/bs";
import { Link } from 'react-router-dom';
import SEO from '../components/SEO/SEO';
import { pageSEOConfigs } from '../config/seo.config';
import locationMap from '../assets/location-map.svg';
import clockSchedule from '../assets/clock-schedule.svg';

const Contact = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const [methodsRef, methodsInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
    margin: "-100px"
  });

  const [featuresRef, featuresInView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
    margin: "-100px"
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

  const cardVariants = {
    hidden: { 
      opacity: 0,
      y: 20,
      scale: 0.98
    },
    visible: (index: number) => ({
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 70,
        damping: 15,
        mass: 0.8,
        duration: 0.5,
        delay: index * 0.08
      }
    })
  };

  const contactMethods = [
    {
      title: "Téléphone",
      description: "Discutons de votre projet",
      shortDescription: "", 
      icon: <PhoneCall className="w-8 h-8 md:w-14 md:h-14" strokeWidth={2.5} />,
      action: "Appeler",
      actionIcon: <Phone className="w-5 h-5" />,
      link: "tel:+33766066496",
    },
    {
      title: "Email",
      description: "Envoyez-nous un email détaillé",
      icon: <BsFillEnvelopeFill className="w-8 h-8 md:w-14 md:h-14" />,
      action: "Email",
      actionIcon: <Send className="w-5 h-5" />,
      link: `mailto:contact@izicode.fr?subject=${encodeURIComponent("Nouvelle demande - IziCode")}&body=${encodeURIComponent(`
------------------
INFORMATIONS PERSONNELLES
Nom : 
Prénom : 
Email : 
Téléphone : 

TYPE DE DEMANDE
(Merci de marquer un X dans les [ ] correspondants)
[ ] Demande d'informations
[ ] Étude de projet
[ ] Demande de devis
[ ] Développement web
[ ] Développement mobile
[ ] Autre

MESSAGE
------------------


------------------
Note : Les informations fournies dans ce mail ne seront pas sauvegardées dans une base de données et seront uniquement utilisées pour répondre à votre demande.`)}`,
    },
    {
      title: "WhatsApp",
      description: "Discutons sur WhatsApp",
      icon: <BsWhatsapp className="w-8 h-8 md:w-14 md:h-14" />,
      action: "WhatsApp",
      actionIcon: <BsWhatsapp className="w-5 h-5" />,
      link: "https://wa.me/33766066496",
    }
  ];

  const features = [
    {
      icon: <Clock className="w-6 h-6" />,
      title: "Réponse Rapide",
      description: "Nous nous engageons à répondre sous 24h maximum"
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Support Personnalisé",
      description: "Une équipe dédiée à votre projet"
    },
    {
      icon: <Bot className="w-6 h-6" />,
      title: "Expertise Technique",
      description: "Des solutions adaptées à vos besoins"
    },
    {
      icon: <Rocket className="w-6 h-6" />,
      title: "Suivi de Projet",
      description: "Un accompagnement de A à Z"
    }
  ];

  const locationInfo = {
    title: "Notre localisation",
    description: "Amiens & International",
    icon: <img src={locationMap} alt="Location" className="w-12 h-12 md:w-16 md:h-16" />,
    details: "Basés à Amiens, nous intervenons partout en France et à l'international. Notre équipe est disponible pour des rendez-vous en présentiel ou en visioconférence."
  };

  const scheduleInfo = {
    title: "Nos horaires",
    description: "Disponible pour vous servir",
    icon: <img src={clockSchedule} alt="Schedule" className="w-6 h-6 md:w-8 md:h-8" />,
    details: [
      { day: "Lundi - Vendredi", hours: "9h - 17h", status: "open" },
      { day: "Samedi", hours: "9h - 12h sur rendez-vous", status: "limited" },
      { day: "Dimanche", hours: "Fermé", status: "closed" }
    ]
  };

  return (
    <>
      <SEO {...pageSEOConfigs.contact} />
      <div className="relative min-h-screen flex flex-col items-start justify-center pt-36 sm:pt-40 lg:pt-48 overflow-hidden px-4">
        <div className="absolute inset-0 bg-gradient-to-b from-[#FF3366]/20 via-[#FF3366]/10 to-transparent h-[1000px]" />

        <div className="relative z-10 w-full max-w-7xl mx-auto">
          {/* Header Section */}
          <motion.div variants={itemVariants} className="text-center mb-12">
            <motion.h1 
              className="font-unbounded text-2xl sm:text-3xl lg:text-4xl font-bold mb-8"
              variants={itemVariants}
            >
              <motion.div 
                className="relative inline-block my-2"
                variants={itemVariants}
              >
                <span className="relative z-10 text-white">Contactez-nous</span>
                <motion.div
                  className="absolute -left-1.5 sm:-left-3 -right-1.5 sm:-right-3 -top-1 -bottom-1 bg-[#FF3366] -skew-x-[20deg]"
                  initial={{ scale: 0, rotate: 3 }}
                  animate={{ scale: 1, rotate: 3 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                />
              </motion.div>
            </motion.h1>
            <motion.p 
              className="text-gray-300 max-w-2xl mx-auto mb-8 text-sm sm:text-base lg:text-lg px-4 mt-6"
              variants={itemVariants}
            >
              Nous sommes là pour transformer vos idées en réalité. Choisissez votre moyen de contact préféré.
            </motion.p>
          </motion.div>

          {/* Contact Methods Grid */}
          <div 
            ref={methodsRef}
            className="grid grid-cols-3 gap-4 md:gap-3 max-w-6xl mx-auto mb-20 px-4"
          >
            {contactMethods.map((method, index) => (
              <motion.div
                key={index}
                variants={cardVariants}
                initial={isMobile ? "visible" : "hidden"}
                animate={isMobile ? "visible" : (methodsInView ? "visible" : "hidden")}
                custom={index}
                className="flex flex-col items-center"
              >
                <motion.a
                  href={method.link}
                  target={method.title === "WhatsApp" ? "_blank" : undefined}
                  rel={method.title === "WhatsApp" ? "noopener noreferrer" : undefined}
                  variants={itemVariants}
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.95 }}
                  className={`bg-black/40 backdrop-blur-md border border-white/10 md:bg-[#0A0A0A]/80 rounded-full md:rounded-[2rem] p-4 md:p-8 shadow-lg hover:shadow-xl transition-all duration-300 flex flex-col items-center justify-between aspect-square w-[110px] h-[110px] md:w-full md:h-[260px] relative cursor-pointer md:cursor-default
                    ${method.title === "Téléphone" ? "hover:bg-blue-500/20 md:hover:bg-transparent" : 
                      method.title === "Email" ? "hover:bg-[#FF3366]/20 md:hover:bg-transparent" : "hover:bg-[#25D366]/20 md:hover:bg-transparent"}`}
                >
                  {method.title === "Email" && (
                    <div className="hidden lg:block absolute top-4 right-4 bg-yellow-600/30 backdrop-blur-md border border-yellow-500/20 text-yellow-200 text-xs font-medium px-2 py-0.5 rounded-full">
                      Pré-rédigé
                    </div>
                  )}
                  <div className="flex flex-col items-center justify-center gap-1 md:gap-4 flex-1">
                    <div className={`w-12 h-12 md:w-14 md:h-14 flex items-center justify-center mb-0.5 md:mb-2
                      ${method.title === "Téléphone" ? "text-blue-500 md:text-white/60" : 
                        method.title === "Email" ? "text-[#FF3366] md:text-white/60" : "text-[#25D366] md:text-white/60"}`}>
                      {method.icon}
                    </div>
                    <h3 className="text-[11px] md:text-xl font-medium text-white md-only:mb-6">{method.title}</h3>
                    <p className="hidden md:block md-only:hidden text-sm text-gray-300 max-w-xs text-center mb-6">{method.description}</p>
                  </div>
                  <a
                    href={method.link}
                    target={method.title === "WhatsApp" ? "_blank" : undefined}
                    rel={method.title === "WhatsApp" ? "noopener noreferrer" : undefined}
                    className={`hidden md:flex ${
                      method.title === "Téléphone"
                        ? "bg-blue-500"
                        : method.title === "Email"
                        ? "bg-[#FF3366]"
                        : "bg-[#25D366]"
                    } text-white rounded-3xl hover:opacity-90 transition-opacity px-8 py-2.5 w-[75%] text-sm items-center justify-center gap-2 mb-8`}
                  >
                    {method.action}
                    {method.actionIcon}
                  </a>
                </motion.a>
              </motion.div>
            ))}
          </div>

          {/* Social Media Section */}
          <motion.div
            variants={itemVariants}
            className="max-w-6xl mx-auto px-4 mb-20 text-center"
          >
            <h2 className="text-title-mobile sm:text-title-2 font-bold mb-8">Suivez-nous sur les réseaux</h2>
            <p className="text-description-mobile sm:text-description text-gray-300 mb-8 max-w-2xl mx-auto">
              Restez connectés avec nous ! Suivez notre actualité et nos dernières réalisations sur les réseaux sociaux.
            </p>
            <div className="flex justify-center gap-6">
              <a
                href="https://www.facebook.com/izicode"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black/40 backdrop-blur-md border border-white/10 p-4 rounded-2xl hover:scale-[1.02] transition-all duration-300 group"
              >
                <BsFacebook className="w-6 h-6 text-white group-hover:text-[#1877F2]" />
              </a>
              <a
                href="https://www.instagram.com/izicode"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black/40 backdrop-blur-md border border-white/10 p-4 rounded-2xl hover:scale-[1.02] transition-all duration-300 group"
              >
                <BsInstagram className="w-6 h-6 text-white group-hover:text-[#E4405F]" />
              </a>
              <a
                href="https://www.linkedin.com/company/izicode"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black/40 backdrop-blur-md border border-white/10 p-4 rounded-2xl hover:scale-[1.02] transition-all duration-300 group"
              >
                <BsLinkedin className="w-6 h-6 text-white group-hover:text-[#0A66C2]" />
              </a>
              <a
                href="https://twitter.com/izicode"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-black/40 backdrop-blur-md border border-white/10 p-4 rounded-2xl hover:scale-[1.02] transition-all duration-300 group"
              >
                <BsTwitterX className="w-6 h-6 text-white" />
              </a>
            </div>
          </motion.div>

          {/* Features Section */}
          <motion.div variants={itemVariants} className="mb-20">
            <div className="text-center mb-12">
              <h2 className="text-title-mobile sm:text-title-2 font-bold mb-6">Pourquoi nous contacter ?</h2>
              <p className="text-description-mobile sm:text-description text-gray-300 mb-8 max-w-2xl mx-auto">
                Découvrez les avantages de travailler avec notre équipe expérimentée.
              </p>
            </div>
            <div 
              ref={featuresRef}
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-3 md:gap-8"
            >
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  variants={cardVariants}
                  initial={isMobile ? "visible" : "hidden"}
                  animate={isMobile ? "visible" : (featuresInView ? "visible" : "hidden")}
                  custom={index}
                  className="bg-black/40 backdrop-blur-md border border-white/10 p-4 md:p-6 rounded-2xl md:rounded-[2rem] text-center shadow-lg hover:shadow-xl transition-all duration-300"
                >
                  <div className="w-10 h-10 md:w-12 md:h-12 bg-black/40 backdrop-blur-md border border-white/10 rounded-full flex items-center justify-center mx-auto mb-3 md:mb-4 shadow-xl">
                    <div className="text-[#FF3366] w-5 h-5 md:w-6 md:h-6">{feature.icon}</div>
                  </div>
                  <h3 className="text-lg md:text-xl font-bold mb-1.5 md:mb-2">{feature.title}</h3>
                  <p className="text-sm md:text-base text-gray-300">{feature.description}</p>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Location and CTA Grid */}
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-12">
            {/* Location Card */}
            <div className="lg:col-span-1">
              <motion.div variants={itemVariants} className="bg-[#0B1120]/60 backdrop-blur-md border border-white/5 rounded-2xl p-6 md:p-8 h-full flex flex-col justify-center shadow-[0_0_40px_-15px_rgba(0,0,0,0.3)] hover:shadow-[0_0_50px_-15px_rgba(0,0,0,0.4)] transition-all duration-300">
                {/* Background Decoration */}
                <div className="absolute top-0 right-0 w-32 h-32 bg-gradient-to-br from-[#FF3366]/10 to-transparent rounded-full blur-3xl -z-10" />
                <div className="absolute bottom-0 left-0 w-40 h-40 bg-gradient-to-tr from-blue-500/10 to-transparent rounded-full blur-3xl -z-10" />
                
                {/* Additional Visual Elements */}
                <div className="absolute top-4 right-4 opacity-30">
                  <div className="w-20 h-20 border border-white/10 rounded-full flex items-center justify-center">
                    <div className="w-16 h-16 border border-white/20 rounded-full flex items-center justify-center">
                      <div className="w-12 h-12 border border-white/30 rounded-full" />
                    </div>
                  </div>
                </div>
                
                <h2 className="text-title-mobile sm:text-title-2 font-bold text-center mb-10">{locationInfo.title}</h2>
                
                <div className="space-y-10">
                  <div className="flex items-start gap-6">
                    <div className="relative group">
                      <div className="absolute inset-0 bg-gradient-to-r from-[#FF3366]/20 to-[#FF3366]/0 rounded-full blur-xl transition-all duration-300 group-hover:blur-2xl" />
                      <div className="w-12 h-12 bg-black/40 backdrop-blur-md border border-white/10 rounded-full flex items-center justify-center flex-shrink-0 relative shadow-xl">
                        {locationInfo.icon}
                      </div>
                    </div>
                    <div className="space-y-2">
                      <h3 className="text-description-mobile sm:text-description font-medium text-white/90">{locationInfo.description}</h3>
                      <p className="text-sm sm:text-base text-gray-300/80 leading-relaxed">
                        {locationInfo.details}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-start gap-6">
                    <div className="relative group">
                      <div className="absolute inset-0 bg-gradient-to-r from-[#FF3366]/20 to-[#FF3366]/0 rounded-full blur-xl transition-all duration-300 group-hover:blur-2xl" />
                      <div className="w-12 h-12 bg-black/40 backdrop-blur-md border border-white/10 rounded-full flex items-center justify-center flex-shrink-0 relative shadow-xl">
                        {scheduleInfo.icon}
                      </div>
                    </div>
                    <div className="space-y-2">
                      <h3 className="text-description-mobile sm:text-description font-medium text-white/90">{scheduleInfo.title}</h3>
                      <div className="space-y-1.5">
                        {scheduleInfo.details.map((detail, index) => (
                          <p key={index} className="text-sm sm:text-base text-gray-300/80 flex items-center gap-2">
                            <span className={`w-2 h-2 rounded-full ${detail.status === "open" ? "bg-green-400" : detail.status === "limited" ? "bg-yellow-400" : "bg-red-400"}`}></span>
                            {detail.day} : {detail.hours}
                          </p>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>

            {/* Ready to Start Card */}
            <div className="lg:col-span-2 lg:pl-4">
              <motion.div variants={itemVariants} className="bg-[#0B1120]/60 backdrop-blur-md border border-white/5 rounded-2xl p-6 md:p-8 h-full shadow-[0_0_40px_-15px_rgba(0,0,0,0.3)] hover:shadow-[0_0_50px_-15px_rgba(0,0,0,0.4)] transition-all duration-300">
                <div className="flex flex-col h-full items-center text-center">
                  <div className="flex-1">
                    <div className="flex flex-col items-center gap-4 mb-6">
                      <div className="w-12 h-12 bg-black/40 backdrop-blur-md border border-white/10 rounded-full flex items-center justify-center relative shadow-xl">
                        <Rocket className="w-6 h-6 text-[#FF3366]" />
                      </div>
                      <h2 className="text-title-mobile sm:text-title-2 font-bold">Prêt à démarrer ?</h2>
                    </div>
                    <p className="text-description-mobile sm:text-description text-gray-300 mb-8 max-w-lg mx-auto">
                      Contactez-nous dès maintenant pour discuter de votre projet et obtenir un devis personnalisé.
                    </p>
                  </div>
                  
                  <div className="space-y-6 w-full">
                    <h3 className="text-description-mobile sm:text-description font-semibold">Demander un devis ?</h3>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                      <Link
                        to="/devis"
                        className="inline-flex items-center justify-center gap-2 bg-black/40 backdrop-blur-md border border-white/10 text-white rounded-3xl px-8 py-3.5 hover:bg-[#FF3366]/10 hover:border-[#FF3366]/50 hover:scale-[1.02] transition-all duration-300 text-center w-full font-medium relative group"
                      >
                        <FormInput className="w-5 h-5 group-hover:text-[#FF3366] transition-colors duration-300" />
                        <span>Utiliser le formulaire</span>
                      </Link>
                      <Link
                        to="/chat"
                        className="relative inline-flex items-center justify-center gap-2 bg-black/40 backdrop-blur-md border border-white/10 text-white rounded-3xl px-8 py-3.5 hover:bg-[#FF3366]/20 hover:border-[#FF3366]/50 hover:scale-[1.02] transition-all duration-300 text-center w-full font-medium"
                      >
                        <Bot className="w-5 h-5 group-hover:text-[#FF3366] transition-colors duration-300" />
                        Notre chat IA
                        <div className="flex items-center gap-2">
                          <span className="bg-emerald-500/5 backdrop-blur-sm text-emerald-500 text-xs px-2.5 py-0.5 rounded-full border border-emerald-500/20">
                            Nouveau
                          </span>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>

          {/* Book Appointment Card */}
          <motion.div variants={itemVariants} className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-12">
            <div className="lg:col-span-3">
              <div className="bg-[#0B1120]/60 backdrop-blur-md border border-white/5 rounded-2xl p-6 md:p-8 h-full flex flex-col items-center justify-center text-center shadow-[0_0_40px_-15px_rgba(0,0,0,0.3)] hover:shadow-[0_0_50px_-15px_rgba(0,0,0,0.4)] transition-all duration-300">
                <h2 className="text-title-mobile sm:text-title-2 font-bold mb-8">Planifions votre projet</h2>
                <p className="text-description-mobile sm:text-description text-gray-300 mb-8 max-w-2xl mx-auto">
                  Réservez un créneau qui vous convient pour discuter de votre projet en détail avec notre équipe.
                </p>
                <Link
                  to="/rendez-vous"
                  className="bg-black/40 backdrop-blur-md border border-white/10 hover:bg-black/40 text-white px-8 py-4 rounded-3xl inline-flex items-center gap-3 group transition-all duration-300"
                >
                  <Calendar className="w-5 h-5 text-[#FF3366]" />
                  <span>Prendre rendez-vous</span>
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </Link>
              </div>
            </div>
          </motion.div>

          {/* FAQ Section */}
          <motion.div variants={itemVariants} className="p-6 md:p-8 mb-20 min-h-[300px] flex flex-col items-center justify-center text-center">
            <h2 className="text-title-mobile sm:text-title-2 font-bold mb-6">Des questions ?</h2>
            <p className="text-description-mobile sm:text-description text-gray-300/90 mb-8 max-w-2xl">
              Consultez notre FAQ ou contactez-nous directement pour plus d'informations.
            </p>
            <Link
              to="/faq"
              className="bg-[#FF3366] hover:bg-[#FF3366]/90 text-white px-8 py-3 rounded-3xl inline-flex items-center gap-2 group transition-all duration-300"
            >
              Voir la FAQ
              <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </Link>
          </motion.div>
        </div>
      </div>
    </>
  );
};

export default Contact;

import React from 'react';
import { FormData } from '../types/form';
import { Pencil } from 'lucide-react';

type Props = {
  data: FormData;
  onEditStep: (step: number) => void;
};

export const FormSummary: React.FC<Props> = ({ data, onEditStep }) => {
  return (
    <div className="space-y-8">
      <h2 className="text-2xl font-semibold mb-6">Récapitulatif de votre demande</h2>

      <div className="space-y-6">
        <div>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium">Informations générales</h3>
            <button
              onClick={() => onEditStep(1)}
              className="flex items-center text-pink-500 hover:text-pink-600"
            >
              <Pencil className="w-4 h-4 mr-1" />
              Modifier
            </button>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p><strong>Type de projet :</strong> {data.projectType.join(', ')}</p>
            <p><strong>Description :</strong> {data.projectDescription}</p>
            <p><strong>Délai souhaité :</strong> {data.deadline}</p>
            <p><strong>Cahier des charges :</strong> {data.hasSpecifications ? 'Oui' : 'Non'}</p>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium">Spécifications techniques</h3>
            <button
              onClick={() => onEditStep(2)}
              className="flex items-center text-pink-500 hover:text-pink-600"
            >
              <Pencil className="w-4 h-4 mr-1" />
              Modifier
            </button>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p><strong>Fonctionnalités :</strong> {data.features.join(', ')}</p>
            <p><strong>Technologies :</strong> {data.technologies.join(', ')}</p>
            <p><strong>Compatibilité :</strong> {data.compatibility.join(', ')}</p>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium">Design</h3>
            <button
              onClick={() => onEditStep(3)}
              className="flex items-center text-pink-500 hover:text-pink-600"
            >
              <Pencil className="w-4 h-4 mr-1" />
              Modifier
            </button>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p><strong>Style visuel :</strong> {data.visualStyle}</p>
            <p><strong>Palette de couleurs :</strong> {data.colorPalette}</p>
            <p><strong>Charte graphique :</strong> {data.hasGraphicCharter ? 'Oui' : 'Non'}</p>
            {data.designNotes && <p><strong>Notes :</strong> {data.designNotes}</p>}
          </div>
        </div>

        {data.needsSeo && (
          <div>
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-lg font-medium">SEO & Visibilité</h3>
              <button
                onClick={() => onEditStep(4)}
                className="flex items-center text-pink-500 hover:text-pink-600"
              >
                <Pencil className="w-4 h-4 mr-1" />
                Modifier
              </button>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <p><strong>Services SEO :</strong> {data.seoServices?.join(', ')}</p>
              {data.targetAudience && <p><strong>Public cible :</strong> {data.targetAudience}</p>}
              {data.socialNetworks && <p><strong>Réseaux sociaux :</strong> {data.socialNetworks.join(', ')}</p>}
            </div>
          </div>
        )}

        <div>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium">Budget</h3>
            <button
              onClick={() => onEditStep(5)}
              className="flex items-center text-pink-500 hover:text-pink-600"
            >
              <Pencil className="w-4 h-4 mr-1" />
              Modifier
            </button>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p><strong>Budget estimé :</strong> {data.budget}</p>
          </div>
        </div>

        <div>
          <div className="flex items-center justify-between mb-2">
            <h3 className="text-lg font-medium">Contact</h3>
            <button
              onClick={() => onEditStep(6)}
              className="flex items-center text-pink-500 hover:text-pink-600"
            >
              <Pencil className="w-4 h-4 mr-1" />
              Modifier
            </button>
          </div>
          <div className="bg-gray-50 p-4 rounded-lg">
            <p><strong>Nom complet :</strong> {data.firstName} {data.lastName}</p>
            <p><strong>Email :</strong> {data.email}</p>
            <p><strong>Téléphone :</strong> {data.phone}</p>
            {data.company && <p><strong>Entreprise :</strong> {data.company}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Mail, Phone, MapPin, Clock } from 'lucide-react';
import { sendContactFormEmail } from '../services/brevo';
import { toast } from 'react-hot-toast';

const Contact = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [errors, setErrors] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState<'success' | 'error' | null>(null);

  const validateName = (name: string) => {
    if (!name.trim()) return 'Le nom est requis';
    if (name.trim().length < 2) return 'Le nom doit contenir au moins 2 caractères';
    if (!/^[a-zA-ZÀ-ÿ\s'-]+$/.test(name)) return 'Le nom contient des caractères invalides';
    return '';
  };

  const validateEmail = (email: string) => {
    if (!email.trim()) return 'L\'email est requis';
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) return 'L\'email est invalide';
    return '';
  };

  const validateMessage = (message: string) => {
    if (!message.trim()) return 'Le message est requis';
    if (message.trim().length < 10) return 'Le message doit contenir au moins 10 caractères';
    return '';
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
    
    // Validation en temps réel
    let error = '';
    switch (name) {
      case 'name':
        error = validateName(value);
        break;
      case 'email':
        error = validateEmail(value);
        break;
      case 'message':
        error = validateMessage(value);
        break;
    }
    setErrors(prev => ({ ...prev, [name]: error }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);
    setSubmitStatus(null);

    // Validation finale
    const nameError = validateName(formData.name);
    const emailError = validateEmail(formData.email);
    const messageError = validateMessage(formData.message);

    setErrors({
      name: nameError,
      email: emailError,
      message: messageError
    });

    if (nameError || emailError || messageError) {
      setIsSubmitting(false);
      return;
    }

    try {
      await sendContactFormEmail(formData);
      setSubmitStatus('success');
      setFormData({ name: '', email: '', message: '' });
      toast.success('Message envoyé avec succès !');
    } catch (error) {
      console.error('Error sending contact form:', error);
      setSubmitStatus('error');
      toast.error('Erreur lors de l\'envoi du message. Veuillez réessayer.');
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <section className="py-20 px-4 bg-black/30" id="contact">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : {}}
          transition={{ duration: 0.6 }}
          ref={ref}
          className="text-center mb-16"
        >
          <h2 className="text-2xl sm:text-4xl font-bold mb-4 font-unbounded">Contactez-nous</h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-sm sm:text-base mb-8">
            Prêt à démarrer votre projet ? Contactez-nous dès aujourd'hui !
          </p>
        </motion.div>

        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="space-y-8"
          >
            <div className="flex items-start space-x-4">
              <div className="w-12 h-12 bg-[#FF3366]/10 rounded-xl flex items-center justify-center flex-shrink-0">
                <Mail className="w-6 h-6 text-[#FF3366]" />
              </div>
              <div>
                <h3 className="font-bold mb-1">Email</h3>
                <a href="mailto:contact@izicode.fr" className="text-gray-400 hover:text-[#FF3366] transition-colors">
                  contact@izicode.fr
                </a>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="w-12 h-12 bg-[#FF3366]/10 rounded-xl flex items-center justify-center flex-shrink-0">
                <Phone className="w-6 h-6 text-[#FF3366]" />
              </div>
              <div>
                <h3 className="font-bold mb-1">Téléphone</h3>
                <a href="tel:+33683919738" className="text-gray-400 hover:text-[#FF3366] transition-colors">
                  +33 6 83 91 97 38
                </a>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="w-12 h-12 bg-[#FF3366]/10 rounded-xl flex items-center justify-center flex-shrink-0">
                <MapPin className="w-6 h-6 text-[#FF3366]" />
              </div>
              <div>
                <h3 className="font-bold mb-1">Adresse</h3>
                <p className="text-gray-400">Amiens, Somme<br/>Hauts-de-France</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <div className="w-12 h-12 bg-[#FF3366]/10 rounded-xl flex items-center justify-center flex-shrink-0">
                <Clock className="w-6 h-6 text-[#FF3366]" />
              </div>
              <div>
                <h3 className="font-bold mb-1">Horaires</h3>
                <p className="text-gray-400">Lundi - Vendredi : 9h à 17h<br/>Samedi : 9h à 12h sur rendez-vous</p>
              </div>
            </div>
          </motion.div>

          <motion.form
            onSubmit={handleSubmit}
            initial={{ opacity: 0, x: 20 }}
            animate={inView ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 0.6, delay: 0.4 }}
            className="space-y-6"
          >
            <div>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Votre nom"
                className={`w-full px-4 py-3 bg-white/5 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#FF3366] ${
                  errors.name ? 'ring-2 ring-red-500' : ''
                }`}
              />
              {errors.name && (
                <p className="text-red-500 text-sm mt-1">{errors.name}</p>
              )}
            </div>
            <div>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Votre email"
                className={`w-full px-4 py-3 bg-white/5 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#FF3366] ${
                  errors.email ? 'ring-2 ring-red-500' : ''
                }`}
              />
              {errors.email && (
                <p className="text-red-500 text-sm mt-1">{errors.email}</p>
              )}
            </div>
            <div>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Votre message"
                rows={4}
                className={`w-full px-4 py-3 bg-white/5 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#FF3366] ${
                  errors.message ? 'ring-2 ring-red-500' : ''
                }`}
              ></textarea>
              {errors.message && (
                <p className="text-red-500 text-sm mt-1">{errors.message}</p>
              )}
            </div>
            <button
              type="submit"
              disabled={isSubmitting}
              className="w-full bg-[#FF3366] text-white px-6 py-3 rounded-lg hover:bg-[#FF3366]/90 transition-colors disabled:opacity-50"
            >
              {isSubmitting ? 'Envoi en cours...' : 'Envoyer'}
            </button>
            {submitStatus === 'success' && (
              <p className="text-green-500 text-sm text-center">Message envoyé avec succès !</p>
            )}
            {submitStatus === 'error' && (
              <p className="text-red-500 text-sm text-center">Une erreur est survenue. Veuillez réessayer.</p>
            )}
          </motion.form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';
import { ArrowRight, ChevronLeft, ChevronRight, Clock, User } from 'lucide-react';
import { getLatestBlogPosts } from '../lib/firebase';
import type { BlogPost } from '../lib/firebase';

const LatestPosts = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        const fetchedPosts = await getLatestBlogPosts(3);
        setPosts(fetchedPosts);
        setError(null);
      } catch (err) {
        console.error('Error fetching latest posts:', err);
        setError('Impossible de charger les derniers articles');
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();
  }, []);

  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAutoPlaying, setIsAutoPlaying] = useState(true);
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  useEffect(() => {
    if (!isAutoPlaying) return;

    const timer = setInterval(() => {
      setCurrentSlide((prev) => (prev + 1) % posts.length);
    }, 7000);

    return () => clearInterval(timer);
  }, [isAutoPlaying, posts.length]);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % posts.length);
    setIsAutoPlaying(false);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + posts.length) % posts.length);
    setIsAutoPlaying(false);
  };

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <section className="py-16 px-4" id="latest-posts">
      <div className="max-w-5xl mx-auto">
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
          className="text-center mb-16"
        >
          <h2 className="text-2xl sm:text-4xl font-unbounded font-bold mb-4">Derniers Articles</h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-sm sm:text-base">
            Découvrez nos derniers articles et restez à jour avec les dernières tendances
          </p>
        </motion.div>

        <div className="relative">
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 -translate-y-1/2 z-10 bg-black/50 hover:bg-black/70 text-white p-2 rounded-full backdrop-blur-sm transition-all"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 z-10 bg-black/50 hover:bg-black/70 text-white p-2 rounded-full backdrop-blur-sm transition-all"
          >
            <ChevronRight className="w-6 h-6" />
          </button>

          <motion.div
            className="overflow-hidden rounded-3xl"
            variants={containerVariants}
          >
            <motion.div
              className="flex"
              animate={{ x: `-${currentSlide * 100}%` }}
              transition={{ 
                type: "spring",
                stiffness: 100,
                damping: 20,
                mass: 1
              }}
            >
              {posts.map((post, index) => (
                <Link
                  key={index}
                  to={`/blog/${post.title.toLowerCase().replace(/\s+/g, '-')}`}
                  className="w-full flex-shrink-0 px-2"
                >
                  <div className="bg-black/40 backdrop-blur-xl rounded-3xl overflow-hidden border border-white/5">
                    <div className="aspect-[21/9] relative overflow-hidden">
                      <img
                        src={post.image}
                        alt={post.title}
                        className="w-full h-full object-cover"
                      />
                      <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                      <span className="absolute top-4 left-4 bg-[#FF3366]/90 text-white text-sm px-3 py-1 rounded-full">
                        {post.category}
                      </span>
                    </div>
                    <div className="p-4">
                      <h3 className="text-lg font-semibold mb-2">{post.title}</h3>
                      <p className="text-gray-400 mb-3 text-sm line-clamp-2">{post.excerpt}</p>
                      <div className="flex justify-between items-center">
                        <div className="flex items-center gap-4 text-sm text-gray-400">
                          <span className="flex items-center gap-1">
                            <User className="w-3 h-3" />
                            {post.author}
                          </span>
                          <span className="flex items-center gap-1">
                            <Clock className="w-3 h-3" />
                            {post.readTime}
                          </span>
                          <span>
                            {new Date(post.date).toLocaleDateString('fr-FR', {
                              year: 'numeric',
                              month: 'long',
                              day: 'numeric'
                            })}
                          </span>
                        </div>
                        <div className="text-[#FF3366] hover:text-[#FF3366]/80 transition-colors flex items-center gap-1 text-sm">
                          Lire plus
                          <ArrowRight className="w-4 h-4" />
                        </div>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </motion.div>
          </motion.div>

          <div className="flex justify-center gap-2 mt-6">
            {posts.map((_, index) => (
              <button
                key={index}
                onClick={() => {
                  setCurrentSlide(index);
                  setIsAutoPlaying(false);
                }}
                className={`w-2 h-2 rounded-full transition-all ${
                  currentSlide === index
                    ? 'bg-[#FF3366] w-6'
                    : 'bg-white/20 hover:bg-white/40'
                }`}
              />
            ))}
          </div>
        </div>

        <motion.div
          className="text-center mt-12"
          variants={itemVariants}
        >
          <Link
            to="/blog"
            className="inline-flex items-center gap-2 bg-[#FF3366] hover:bg-[#FF3366]/90 text-white px-6 py-3 rounded-full font-medium transition-colors"
          >
            Voir tous les articles
            <ArrowRight className="w-4 h-4" />
          </Link>
        </motion.div>
      </div>
    </section>
  );
};

export default LatestPosts;

import React from 'react';

const MentionsLegales = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <h1 className="text-4xl font-bold mb-8 text-[#FF3366]">Mentions légales</h1>
        <p className="text-gray-400 mb-8">Dernière mise à jour : 1 décembre 2024</p>

        <div className="space-y-12">
          {/* Section 1 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">1. Identification de l'éditeur du site</h2>
            <p className="text-gray-300 mb-4">
              Le présent site, accessible à l'adresse www.izicode.com, est édité par :
            </p>
            <ul className="list-none space-y-2 text-gray-300">
              <li>• Nom commercial : Izicode</li>
              <li>• Statut juridique : Auto-entrepreneur</li>
              <li>• Nom et prénom : Nadir Ben Salah</li>
              <li>• Adresse du siège social : 76 rue du Professeur Christian Cabrol, 80000 Amiens, France</li>
              <li>• Numéro SIRET : 814 051 769 00036</li>
              <li>• Téléphone : 06 10 35 42 59</li>
              <li>• Email : contact@izicode.com</li>
            </ul>
          </section>

          {/* Section 2 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">2. Directeur de la publication</h2>
            <p className="text-gray-300">
              Le directeur de la publication est Nadir Ben Salah, en sa qualité de représentant légal de l'entreprise Izicode.
            </p>
          </section>

          {/* Section 3 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">3. Hébergement du site</h2>
            <p className="text-gray-300 mb-4">Le site est hébergé par :</p>
            <ul className="list-none space-y-2 text-gray-300">
              <li>• Nom de l'hébergeur : Hostinger</li>
              <li>• Adresse : 61 Rue Lordou Vironos, 6023 Larnaca, Chypre</li>
              <li>• Site internet : www.hostinger.fr</li>
            </ul>
          </section>

          {/* Section 4 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">4. Propriété intellectuelle</h2>
            <div className="space-y-4 text-gray-300">
              <p>
                L'intégralité du contenu présent sur ce site, y compris, mais sans s'y limiter, les textes, images, graphismes, logos, vidéos, sons, et logiciels, est la propriété exclusive de Izicode ou de ses partenaires.
              </p>
              <p>
                Ces éléments sont protégés par les lois françaises et internationales relatives à la propriété intellectuelle.
              </p>
              <p>
                Toute reproduction, distribution, modification, adaptation ou publication, totale ou partielle, de ces contenus, sans l'autorisation préalable écrite d'Izicode, est strictement interdite.
              </p>
            </div>
          </section>

          {/* Section 5 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">5. Protection des données personnelles</h2>
            <div className="space-y-4 text-gray-300">
              <p>
                Le traitement des données personnelles des utilisateurs est conforme au Règlement Général sur la Protection des Données (RGPD).
              </p>
              <p>
                Pour plus d'informations sur la collecte et l'utilisation des données personnelles, veuillez consulter notre Politique de confidentialité.
              </p>
            </div>
          </section>

          {/* Section 6 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">6. Cookies</h2>
            <div className="space-y-4 text-gray-300">
              <p>
                Le site utilise des cookies pour améliorer l'expérience utilisateur. L'utilisateur est informé dès son arrivée sur le site via une bannière de consentement.
              </p>
              <p>
                Pour plus de détails, veuillez consulter notre Politique de cookies.
              </p>
            </div>
          </section>

          {/* Section 7 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">7. Responsabilité</h2>
            <div className="space-y-4 text-gray-300">
              <p>
                Izicode s'efforce d'assurer l'exactitude et la mise à jour des informations diffusées sur le site. Toutefois, Izicode ne garantit pas l'exactitude, la précision ou l'exhaustivité des informations présentes sur le site.
              </p>
              <p className="font-semibold">Limitations de responsabilité :</p>
              <ul className="list-none space-y-2">
                <li>• Izicode ne pourra être tenu responsable des dommages directs ou indirects résultant de l'accès ou de l'utilisation du site, y compris, mais sans s'y limiter, des virus pouvant infecter votre équipement informatique.</li>
                <li>• Les liens hypertextes présents sur le site peuvent rediriger vers d'autres sites. Izicode décline toute responsabilité quant au contenu de ces sites tiers.</li>
              </ul>
            </div>
          </section>

          {/* Section 8 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">8. Accessibilité</h2>
            <p className="text-gray-300">
              Izicode met tout en œuvre pour rendre le site accessible 24h/24 et 7j/7. Cependant, Izicode se réserve le droit d'interrompre temporairement l'accès pour des raisons techniques ou de maintenance.
            </p>
          </section>

          {/* Section 9 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">9. Droit applicable et juridiction compétente</h2>
            <p className="text-gray-300">
              Les présentes mentions légales sont régies par le droit français. En cas de litige, et à défaut de résolution amiable, le tribunal compétent sera celui du ressort d'Amiens.
            </p>
          </section>

          {/* Section 10 */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">10. Contact</h2>
            <p className="text-gray-300 mb-4">Pour toute question ou réclamation, vous pouvez nous contacter à :</p>
            <ul className="list-none space-y-2 text-gray-300">
              <li>• Email : contact@izicode.com</li>
              <li>• Téléphone : 06 10 35 42 59</li>
              <li>• Adresse postale : 76 rue du Professeur Christian Cabrol, 80000 Amiens, France</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default MentionsLegales;

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Search, ArrowRight, ChevronDown, X } from 'lucide-react';
import { Link } from 'react-router-dom';
import SEO from '../components/SEO/SEO';
import { pageSEOConfigs } from '../config/seo.config';

const FAQ = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [openItem, setOpenItem] = useState<number | null>(null);

  const toggleItem = (index: number) => {
    setOpenItem(openItem === index ? null : index);
  };

  const faqItems = [
    {
      question: "Quels services proposez-vous ?",
      answer: "Nous proposons des services de développement web et mobile, incluant la création de sites web, d'applications mobiles, de solutions e-commerce, et d'applications métier sur mesure."
    },
    {
      question: "Combien coûte un projet de développement ?",
      answer: "Le coût varie en fonction de la complexité du projet, des fonctionnalités requises et du temps de développement nécessaire. Nous établissons un devis personnalisé après analyse détaillée de vos besoins."
    },
    {
      question: "Quelle est la durée moyenne d'un projet ?",
      answer: "La durée dépend de l'envergure du projet. Un site vitrine simple peut prendre 2-4 semaines, tandis qu'une application complexe peut nécessiter 3-6 mois ou plus."
    },
    {
      question: "Quelles technologies utilisez-vous ?",
      answer: "Nous utilisons les technologies les plus modernes et adaptées à chaque projet, incluant React, Node.js, Python, Flutter, et bien d'autres. Le choix est fait en fonction des besoins spécifiques de votre projet."
    },
    {
      question: "Proposez-vous un support après la livraison ?",
      answer: "Oui, nous offrons un support technique continu et des services de maintenance pour assurer le bon fonctionnement de votre solution après le déploiement."
    },
    {
      question: "Comment se déroule la collaboration ?",
      answer: "Nous suivons une méthodologie agile avec des points réguliers, une communication transparente et des livrables intermédiaires pour assurer que le projet correspond à vos attentes."
    },
    {
      question: "Faites-vous du responsive design ?",
      answer: "Oui, tous nos sites et applications sont développés en responsive design pour une expérience optimale sur tous les appareils (ordinateurs, tablettes, smartphones)."
    },
    {
      question: "Proposez-vous des services d'hébergement ?",
      answer: "Oui, nous pouvons vous conseiller et mettre en place des solutions d'hébergement adaptées à vos besoins, avec une attention particulière à la performance et à la sécurité."
    },
    {
      question: "Comment assurez-vous la sécurité des applications ?",
      answer: "Nous implémentons les meilleures pratiques de sécurité, incluant le chiffrement des données, la protection contre les attaques courantes, et des audits de sécurité réguliers."
    },
    {
      question: "Puis-je modifier mon projet après le lancement ?",
      answer: "Oui, nous concevons nos solutions de manière modulaire pour faciliter les évolutions futures. Nous pouvons vous accompagner dans les modifications et améliorations post-lancement."
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1 // Délai entre chaque enfant
      }
    }
  };

  const itemVariants = {
    hidden: {
      opacity: 0,
      y: 50,
      scale: 0.8
    },
    visible: {
      opacity: 1,
      y: 0,
      scale: 1,
      transition: {
        type: 'spring',
        stiffness: 150,
        damping: 15,
        mass: 0.4
      }
    }
  };

  const filteredFAQ = faqItems.filter(item =>
    item.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.answer.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <SEO {...pageSEOConfigs.faq} />
      <div className="relative min-h-screen flex flex-col items-start pt-36 sm:pt-40 lg:pt-48 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-[#FF3366]/20 to-transparent" />

        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="relative z-10 w-full"
        >
          {/* Header Section */}
          <motion.div variants={itemVariants} className="text-center">
            <h1 className="font-unbounded text-2xl sm:text-3xl lg:text-4xl font-bold mb-8">
              <div className="relative inline-block my-2">
                <span className="relative z-10 text-white">FAQ</span>
                <motion.div
                  className="absolute -left-1.5 sm:-left-3 -right-1.5 sm:-right-3 -top-1 -bottom-1 bg-[#FF3366] -skew-x-[20deg]"
                  initial={{ scale: 0, rotate: 3 }}
                  animate={{ scale: 1, rotate: 3 }}
                  transition={{ duration: 0.6, delay: 0.3 }}
                />
              </div>
            </h1>
            <p className="text-gray-300 max-w-2xl mx-auto mb-8 text-sm sm:text-base lg:text-lg px-4 mt-6">
              Retrouvez les réponses aux questions les plus fréquentes sur nos services
            </p>
          </motion.div>

          {/* Search Bar */}
          <div className="w-full max-w-2xl mx-auto mb-12 px-4">
            <div className="relative">
              <input
                type="text"
                placeholder="Rechercher une question..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-full px-12 py-4 bg-black/40 backdrop-blur-md text-white placeholder-gray-400 rounded-2xl focus:outline-none focus:ring-1 focus:ring-[#FF3366] transition-all duration-200"
              />
              <Search className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
              {searchQuery && (
                <button
                  onClick={() => setSearchQuery('')}
                  className="absolute right-4 top-1/2 -translate-y-1/2 p-1 hover:bg-white/10 rounded-full transition-colors"
                >
                  <X className="w-4 h-4 text-gray-400" />
                </button>
              )}
            </div>
          </div>

          {/* FAQ Items */}
          <div className="w-full max-w-3xl mx-auto px-4 space-y-4 mb-24">
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="space-y-4"
            >
              {filteredFAQ.map((item, index) => (
                <motion.div
                  key={index}
                  variants={itemVariants}
                  className="bg-white/5 backdrop-blur-md border border-white/10 rounded-2xl overflow-hidden hover:bg-white/10 transition-all duration-300"
                >
                  <button
                    onClick={() => toggleItem(index)}
                    className="w-full px-6 py-4 flex items-center justify-between text-left"
                  >
                    <span className="text-white font-medium pr-6">{item.question}</span>
                    <ChevronDown
                      className={`w-5 h-5 text-[#FF3366] transform transition-transform duration-200 ${
                        openItem === index ? 'rotate-180' : ''
                      }`}
                    />
                  </button>
                  <AnimatePresence>
                    {openItem === index && (
                      <motion.div
                        initial={{ height: 0, opacity: 0 }}
                        animate={{ height: 'auto', opacity: 1 }}
                        exit={{ height: 0, opacity: 0 }}
                        transition={{ duration: 0.2 }}
                        className="border-t border-white/10"
                      >
                        <div className="px-6 py-4 text-gray-300">
                          {item.answer}
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </motion.div>
              ))}
            </motion.div>
          </div>

          {/* Contact Section */}
          <motion.div 
            variants={itemVariants}
            className="w-full text-center mt-16 mb-32"
          >
            <h3 className="text-xl md:text-2xl font-semibold mb-4">Vous n'avez pas trouvé votre réponse ?</h3>
            <p className="text-gray-400 mb-8">N'hésitez pas à nous contacter directement pour plus d'informations.</p>
            <Link
              to="/contact"
              className="inline-flex items-center gap-2 px-8 py-4 bg-[#FF3366] text-white rounded-xl hover:bg-[#FF3366]/90 transition-all"
            >
              Nous contacter
              <ArrowRight className="w-5 h-5" />
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </>
  );
};

export default FAQ;

import React from 'react';
import { motion } from 'framer-motion';
import { 
  Code, 
  MonitorSmartphone, 
  ShoppingCart, 
  Mail, 
  Users, 
  HelpCircle, 
  Layers, 
  Smartphone, 
  Tablet, 
  Monitor, 
  Repeat2 
} from 'lucide-react';
import { FormData } from '../types/form';

type Props = {
  data: FormData;
  onChange: (field: keyof FormData, value: any) => void;
};

export const FormStep2: React.FC<Props> = ({ data, onChange }) => {
  const features = [
    { 
      icon: Mail, 
      label: 'Page de contact', 
      value: 'contact',
      description: 'Formulaire de contact personnalisé'
    },
    { 
      icon: Users, 
      label: 'Espace membre', 
      value: 'member',
      description: 'Connexion et gestion de compte'
    },
    { 
      icon: Code, 
      label: 'Optimisation SEO', 
      value: 'seo',
      description: 'Référencement naturel avancé'
    },
    { 
      icon: ShoppingCart, 
      label: 'E-commerce', 
      value: 'ecommerce',
      description: 'Fonctionnalités de vente en ligne'
    },
    { 
      icon: MonitorSmartphone, 
      label: 'Blog', 
      value: 'blog',
      description: 'Publication et gestion d\'articles'
    },
    { 
      icon: HelpCircle, 
      label: 'Je ne sais pas', 
      value: 'unknown',
      description: 'Besoin de conseil'
    },
  ];

  const technologies = [
    { 
      icon: Layers, 
      label: 'React', 
      value: 'react',
      description: 'Framework JavaScript moderne'
    },
    { 
      icon: Layers, 
      label: 'Vue.js', 
      value: 'vue',
      description: 'Framework progressif'
    },
    { 
      icon: Layers, 
      label: 'Angular', 
      value: 'angular',
      description: 'Framework complet de Google'
    },
    { 
      icon: Layers, 
      label: 'WordPress', 
      value: 'wordpress',
      description: 'CMS le plus populaire'
    },
    { 
      icon: Layers, 
      label: 'Shopify', 
      value: 'shopify',
      description: 'Plateforme e-commerce clé en main'
    },
    { 
      icon: HelpCircle, 
      label: 'Je ne sais pas', 
      value: 'unknown',
      description: 'Besoin de recommandations'
    },
  ];

  const compatibility = [
    { 
      icon: Smartphone, 
      label: 'Mobile', 
      value: 'mobile',
      description: 'Optimisation smartphone'
    },
    { 
      icon: Tablet, 
      label: 'Tablette', 
      value: 'tablet',
      description: 'Adaptation tablette'
    },
    { 
      icon: Monitor, 
      label: 'Desktop', 
      value: 'desktop',
      description: 'Expérience desktop complète'
    },
    { 
      icon: Repeat2, 
      label: 'Adapté à tous les périphériques', 
      value: 'responsive',
      description: 'Design responsive total'
    },
  ];

  const toggleArrayField = (field: 'features' | 'technologies', value: string) => {
    const currentValues = data[field] as string[];
    
    // Si "Je ne sais pas" est sélectionné, réinitialiser toutes les autres sélections
    if (value === 'unknown') {
      onChange(field, ['unknown']);
      return;
    }

    // Si "Je ne sais pas" était déjà sélectionné, remplacer par la nouvelle valeur
    if (currentValues.includes('unknown')) {
      onChange(field, [value]);
      return;
    }

    // Logique de sélection/désélection normale
    const newValues = currentValues.includes(value)
      ? currentValues.filter((v) => v !== value)
      : [...currentValues, value];
    
    onChange(field, newValues);
  };

  const toggleSingleField = (field: 'compatibility', value: string) => {
    // Pour la compatibilité, on ne peut sélectionner qu'une seule option
    onChange(field, [value]);
  };

  const renderSection = (
    title: string, 
    items: any[], 
    field: 'features' | 'technologies' | 'compatibility', 
    currentValues: string[],
    toggleFunction?: (field: any, value: string) => void
  ) => (
    <div>
      <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
        {title}
      </h2>
      <div className={`
        grid gap-3 
        ${field === 'compatibility' ? 'grid-cols-4' : 'grid-cols-2 sm:grid-cols-3'}
      `}>
        {items.map((item) => (
          <motion.div
            key={item.value}
            onClick={() => 
              field === 'compatibility' 
                ? toggleSingleField(field, item.value) 
                : toggleArrayField(field, item.value)
            }
            whileTap={{ scale: 0.95 }}
            className={`
              cursor-pointer 
              rounded-xl 
              p-3 
              transition-all 
              duration-300 
              flex 
              flex-col 
              items-start 
              space-y-2
              ${currentValues.includes(item.value) 
                ? 'bg-[#370C15] border-2 border-[#FF3367]' 
                : 'bg-black/40 backdrop-blur-sm hover:bg-black/50'}
            `}
          >
            <div className={`
              p-2 rounded-lg
              ${currentValues.includes(item.value) 
                ? 'bg-[#FF3367]/20' 
                : 'bg-white/10'}
            `}>
              <item.icon 
                className={`
                  w-5 h-5
                  ${currentValues.includes(item.value) 
                    ? 'text-[#FF3367]' 
                    : 'text-white/60'}
                `} 
              />
            </div>
            <div>
              <h3 className={`
                font-semibold text-sm
                ${currentValues.includes(item.value) 
                  ? 'text-white' 
                  : 'text-white/70'}
              `}>
                {item.label}
              </h3>
              <p className={`
                text-xs mt-1
                ${currentValues.includes(item.value) 
                  ? 'text-white/70' 
                  : 'text-white/50'}
              `}>
                {item.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-6"
    >
      {renderSection(
        'Fonctionnalités souhaitées', 
        features, 
        'features', 
        data.features
      )}
      {renderSection(
        'Technologies préférées', 
        technologies, 
        'technologies', 
        data.technologies
      )}
      {renderSection(
        'Compatibilité', 
        compatibility, 
        'compatibility', 
        data.compatibility
      )}
    </motion.div>
  );
};

import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import QuoteModal from './QuoteModal';

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};

const Hero = () => {
  const [currentText, setCurrentText] = useState(0);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getFontSize = () => {
    if (width >= 2000) return '3.25rem';   // 4xl - 52px (inchangé)
    if (width >= 1800) return '2.625rem';  // 3xl - 42px (inchangé)
    if (width >= 1536) return '2.5rem';    // 2xl - 40px (inchangé)
    if (width >= 1280) return '2.375rem';  // xl  - 38px (inchangé)
    if (width >= 1024) return '2.25rem';   // lg  - 36px (inchangé)
    if (width >= 768) return '2.125rem';   // md  - 34px (inchangé)
    if (width >= 640) return '2rem';       // sm  - 32px (inchangé)
    if (width >= 480) return '1.875rem';   // xs  - inchangé
    return '1.625rem';                     // base - légèrement augmenté pour mobile
  };

  const getDescriptionFontSize = () => {
    if (width < 640) return '1.125rem';      // mobile - 18px
    return '1.25rem';                        // autres tailles - 20px (inchangé)
  };

  const texts = [
    "Applications Web.",
    "Applications Mobile.",
    "Design UX/UI.",
    "SEO & Audit."
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentText((prev) => (prev + 1) % texts.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.15,
        delayChildren: 0.2
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.43, 0.13, 0.23, 0.96]
      }
    }
  };

  const textVariants = {
    enter: { opacity: 0, y: 20 },
    center: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96]
      }
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.5,
        ease: [0.43, 0.13, 0.23, 0.96]
      }
    }
  };

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 1480);
    };
    
    checkMobile();
    window.addEventListener('resize', checkMobile);
    
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  const images = [
    {
      src: "https://images.unsplash.com/photo-1498050108023-c5249f4df085?q=80&w=2072&auto=format&fit=crop",
      alt: "Code development"
    },
    {
      src: "https://images.unsplash.com/photo-1517694712202-14dd9538aa97?q=80&w=2070&auto=format&fit=crop",
      alt: "Modern workspace"
    },
    {
      src: "https://images.unsplash.com/photo-1551650975-87deedd944c3?q=80&w=1974&auto=format&fit=crop",
      alt: "Mobile development"
    }
  ];

  useEffect(() => {
    if (isMobile) {
      const interval = setInterval(() => {
        setCurrentImageIndex((prev) => (prev + 1) % images.length);
      }, 5000); // Augmenté de 3000 à 5000ms
      return () => clearInterval(interval);
    }
  }, [isMobile]);

  const imageContainerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        staggerChildren: 0.1,
      },
    },
  };

  return (
    <section className="relative overflow-hidden bg-[#0A0A0A] dark:bg-[#0A0A0A]">
      <div className="relative h-screen flex flex-col items-center justify-start overflow-hidden px-4 overflow-hidden">
        {/* Fond statique avec dégradé */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#FF3366]/20 via-[#FF3366]/10 to-transparent h-full z-0" />
        
        {/* Motif de points statique avec masque de dégradé */}
        <div 
          className="absolute inset-0 h-full z-0 opacity-40"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgb(75, 85, 99) 1px, transparent 0)`,
            backgroundSize: '40px 40px',
            maskImage: 'linear-gradient(to top, black 50%, transparent)',
            WebkitMaskImage: 'linear-gradient(to top, black 50%, transparent)'
          }}
        />

        {/* Contenu animé */}
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={containerVariants}
          className="relative z-10 text-center max-w-8xl mx-auto mt-[15vh] md:mt-[18vh] lg:mt-[24vh] xl:mt-[22vh] 2xl:mt-[20vh] 3xl:mt-[18vh] 4xl:mt-[14vh] flex flex-col items-center justify-center"
        >
          <motion.h1 
            className="font-unbounded font-bold mb-4 md:mb-6 lg:mb-8"
            variants={itemVariants}
            style={{ fontSize: getFontSize() }}
          >
            <motion.div variants={itemVariants}>
              Bienvenue sur
            </motion.div>
            <motion.div 
              className="relative inline-block my-4"
              variants={itemVariants}
            >
              <span className="relative z-10 text-white">izicode</span>
              <motion.div
                className="absolute -left-4 sm:-left-6 -right-4 sm:-right-6 top-0 bottom-0 bg-[#FF3366] -skew-x-[20deg] h-[75%] top-[12.5%]"
                initial={{ scale: 0, rotate: 3 }}
                animate={{ scale: 1, rotate: 3 }}
                transition={{ duration: 0.6, delay: 0.3 }}
              />
            </motion.div>
            <AnimatePresence mode="wait">
              <motion.div
                key={currentText}
                variants={textVariants}
                initial="enter"
                animate="center"
                exit="exit"
                className="min-h-[1.5em]"
              >
                {texts[currentText]}
              </motion.div>
            </AnimatePresence>
          </motion.h1>
          
          <motion.p 
            className="text-gray-300 max-w-4xl mx-auto mb-8 text-[0.85rem] xs:text-base sm:text-lg md:text-xl lg:text-2xl xl:text-2.5xl 2xl:text-3xl px-4 md:leading-relaxed lg:leading-relaxed"
            variants={itemVariants}
          >
            Transformez vos idées en succès avec des solutions web et mobiles sur-mesure, prêtes à l'emploi et conçues pour accélérer votre croissance digitale.
          </motion.p>

          <motion.button 
            onClick={() => setIsModalOpen(true)}
            className="bg-[#FF3366] text-white xs:px-4 sm-phone:px-5 md:px-6 lg:px-8 xs:py-2 sm-phone:py-3 md:py-3 lg:py-4 rounded-full flex items-center gap-4 mx-auto hover:bg-[#FF3366]/90 transition-all xs:text-xs sm-phone:text-sm md:text-base lg:text-lg"
            variants={itemVariants}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
          >
            DEMANDER UN DEVIS
            <ArrowRight className="xs:w-3 xs:h-3 sm-phone:w-4 sm-phone:h-4 md:w-5 md:h-5" />
          </motion.button>
        </motion.div>

        {/* Section des 3 cartes */}
        <div className="absolute bottom-0 left-0 right-0 w-full">
          <div className={`relative ${isMobile ? 'w-full px-4' : 'max-w-[65vw] mx-auto'}`}>
            {isMobile ? (
              // Vue mobile - Carrousel
              <div className="relative w-full aspect-video mb-20">
                <motion.div
                  key={currentImageIndex}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.5 }}
                  className="relative w-full h-full rounded-t-2xl shadow-2xl overflow-hidden transform group"
                >
                  <img
                    src={images[currentImageIndex].src}
                    alt={images[currentImageIndex].alt}
                    className="w-full h-full object-cover brightness-90 transition-transform duration-700 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/40 to-black/20"></div>
                </motion.div>
                
                {/* Indicateurs de navigation */}
                <div className="absolute bottom-4 left-1/2 transform -translate-x-1/2 flex space-x-2">
                  {images.map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentImageIndex(index)}
                      className={`w-2 h-2 rounded-full transition-all ${
                        currentImageIndex === index ? 'bg-white w-4' : 'bg-white/50'
                      }`}
                    />
                  ))}
                </div>
              </div>
            ) : (
              // Vue desktop - 3 cartes avec dimensions responsives
              <div className="flex justify-center items-end">
                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.7, delay: 0.2 }}
                  className="relative w-[calc(40vw)] h-[calc(25vh)] -mr-20 rounded-t-2xl shadow-2xl overflow-hidden transform group"
                  style={{ zIndex: 1 }}
                >
                  <img
                    src={images[0].src}
                    alt={images[0].alt}
                    className="w-full h-full object-cover brightness-90 transition-transform duration-700 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/40 to-black/20"></div>
                </motion.div>

                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.7 }}
                  className="relative w-[calc(45vw)] h-[calc(30vh)] rounded-t-2xl shadow-2xl overflow-hidden transform group"
                  style={{ zIndex: 3 }}
                >
                  <img
                    src={images[1].src}
                    alt={images[1].alt}
                    className="w-full h-full object-cover brightness-90 transition-transform duration-700 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/40 to-black/20"></div>
                </motion.div>

                <motion.div
                  initial={{ y: 100, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ duration: 0.7, delay: 0.4 }}
                  className="relative w-[calc(40vw)] h-[calc(25vh)] -ml-20 rounded-t-2xl shadow-2xl overflow-hidden transform group"
                  style={{ zIndex: 2 }}
                >
                  <img
                    src={images[2].src}
                    alt={images[2].alt}
                    className="w-full h-full object-cover brightness-90 transition-transform duration-700 group-hover:scale-110"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/60 via-black/40 to-black/20"></div>
                </motion.div>
              </div>
            )}
          </div>
        </div>

        <QuoteModal 
          isOpen={isModalOpen} 
          onClose={() => setIsModalOpen(false)} 
        />
      </div>
    </section>
  );
};

export default Hero;
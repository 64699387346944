import React from 'react';
import { Link } from 'react-router-dom';

const CGU = () => {
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-gray-900 text-white">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-32">
        <h1 className="text-4xl font-bold mb-8 text-[#FF3366]">Conditions générales d'utilisation (CGU)</h1>
        <p className="text-gray-400 mb-8">Dernière mise à jour : 1 décembre 2024</p>

        <div className="text-gray-300 mb-12">
          <p>
            Les présentes Conditions générales d'utilisation (CGU) définissent les règles d'accès 
            et d'utilisation du site internet www.izicode.com, édité par Izicode. En accédant à ce 
            site, vous acceptez pleinement et sans réserve les conditions décrites ci-dessous. Si 
            vous n'acceptez pas ces conditions, nous vous demandons de ne pas utiliser notre site.
          </p>
        </div>

        <div className="space-y-12">
          {/* Section Objet */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">Objet</h2>
            <p className="text-gray-300">
              Ces CGU ont pour objet de définir les modalités et conditions d'accès au site www.izicode.com 
              et aux services proposés. Ce site est une plateforme vitrine présentant les services proposés 
              par Izicode, notamment dans les domaines suivants : développement web et mobile, SEO, design 
              UX/UI, et formulaire de contact pour les demandes d'information ou devis.
            </p>
          </section>

          {/* Section Accès au site */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">Accès au site</h2>
            <p className="text-gray-300">
              Le site est accessible gratuitement à tout utilisateur disposant d'une connexion Internet. 
              Izicode met tout en œuvre pour que le site soit accessible 24h/24, 7j/7. Cependant, nous 
              ne garantissons pas l'absence d'interruptions pour des raisons de maintenance, de mises à 
              jour ou de problèmes techniques. Izicode se réserve le droit de suspendre ou de limiter 
              l'accès au site sans préavis en cas de force majeure ou pour des raisons de sécurité.
            </p>
          </section>

          {/* Section Responsabilités de l'utilisateur */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">Responsabilités de l'utilisateur</h2>
            <p className="text-gray-300">
              En utilisant ce site, l'utilisateur s'engage à fournir des informations exactes et complètes 
              lorsqu'il remplit le formulaire de contact, à ne pas utiliser le site pour diffuser du contenu 
              illicite, diffamatoire ou contraire aux bonnes mœurs, et à respecter les droits de propriété 
              intellectuelle relatifs au contenu publié sur le site. L'utilisateur est seul responsable des 
              équipements et connexions nécessaires pour accéder au site.
            </p>
          </section>

          {/* Section Propriété intellectuelle */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">Propriété intellectuelle</h2>
            <p className="text-gray-300">
              Tous les contenus présents sur ce site, tels que les textes, images, vidéos, graphismes, 
              logos et icônes, sont protégés par les lois relatives à la propriété intellectuelle. Ils 
              sont la propriété exclusive d'Izicode, sauf mention contraire explicite. Toute reproduction, 
              distribution, modification ou exploitation, totale ou partielle, du contenu de ce site, sans 
              autorisation préalable écrite d'Izicode, est strictement interdite.
            </p>
          </section>

          {/* Section Collecte de données personnelles */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">Collecte de données personnelles</h2>
            <p className="text-gray-300">
              L'utilisation du formulaire de contact entraîne la collecte de certaines données personnelles, 
              notamment le nom, l'email et le message. Ces données sont utilisées uniquement pour répondre 
              aux demandes d'information ou devis. Pour plus de détails sur la gestion de vos données 
              personnelles, veuillez consulter notre{' '}
              <Link to="/politique-confidentialite" className="text-[#FF3366] hover:underline">
                Politique de confidentialité
              </Link>.
            </p>
          </section>

          {/* Section Absence de cookies */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">Absence de cookies</h2>
            <p className="text-gray-300">
              Le site www.izicode.com n'utilise pas de cookies pour suivre ou collecter les données des utilisateurs.
            </p>
          </section>

          {/* Section Limitations de responsabilité */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">Limitations de responsabilité</h2>
            <p className="text-gray-300">
              Izicode s'efforce d'assurer l'exactitude des informations présentées sur ce site. Toutefois, 
              nous ne garantissons pas l'exhaustivité ou l'actualité des contenus. Les informations sont 
              fournies à titre indicatif. Izicode ne pourra être tenu responsable des dommages directs ou 
              indirects liés à l'utilisation du site, notamment en cas d'interruption de service, de virus 
              ou de problème technique. Le site peut contenir des liens hypertextes vers d'autres sites 
              internet. Izicode décline toute responsabilité quant au contenu et au fonctionnement de ces 
              sites tiers.
            </p>
          </section>

          {/* Section Modifications des CGU */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">Modifications des CGU</h2>
            <p className="text-gray-300">
              Izicode se réserve le droit de modifier à tout moment les présentes CGU pour les adapter aux 
              évolutions législatives, techniques ou fonctionnelles. Les utilisateurs sont invités à consulter 
              régulièrement cette page pour prendre connaissance des éventuelles modifications. Les nouvelles 
              conditions prennent effet dès leur publication sur le site.
            </p>
          </section>

          {/* Section Droit applicable */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">Droit applicable et juridiction compétente</h2>
            <p className="text-gray-300">
              Les présentes CGU sont régies par le droit français. En cas de litige, et à défaut de résolution 
              amiable, les tribunaux compétents seront ceux du ressort d'Amiens.
            </p>
          </section>

          {/* Section Contact */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-[#FF3366]">Contact</h2>
            <p className="text-gray-300 mb-4">
              Pour toute question ou réclamation relative à ces CGU ou à l'utilisation du site, vous pouvez 
              nous contacter :
            </p>
            <ul className="list-none space-y-2 text-gray-300">
              <li>• Par email : contact@izicode.com</li>
              <li>• Par téléphone : 06 10 35 42 59</li>
              <li>• Par courrier : Izicode, 76 rue du Professeur Christian Cabrol, 80000 Amiens, France</li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  );
};

export default CGU;

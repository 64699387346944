import React, { useRef } from 'react';
import { motion } from 'framer-motion';
import { Rocket, Code, Layout, Search, DollarSign, User, Check } from 'lucide-react';

type Step = {
  icon: React.ElementType;
  label: string;
};

type StepIndicatorProps = {
  currentStep: number;
  steps: Step[];
};

export const StepIndicator: React.FC<StepIndicatorProps> = ({ currentStep, steps }) => {
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const stepsRef = useRef<(HTMLDivElement | null)[]>([]);

  const handleStepClick = (index: number, event: React.MouseEvent) => {
    event.preventDefault(); // Empêche tout comportement par défaut
    event.stopPropagation(); // Arrête la propagation de l'événement

    if (scrollContainerRef.current) {
      const stepWidth = scrollContainerRef.current.scrollWidth / steps.length;
      scrollContainerRef.current.scrollTo({
        left: stepWidth * index,
        behavior: 'smooth'
      });
    }
  };

  return (
    <div className="mb-8 sm:mb-12">
      <div 
        className="overflow-x-auto -mx-4 sm:-mx-8 px-4 sm:px-8" 
        ref={scrollContainerRef}
      >
        <div className="flex items-center min-w-max pb-4 sm:pb-6">
          {steps.map((step, index) => (
            <div 
              key={index} 
              className="flex items-center"
              ref={el => stepsRef.current[index] = el}
            >
              <div className="flex items-center">
                {index + 1 === currentStep ? (
                  <motion.div
                    initial={{ scale: 0.8 }}
                    animate={{ scale: [0.8, 1.1, 1] }}
                    transition={{ 
                      duration: 0.5,
                      times: [0, 0.6, 1],
                      type: "spring",
                      stiffness: 400,
                      damping: 8
                    }}
                    className={`w-7 h-7 sm:w-8 sm:h-8 rounded-lg flex items-center justify-center bg-[#FF3366] text-white`}
                  >
                    <step.icon className="w-4 h-4 sm:w-5 sm:h-5" />
                  </motion.div>
                ) : (
                  <div
                    onClick={(e) => index + 1 < currentStep && handleStepClick(index, e)}
                    className={`w-7 h-7 sm:w-8 sm:h-8 rounded-lg flex items-center justify-center ${
                      index + 1 < currentStep
                        ? 'bg-green-500 text-white cursor-pointer hover:bg-green-600 transition-colors'
                        : 'bg-white/10 text-white/50'
                    }`}
                  >
                    {index + 1 < currentStep ? (
                      <Check className="w-4 h-4 sm:w-5 sm:h-5 stroke-[2.5]" />
                    ) : (
                      <step.icon className="w-4 h-4 sm:w-5 sm:h-5" />
                    )}
                  </div>
                )}
                {index + 1 === currentStep ? (
                  <motion.span
                    initial={{ opacity: 0.5, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ delay: 0.3 }}
                    className="ml-2 sm:ml-3 text-xs sm:text-sm text-white"
                  >
                    {step.label}
                  </motion.span>
                ) : (
                  <span
                    onClick={(e) => index + 1 < currentStep && handleStepClick(index, e)}
                    className={`ml-2 sm:ml-3 text-xs sm:text-sm ${
                      index + 1 < currentStep 
                        ? 'text-white/50 cursor-pointer hover:text-white/70 transition-colors' 
                        : 'text-white/50'
                    }`}
                  >
                    {step.label}
                  </span>
                )}
              </div>
              {index < steps.length - 1 && (
                <div
                  className={`w-8 sm:w-12 h-[2px] mx-1 sm:mx-2 ${
                    index + 1 < currentStep
                      ? 'bg-green-500'
                      : index + 1 === currentStep
                        ? 'bg-[#FF3366]'
                        : 'bg-white/10'
                  }`}
                />
              )}
            </div>
          ))}
        </div>
      </div>
      
      {/* Navigation Dots */}
      <div className="flex justify-center items-center gap-1 sm:gap-2 mt-2 sm:mt-4">
        {steps.map((_, index) => (
          <button
            key={index}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
            className={`w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full transition-all duration-300 ${
              index + 1 === currentStep 
                ? 'bg-[#FF3366]' 
                : index + 1 < currentStep
                  ? 'bg-green-500'
                  : 'bg-white/20 hover:bg-white/30'
            }`}
            aria-label={`Scroll to step ${index + 1}`}
          />
        ))}
      </div>
    </div>
  );
};

import React from 'react';

const BlogCardSkeleton = () => {
  return (
    <div className="bg-white dark:bg-gray-800 rounded-xl shadow-lg overflow-hidden h-full animate-pulse">
      {/* Image skeleton */}
      <div className="w-full h-48 bg-gray-300 dark:bg-gray-700" />
      
      <div className="p-6">
        {/* Category skeleton */}
        <div className="w-24 h-6 bg-gray-300 dark:bg-gray-700 rounded mb-4" />
        
        {/* Title skeleton */}
        <div className="w-full h-8 bg-gray-300 dark:bg-gray-700 rounded mb-3" />
        
        {/* Excerpt skeleton */}
        <div className="space-y-2 mb-4">
          <div className="w-full h-4 bg-gray-300 dark:bg-gray-700 rounded" />
          <div className="w-3/4 h-4 bg-gray-300 dark:bg-gray-700 rounded" />
        </div>
        
        {/* Meta info skeleton */}
        <div className="flex items-center space-x-4 mb-4">
          <div className="flex items-center">
            <div className="w-4 h-4 bg-gray-300 dark:bg-gray-700 rounded-full mr-2" />
            <div className="w-20 h-4 bg-gray-300 dark:bg-gray-700 rounded" />
          </div>
          <div className="flex items-center">
            <div className="w-4 h-4 bg-gray-300 dark:bg-gray-700 rounded-full mr-2" />
            <div className="w-20 h-4 bg-gray-300 dark:bg-gray-700 rounded" />
          </div>
        </div>
        
        {/* Button skeleton */}
        <div className="w-32 h-10 bg-gray-300 dark:bg-gray-700 rounded" />
      </div>
    </div>
  );
};

export default BlogCardSkeleton;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, User, Clock, ArrowRight, Search, X } from 'lucide-react';
import BlogModal from '../components/BlogModal';
import NewsletterSubscribe from '../components/NewsletterSubscribe';
import BlogCardSkeleton from '../components/BlogCardSkeleton';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getBlogPosts, normalizeSlug } from '../lib/firebase';
import type { BlogPost } from '../lib/firebase';
import { useInView } from 'react-intersection-observer';

const itemVariants = {
  hidden: {
    opacity: 0,
    y: 20
  },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 15,
      mass: 0.8,
      delay: 0.1
    }
  }
};

const containerVariants = {
  hidden: {
    opacity: 0,
    scale: 0.98
  },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      type: 'spring',
      stiffness: 100,
      damping: 15,
      mass: 0.8
    }
  }
};

const Blog = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [posts, setPosts] = useState<BlogPost[]>(() => {
    const cachedPosts = sessionStorage.getItem('blogPosts');
    return cachedPosts ? JSON.parse(cachedPosts) : [];
  });
  const [isLoading, setIsLoading] = useState(posts.length === 0);
  const [error, setError] = useState<string | null>(null);

  const [cardsRef, cardsInView] = useInView({
    triggerOnce: false,
    threshold: 0.1,
    margin: "-100px"
  });

  useEffect(() => {
    const fetchPosts = async () => {
      if (posts.length > 0) return;

      try {
        setIsLoading(true);
        const postsData = await getBlogPosts();
        setPosts(postsData);
        sessionStorage.setItem('blogPosts', JSON.stringify(postsData));
      } catch (err) {
        setError('Erreur lors du chargement des articles');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPosts();
  }, [posts.length]);

  const categories = Array.from(new Set(posts.map(post => post.category)));
  
  const filteredPosts = posts.filter(post => {
    const matchesSearch = post.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                         post.excerpt.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesCategory = !selectedCategory || post.category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  const featuredPost = posts.find(post => post.featured);
  const regularPosts = filteredPosts.filter(post => !post.featured);

  if (error) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <p className="text-red-500">{error}</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen">
      <Helmet>
        <title>Blog - Izicode | Articles et Actualités</title>
        <meta
          name="description"
          content="Découvrez nos articles sur le développement web, le design, et les dernières tendances technologiques."
        />
      </Helmet>

      <section className="pt-36 sm:pt-40 lg:pt-48 pb-12 px-4 bg-gradient-to-b from-[#FF3366]/20 to-transparent">
        <div className="max-w-7xl mx-auto text-center">
          <motion.h1 
            className="font-unbounded text-2xl sm:text-3xl lg:text-4xl font-bold mb-8"
            variants={itemVariants}
          >
            <motion.div 
              className="relative inline-block my-2"
              variants={itemVariants}
            >
              <span className="relative z-10 text-white">Notre Blog</span>
              <motion.div
                className="absolute -left-1.5 sm:-left-3 -right-1.5 sm:-right-3 -top-1 -bottom-1 bg-[#FF3366] -skew-x-[20deg]"
                initial={{ scale: 0, rotate: 3 }}
                animate={{ scale: 1, rotate: 3 }}
                transition={{ duration: 0.6, delay: 0.3 }}
              />
            </motion.div>
          </motion.h1>
          <motion.p 
            className="text-gray-300 max-w-2xl mx-auto mb-8 text-sm sm:text-base lg:text-lg px-4 mt-6"
            variants={itemVariants}
          >
            Découvrez nos derniers articles sur le développement web, le design et les tendances digitales
          </motion.p>
        </div>
      </section>

      <section className="py-16 px-4">
        <div className="max-w-7xl mx-auto">
          {/* Article à la une */}
          {isLoading ? (
            <div className="mb-12">
              <BlogCardSkeleton />
            </div>
          ) : featuredPost && (
            <motion.div
              className="relative h-[400px] sm:h-[500px] rounded-3xl overflow-hidden mb-12 group"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
            >
              <div className="absolute inset-0">
                <img
                  src={featuredPost.image}
                  alt={featuredPost.title}
                  className="w-full h-full object-cover opacity-90 group-hover:opacity-100 transition-opacity duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-gray-950 via-gray-900/70 to-transparent backdrop-blur-[2px]" />
              </div>
              <div className="absolute inset-x-0 bottom-0 p-6 sm:p-8 md:p-10">
                <motion.span 
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ 
                    duration: 0.4,
                    delay: 0.2
                  }}
                  className="bg-[#FF3366]/80 backdrop-blur-sm text-white px-4 py-2 rounded-full text-sm mb-4 inline-block border border-[#FF3366]/20"
                >
                  À la une
                </motion.span>
                <motion.h2 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.5,
                    delay: 0.4,
                    type: "spring",
                    stiffness: 100,
                    damping: 20
                  }}
                  className="text-2xl sm:text-3xl font-bold mb-3"
                >
                  {featuredPost.title}
                </motion.h2>
                <motion.p 
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ 
                    duration: 0.5,
                    delay: 0.6
                  }}
                  className="text-base sm:text-lg text-gray-200 mb-6 max-w-2xl line-clamp-2"
                >
                  {featuredPost.excerpt}
                </motion.p>
                <motion.div 
                  className="flex items-center gap-2 sm:gap-4 text-sm sm:text-base text-gray-300"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ 
                    duration: 0.4,
                    delay: 0.8
                  }}
                >
                  <motion.span 
                    className="flex items-center gap-1.5 whitespace-nowrap"
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 0.9 }}
                  >
                    <User className="w-4 h-4 sm:w-5 sm:h-5" />
                    {featuredPost.author}
                  </motion.span>
                  <motion.span 
                    className="flex items-center gap-1.5 whitespace-nowrap"
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 1.0 }}
                  >
                    <Calendar className="w-4 h-4 sm:w-5 sm:h-5" />
                    {featuredPost.date}
                  </motion.span>
                  <motion.span 
                    className="flex items-center gap-1.5 whitespace-nowrap"
                    initial={{ x: -20, opacity: 0 }}
                    animate={{ x: 0, opacity: 1 }}
                    transition={{ delay: 1.1 }}
                  >
                    <Clock className="w-4 h-4 sm:w-5 sm:h-5" />
                    {featuredPost.readTime}
                  </motion.span>
                </motion.div>
              </div>
              <Link
                to={`/blog/${normalizeSlug(featuredPost.title)}`}
                className="absolute inset-0 z-30"
              />
            </motion.div>
          )}

          {/* Barre de recherche */}
          <div className="mb-8 relative">
            <input
              type="text"
              placeholder="Rechercher un article..."
              className="w-full pl-12 pr-12 py-4 bg-gray-900/50 backdrop-blur-md border border-gray-800/50 rounded-2xl text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#FF3366]"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <Search className="absolute left-4 top-1/2 -translate-y-1/2 w-5 h-5 text-gray-400" />
            {searchTerm && (
              <button
                onClick={() => setSearchTerm('')}
                className="absolute right-4 top-1/2 -translate-y-1/2 w-6 h-6 flex items-center justify-center rounded-full bg-gray-800/50 hover:bg-gray-700/50 transition-colors"
              >
                <X className="w-4 h-4 text-gray-400" />
              </button>
            )}
          </div>

          {/* Filtres par catégorie */}
          <div className="flex flex-wrap gap-3 mb-12">
            <button
              className={`px-4 py-2 rounded-full text-sm transition-all duration-300 backdrop-blur-md border ${
                !selectedCategory ? 'bg-[#FF3366]/80 border-[#FF3366]/20 text-white' : 'bg-gray-800/30 border-gray-700/30 text-gray-300 hover:bg-gray-700/40'
              }`}
              onClick={() => setSelectedCategory('')}
            >
              Tous
            </button>
            {categories.map(category => (
              <button
                key={category}
                className={`px-4 py-2 rounded-full text-sm transition-all duration-300 backdrop-blur-md border ${
                  selectedCategory === category ? 'bg-[#FF3366]/80 border-[#FF3366]/20 text-white' : 'bg-gray-800/30 border-gray-700/30 text-gray-300 hover:bg-gray-700/40'
                }`}
                onClick={() => setSelectedCategory(category)}
              >
                {category}
              </button>
            ))}
          </div>

          {/* Grille des articles */}
          {isLoading ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
              {[...Array(6)].map((_, index) => (
                <BlogCardSkeleton key={index} />
              ))}
            </div>
          ) : regularPosts.length === 0 ? (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="text-center py-12"
            >
              <h3 className="text-2xl font-semibold text-gray-300 mb-2">Aucun article trouvé</h3>
              <p className="text-gray-400">
                Aucun article ne correspond à votre recherche. Essayez avec d'autres mots-clés ou catégories.
              </p>
            </motion.div>
          ) : (
            <div 
              ref={cardsRef}
              className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6"
            >
              <AnimatePresence mode="sync">
                {regularPosts.map((post, index) => (
                  <motion.div
                    key={post.title}
                    initial={{ opacity: 0, y: 30, scale: 0.95 }}
                    animate={cardsInView ? { 
                      opacity: 1, 
                      y: 0, 
                      scale: 1 
                    } : { 
                      opacity: 0, 
                      y: 30, 
                      scale: 0.95 
                    }}
                    exit={{ opacity: 0, y: -20, scale: 0.95 }}
                    transition={{ 
                      type: "spring",
                      stiffness: 100,
                      damping: 20,
                      mass: 1,
                      duration: 0.6,
                      delay: index * 0.15
                    }}
                  >
                    <Link
                      to={`/blog/${normalizeSlug(post.title)}`}
                      className="group relative h-[400px] rounded-2xl overflow-hidden bg-black/20 backdrop-blur-sm border border-white/10 transition-all duration-300 hover:scale-[1.02] block"
                    >
                      <div className="absolute inset-0">
                        <img
                          src={post.image}
                          alt={post.title}
                          className="w-full h-full object-cover opacity-90 group-hover:opacity-100 transition-opacity duration-300"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-gray-950/90 via-gray-900/50 to-transparent backdrop-blur-[2px]" />
                      </div>
                      <div className="absolute top-4 left-4 z-10">
                        <span className="bg-[#FF3366]/80 backdrop-blur-sm text-white px-3 py-1 rounded-full text-sm">
                          {post.category}
                        </span>
                      </div>
                      <div className="absolute inset-x-0 bottom-0 p-4 sm:p-6 backdrop-blur-md bg-black/10">
                        <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-3">{post.title}</h3>
                        <p className="text-base sm:text-lg text-gray-200 mb-6 max-w-2xl line-clamp-2">{post.excerpt}</p>
                        <div className="flex items-center gap-4 text-sm text-gray-400">
                          <span className="flex items-center gap-1.5">
                            <Calendar className="w-4 h-4" />
                            {post.date}
                          </span>
                          <span className="flex items-center gap-1.5">
                            <Clock className="w-4 h-4" />
                            {post.readTime}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </motion.div>
                ))}
              </AnimatePresence>
            </div>
          )}
        </div>
      </section>

      <section className="py-20 px-4">
        <div className="max-w-4xl mx-auto text-center px-4">
          <h2 className="text-3xl font-unbounded font-bold mb-4">Restez informé</h2>
          <p className="text-gray-400 mb-8">
            Abonnez-vous à notre newsletter pour recevoir nos derniers articles et actualités
          </p>
          <NewsletterSubscribe />
        </div>
      </section>
    </div>
  );
};

export default Blog;
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { confirmNewsletterSubscription } from '../services/newsletter';
import { Container, Typography, Box, CircularProgress } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

const ConfirmNewsletter = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<'loading' | 'success' | 'error'>('loading');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const confirmToken = searchParams.get('token');
    console.log('Token reçu:', confirmToken);
    
    if (!confirmToken) {
      console.log('Aucun token trouvé dans les paramètres URL');
      setStatus('error');
      setMessage('Token de confirmation invalide.');
      return;
    }

    const confirmSubscription = async () => {
      try {
        console.log('Tentative de confirmation avec le token:', confirmToken);
        const result = await confirmNewsletterSubscription(confirmToken);
        console.log('Résultat de la confirmation:', result);
        
        if (result.success) {
          setStatus('success');
          setMessage(result.message);
        } else {
          setStatus('error');
          setMessage(result.message);
        }
      } catch (error) {
        console.error('Erreur lors de la confirmation:', error);
        setStatus('error');
        setMessage('Une erreur est survenue lors de la confirmation.');
      }
    };

    confirmSubscription();
  }, [searchParams]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}
    >
      <Container maxWidth="sm" sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center',
            justifyContent: 'center'
          }}
        >
          {status === 'loading' && (
            <>
              <CircularProgress />
              <Typography variant="h6" sx={{ mt: 2 }}>
                Confirmation de votre inscription...
              </Typography>
            </>
          )}

          {status === 'success' && (
            <>
              <CheckCircleOutlineIcon
                sx={{ fontSize: 60, color: 'success.main', mb: 2 }}
              />
              <Typography variant="h5" gutterBottom>
                Inscription confirmée !
              </Typography>
              <Typography>{message}</Typography>
            </>
          )}

          {status === 'error' && (
            <>
              <ErrorOutlineIcon
                sx={{ fontSize: 60, color: 'error.main', mb: 2 }}
              />
              <Typography variant="h5" gutterBottom>
                Erreur de confirmation
              </Typography>
              <Typography>{message}</Typography>
            </>
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default ConfirmNewsletter;

import { Helmet } from 'react-helmet-async';
import { SEOProps } from '../../types/seo.types';

const defaultSEO = {
  siteName: 'IziCode',
  siteUrl: 'https://izicode.fr',
  twitterCreator: '@IziCode',
  language: 'fr',
};

interface SEOComponentProps {
  pageSEOConfig?: SEOProps;
  title?: string;
  description?: string;
  canonical?: string;
  ogType?: string;
  ogImage?: string;
  ogTitle?: string;
  ogDescription?: string;
  twitterCard?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
  twitterCreator?: string;
  noindex?: boolean;
  nofollow?: boolean;
  keywords?: string[];
  author?: string;
  language?: string;
}

const SEO: React.FC<SEOComponentProps> = ({
  pageSEOConfig,
  ...props
}) => {
  // Utiliser la config de la page si elle existe, sinon utiliser les props individuelles
  const config = pageSEOConfig || props;
  
  const metaTitle = config.title;
  const metaDescription = config.description;
  const metaImage = config.ogImage || `${defaultSEO.siteUrl}/og-image.jpg`;

  // Génération des balises structurées (JSON-LD)
  const organizationSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "IziCode",
    "url": "https://izicode.fr",
    "logo": "https://izicode.fr/logo.png",
    "sameAs": [
      "https://www.facebook.com/izicode.fr",
      "https://www.linkedin.com/company/izicode-france",
      "https://www.instagram.com/izicode.fr" // Ajoute si tu as d'autres réseaux.
    ],
    "contactPoint": {
      "@type": "ContactPoint",
      "telephone": "+33683919738", // Remplace avec ton numéro valide.
      "contactType": "customer service",
      "areaServed": "FR",
      "availableLanguage": "French"
    }
  };

  return (
    <Helmet>
      {/* DNS Prefetch et Preconnect */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
      <link rel="preconnect" href="https://firebaseapp.com" />
      <link rel="preconnect" href="https://firestore.googleapis.com" />
      <link rel="dns-prefetch" href="https://fonts.googleapis.com" />
      <link rel="dns-prefetch" href="https://fonts.gstatic.com" />
      <link rel="dns-prefetch" href="https://firebaseapp.com" />
      <link rel="dns-prefetch" href="https://firestore.googleapis.com" />

      {/* Performance */}
      <link rel="preload" as="font" href="/fonts/unbounded/Unbounded-Regular.ttf" type="font/truetype" crossOrigin="anonymous" />
      <link rel="preload" as="font" href="/fonts/unbounded/Unbounded-Bold.ttf" type="font/truetype" crossOrigin="anonymous" />
      <link rel="preload" as="font" href="/fonts/unbounded/Unbounded-Medium.ttf" type="font/truetype" crossOrigin="anonymous" />
      
      {/* Balises de base */}
      <html lang={config.language || defaultSEO.language} />
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      {config.keywords && config.keywords.length > 0 && (
        <meta name="keywords" content={config.keywords.join(', ')} />
      )}
      {config.author && <meta name="author" content={config.author} />}
      
      {/* Balises canoniques et robots */}
      <link rel="canonical" href={config.canonical || defaultSEO.siteUrl} />
      {(config.noindex || config.nofollow) && (
        <meta 
          name="robots" 
          content={`${config.noindex ? 'noindex' : 'index'}, ${config.nofollow ? 'nofollow' : 'follow'}`} 
        />
      )}

      {/* Open Graph */}
      <meta property="og:title" content={config.ogTitle || metaTitle} />
      <meta property="og:description" content={config.ogDescription || metaDescription} />
      <meta property="og:type" content={config.ogType || 'website'} />
      <meta property="og:image" content={metaImage} />
      <meta property="og:url" content={config.canonical || defaultSEO.siteUrl} />
      <meta property="og:site_name" content={defaultSEO.siteName} />

      {/* Twitter Card */}
      <meta name="twitter:card" content={config.twitterCard || 'summary_large_image'} />
      <meta name="twitter:title" content={config.twitterTitle || config.ogTitle || metaTitle} />
      <meta name="twitter:description" content={config.twitterDescription || config.ogDescription || metaDescription} />
      <meta name="twitter:image" content={config.twitterImage || metaImage} />
      <meta name="twitter:creator" content={config.twitterCreator || defaultSEO.twitterCreator} />

      {/* PWA */}
      <meta name="theme-color" content="#FF3366" />
      <meta name="mobile-web-app-capable" content="yes" />
      <meta name="application-name" content={defaultSEO.siteName} />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-title" content={defaultSEO.siteName} />
      
      {/* Sécurité */}
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="referrer" content="strict-origin-when-cross-origin" />

      {/* Schema.org */}
      <script type="application/ld+json">
        {JSON.stringify(organizationSchema)}
      </script>
    </Helmet>
  );
};

export default SEO;

import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { IoClose } from 'react-icons/io5';
import { AlertCircle, Lock, Bot, ShieldCheck } from 'lucide-react';

interface BetaInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const BetaInfoModal: React.FC<BetaInfoModalProps> = ({ isOpen, onClose }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onClose}
            className="absolute inset-0 bg-black/80 backdrop-blur-sm"
          />
          
          {/* Modal */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            transition={{ type: "spring", duration: 0.5 }}
            className="relative w-[95%] max-w-2xl mx-auto"
          >
            <div className="relative bg-gradient-to-br from-black/80 to-black/60 backdrop-blur-2xl border border-white/5 rounded-2xl p-10 shadow-2xl">
              <div className="absolute inset-0 bg-gradient-to-br from-[#0A0A0A]/80 to-[#0A0A0A]/60 rounded-2xl" style={{ zIndex: -1 }} />
              
              {/* Close button */}
              <button
                onClick={onClose}
                className="absolute right-5 top-5 text-white/60 hover:text-white transition-colors"
              >
                <IoClose size={24} />
              </button>

              {/* Header */}
              <div className="flex items-center gap-4 mb-12">
                <div className="w-14 h-14 rounded-full bg-gradient-to-br from-blue-500/30 to-blue-400/10 backdrop-blur-xl flex items-center justify-center border border-blue-500/20">
                  <AlertCircle className="w-7 h-7 text-blue-400" />
                </div>
                <div>
                  <h3 className="text-2xl font-semibold">Bienvenue sur notre Devis IA</h3>
                  <div className="flex items-center gap-2 mt-2">
                    <span className="text-gray-400">Version Beta</span>
                    <span className="px-2 py-0.5 text-xs font-medium bg-blue-500/20 text-blue-400 rounded-full border border-blue-500/20">beta</span>
                  </div>
                </div>
              </div>

              {/* Content */}
              <div className="space-y-8">
                <div className="flex items-start gap-5">
                  <div className="w-10 h-10 rounded-lg bg-gradient-to-br from-[#FF3366]/20 to-transparent backdrop-blur-xl flex items-center justify-center border border-[#FF3366]/10 shrink-0">
                    <Bot className="w-5 h-5 text-[#FF3366]" />
                  </div>
                  <div>
                    <h4 className="font-medium mb-2">Fonctionnalité en développement</h4>
                    <p className="text-gray-400">Notre système de devis par IA est actuellement en version bêta. Il peut parfois générer des réponses imprécises ou nécessiter des ajustements. Nous travaillons continuellement à son amélioration.</p>
                  </div>
                </div>

                <div className="flex items-start gap-5">
                  <div className="w-10 h-10 rounded-lg bg-gradient-to-br from-[#FF3366]/20 to-transparent backdrop-blur-xl flex items-center justify-center border border-[#FF3366]/10 shrink-0">
                    <Lock className="w-5 h-5 text-[#FF3366]" />
                  </div>
                  <div>
                    <h4 className="font-medium mb-2">Confidentialité assurée</h4>
                    <p className="text-gray-400">Vos informations personnelles sont traitées de manière confidentielle et ne seront jamais partagées avec des tiers. Nous utilisons un cryptage de bout en bout pour protéger vos données.</p>
                  </div>
                </div>

                <div className="flex items-start gap-5">
                  <div className="w-10 h-10 rounded-lg bg-gradient-to-br from-[#FF3366]/20 to-transparent backdrop-blur-xl flex items-center justify-center border border-[#FF3366]/10 shrink-0">
                    <ShieldCheck className="w-5 h-5 text-[#FF3366]" />
                  </div>
                  <div>
                    <h4 className="font-medium mb-2">Support et assistance</h4>
                    <p className="text-gray-400">Si vous rencontrez des problèmes ou avez des questions, notre équipe est disponible pour vous aider. N'hésitez pas à nous contacter via le formulaire de contact ou par email.</p>
                  </div>
                </div>
              </div>

              {/* Footer */}
              <div className="mt-10 text-center">
                <button
                  onClick={onClose}
                  className="px-8 py-3 bg-gradient-to-r from-[#FF3366] to-[#FF3366]/90 hover:from-[#FF3366]/90 hover:to-[#FF3366]/80 rounded-xl font-medium transition-all duration-300 shadow-lg shadow-[#FF3366]/20"
                >
                  J'ai compris
                </button>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default BetaInfoModal;

import React from 'react';

const CallToAction = () => {
  return (
    <section className="bg-dark text-white py-24 md:py-32 lg:py-40">
      <div className="container mx-auto px-4">
        <div className="max-w-4xl mx-auto text-center space-y-8">
          <h2 className="text-3xl md:text-4xl font-bold leading-tight">
            VOUS CHERCHEZ UN DÉVELOPPEUR POUR DONNER VIE À VOTRE PROJET DIGITAL ?
          </h2>
          <p className="text-base md:text-lg opacity-90 max-w-3xl mx-auto">
            Vous avez une idée innovante ? Vous souhaitez moderniser votre présence en ligne ? 
            Vous voulez un site web qui se démarque de la concurrence ? Ensemble, transformons votre vision 
            en une réalité digitale performante et attractive.
          </p>
          <div>
            <button className="bg-primary hover:bg-primary-dark text-white font-bold py-4 px-10 rounded-full transition-all duration-300 transform hover:scale-105">
              Demander un devis
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;

import React, { useRef } from 'react';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faWordpress, 
  faReact, 
  faFigma,
} from '@fortawesome/free-brands-svg-icons';
import {
  faMagnifyingGlass,
  faFileLines,
  faGlobe,
  faCode,
  faPenNib,
  faMobile,
  faShareNodes,
  faFileCode,
  faFont
} from '@fortawesome/free-solid-svg-icons';

// Définition des services avec leurs vraies icônes
const services = [
  [
    { icon: faFigma, name: 'Maquette', color: '#FF0000' },
    { icon: faWordpress, name: 'Intégration Wordpress', color: '#21759B' },
    { icon: faMagnifyingGlass, name: 'SEO', color: '#00AB6C' },
    { icon: faShareNodes, name: 'Template réseaux sociaux', color: '#1DA1F2' },
    { icon: faFileCode, name: 'Landing page', color: '#F7DF1E' },
    { icon: faFileLines, name: 'Guide de marque', color: '#FF6B6B' },
    { icon: faFont, name: 'Naming', color: '#9C27B0' },
  ],
  [
    { icon: faFileLines, name: 'Guide de marque', color: '#FF6B6B' },
    { icon: faFileCode, name: 'Landing page', color: '#F7DF1E' },
    { icon: faGlobe, name: 'Site web', color: '#61DAFB' },
    { icon: faFont, name: 'Naming', color: '#9C27B0' },
    { icon: faWordpress, name: 'Intégration Wordpress', color: '#21759B' },
    { icon: faMagnifyingGlass, name: 'SEO', color: '#00AB6C' },
    { icon: faFileCode, name: 'Landing page', color: '#F7DF1E' },
  ],
  [
    { icon: faMobile, name: 'Site web', color: '#61DAFB' },
    { icon: faShareNodes, name: 'Template réseaux sociaux', color: '#1DA1F2' },
    { icon: faFigma, name: 'Maquette', color: '#FF0000' },
    { icon: faFileCode, name: 'Landing page', color: '#F7DF1E' },
    { icon: faFont, name: 'Naming', color: '#9C27B0' },
    { icon: faWordpress, name: 'Intégration Wordpress', color: '#21759B' },
    { icon: faShareNodes, name: 'Template réseaux sociaux', color: '#1DA1F2' },
  ],
];

const createInfiniteArray = (arr: any[]) => [...arr, ...arr, ...arr, ...arr];

const ServicesScroll = () => {
  const containerRef = useRef<HTMLDivElement>(null);

  const calculateOpacity = (x: number, containerWidth: number) => {
    const fadeWidth = 100;
    if (x < fadeWidth) {
      return x / fadeWidth;
    }
    if (x > containerWidth - fadeWidth) {
      return (containerWidth - x) / fadeWidth;
    }
    return 1;
  };

  return (
    <div className=" w-full md:w-full lg:w-full xl:w-[70vw] mx-auto relative overflow-hidden py-4" ref={containerRef}>
      <div className="relative">
        {services.map((row, rowIndex) => {
          const x = useMotionValue(0);
          
          return (
            <motion.div
              key={rowIndex}
              className="flex items-center gap-4 py-2"
              style={{ x }}
              initial={{ x: rowIndex % 2 === 0 ? '0%' : '-100%' }}
              animate={{ x: rowIndex % 2 === 0 ? '-100%' : '0%' }}
              transition={{
                duration: rowIndex === 0 ? 80 : rowIndex === 1 ? 90 : 100,
                repeat: Infinity,
                ease: "linear",
                repeatType: "loop",
              }}
            >
              {createInfiniteArray(row).map((service, index) => {
                const opacity = useTransform(x, (latest) => 
                  calculateOpacity(latest + index * 200, containerRef.current?.clientWidth || 0)
                );

                return (
                  <motion.div
                    key={index}
                    style={{ opacity }}
                    className="flex items-center gap-3 text-white/70 whitespace-nowrap px-5 py-3 rounded-2xl 
                    backdrop-blur-md bg-black/40 border border-white/[0.08]
                    shadow-[0_4px_15px_rgba(0,0,0,0.3)] 
                    hover:bg-black/50 transition-all duration-300"
                  >
                    <div className="w-6 h-6 flex items-center justify-center">
                      <FontAwesomeIcon 
                        icon={service.icon} 
                        className="w-5 h-5" 
                        style={{ color: service.color }}
                      />
                    </div>
                    <span className="text-[15px] font-normal tracking-wide">{service.name}</span>
                  </motion.div>
                );
              })}
            </motion.div>
          );
        })}
      </div>
    </div>
  );
};

export default ServicesScroll;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import { ThemeProvider } from './context/ThemeContext';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import ChatBubble from './components/ChatBubble';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import { Helmet } from 'react-helmet';
import { Toaster } from 'react-hot-toast';
import './styles/scrollbar-hide.css';
import CookieConsent from './components/CookieConsent';
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import ServicesScroll from './components/ServicesScroll';
import Projects from './pages/Projects';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import Contact from './pages/Contact';
import FAQ from './pages/FAQ';
import MentionsLegales from './pages/MentionsLegales';
import PolitiqueConfidentialite from './pages/PolitiqueConfidentialite';
import CGU from './pages/CGU';
import PolitiqueCookies from './pages/PolitiqueCookies';
import Quote from './pages/Quote';
import QuoteIA from './pages/QuoteIA';
import ErrorPage from './pages/ErrorPage';
import Unsubscribe from './pages/Unsubscribe';
import ConfirmNewsletter from './pages/ConfirmNewsletter';
import DevisChat from './pages/DevisChat';

const AppRouter = () => {
  const location = useLocation();
  
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/services-scroll" element={<ServicesScroll />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/:slug" element={<BlogPost />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/politique-confidentialite" element={<PolitiqueConfidentialite />} />
        <Route path="/cgu" element={<CGU />} />
        <Route path="/politique-cookies" element={<PolitiqueCookies />} />
        <Route path="/devis" element={<Quote />} />
        <Route path="/devis-ia" element={<QuoteIA />} />
        <Route path="/devis-chat" element={<DevisChat />} />
        <Route path="/unsubscribe" element={<Unsubscribe />} />
        <Route path="/confirm-newsletter" element={<ConfirmNewsletter />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </AnimatePresence>
  );
};

const App = () => {
  return (
    <HelmetProvider>
      <ThemeProvider>
        <Router>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Izicode - Développement web sur mesure</title>
            <meta name="description" content="Izicode - Agence de développement web sur mesure. Création de sites web, applications web et mobiles." />
          </Helmet>
          <div className="flex flex-col min-h-screen bg-white dark:bg-[#0A0A0A]">
            <Navbar />
            <AppRouter />
            <ChatBubble />
            <Footer />
            <ScrollToTop />
          </div>
          <Toaster position="bottom-right" />
          <CookieConsent />
        </Router>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
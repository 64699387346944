// Types et interfaces
export interface ChatMessage {
  role: 'system' | 'user' | 'assistant';
  content: string;
}

export interface ProjectInfo {
  name?: string;
  phone?: string;
  email?: string;
  projectType?: string;
  description?: string;
  budget?: string;
  timeline?: string;
  status: 'collecting' | 'completed';
}

interface EmailParams {
  to: string;
  subject: string;
  content: string;
}

const SYSTEM_PROMPT = `
Tu es un assistant intelligent dédié à la création de devis personnalisés pour des projets numériques (sites web, applications mobiles, etc.) chez Izicode.

Rôle et comportement général :
	•	Tu dois accompagner l’utilisateur dans la collecte d’informations nécessaires à l’établissement d’un devis.
	•	Tu dois être poli, chaleureux, professionnel, et utiliser un langage simple, clair et accessible.
	•	Tu n’es pas un moteur de recherche, tu ne réponds qu’aux questions en lien avec l’établissement d’un devis (projets web, appli, etc.). Si l’utilisateur s’éloigne du sujet, recentre la conversation sur la demande de devis.
	•	Tu ne poses jamais plusieurs questions à la fois. Une seule question par message.
	•	Tu t’adaptes intelligemment aux réponses de l’utilisateur, comprends son contexte et évites de reposer des questions déjà répondues.
	•	Ton objectif final : collecter toutes les informations nécessaires avant de conclure, puis faire une synthèse complète de la demande.
	•	Tu ne termines la conversation que lorsque tu as obtenu :
• Les coordonnées complètes de l’utilisateur (prénom, numéro de téléphone, adresse email)
• Une description du projet (ce que l’utilisateur souhaite réaliser)
• Les spécifications techniques souhaitées (ou le fait que l’utilisateur n’en a pas, auquel cas tu le rassures et acceptes une réponse vague)
• La question du design / charte graphique (existante ou à créer)
• Les besoins particuliers (hébergement, SEO, maintenance, etc.) ou l’absence de ces besoins
• Le délai de réalisation souhaité par l’utilisateur
• Le budget (même estimatif) pour le projet
• Les éventuelles remarques ou compléments d’information de l’utilisateur
	•	Si l’utilisateur a déjà répondu par exemple au délai et au budget sans y être invité dans l’ordre prévu, tu prends note et tu ne reposes pas ces questions plus tard.
	•	À la fin, fais un résumé sous forme de liste de toutes les informations collectées et demande à l’utilisateur de valider ou de modifier. Une fois validé, termine poliment.

Comportement face aux messages particuliers :
	•	Si l’utilisateur tape un message incompréhensible, indique-lui que tu n’as pas compris, propose ton aide, et rappelle que tu es là pour l’aider à établir un devis.
	•	Si l’utilisateur dit « Bonjour » ou « Salut », réponds en le saluant chaleureusement, puis invite-le à exprimer sa demande.
	•	Si l’utilisateur revient après une coupure ou pose une question hors sujet, réponds poliment, recentre sur l’objectif (collecter des infos pour le devis).

Formatage des réponses :
	•	Une seule question par message.
	•	Pour CHAQUE élément d’une liste ou POUR CHAQUE question, utilise un retour à la ligne.
	•	Aère le texte avec des espaces entre les paragraphes.
	•	Par exemple, pour demander des informations, utilise ce format :
“Pour commencer, j’aurais besoin des informations suivantes :
• Votre prénom
• Votre numéro de téléphone
• Votre adresse email”
Au lieu de “Pourriez-vous me donner votre prénom, numéro de téléphone et adresse email ?”
	•	Pour poser plusieurs questions dans un même message (exceptionnellement lors d’un récapitulatif ou d’un changement de contexte), présente-les ainsi :
“J’aimerais en savoir plus sur votre projet :
• Combien de pages souhaitez-vous ?
• Avez-vous déjà une identité visuelle ?
• Quelles fonctionnalités vous intéressent ?”

(NB : Toutefois, privilégier une question à la fois. Les listes multiples sont à utiliser seulement si nécessaire, par exemple lors d’un résumé ou pour réorienter l’utilisateur après une incompréhension.)

Scénarios d’adaptation :
	•	Si l’utilisateur répond “oui” à une question portant sur des besoins spécifiques, par exemple l’hébergement, le SEO, etc., et qu’il ne précise pas lesquels, relance-le en demandant lesquels précisément.
	•	Si l’utilisateur mentionne spontanément des informations (ex. : délai ou budget) avant que tu ne les demandes, enregistre ces informations et ne redemande pas plus tard.
	•	Si l’utilisateur sort du contexte (demande des infos techniques non liées au projet ou parle d’autre chose), recentre-le sur la collecte d’informations pour le devis, rappelle que tu es là pour ça et non pour autre chose.

Conclusion du processus :
	•	Une fois que toutes les informations ont été collectées, fais un résumé clair sous forme de liste, reprenant chacune des informations essentielles, par exemple :
“Voici le récapitulatif de votre demande :
• Prénom : …
• Numéro de téléphone : …
• Email : …
• Description du projet : …
• Spécifications techniques : …
• Design/Charte graphique : …
• Besoins spécifiques (hébergement, SEO, etc.) : …
• Délai souhaité : …
• Budget : …
• Remarques supplémentaires : …”
	•	Demande à l’utilisateur de valider ou de modifier. S’il valide, remercie-le et explique que vous allez lui envoyer une proposition de devis. S’il souhaite modifier, intègre les modifications et redemande la validation.

Contact direct :
	•	En cas de difficulté ou de problème, propose à l’utilisateur de contacter directement Izicode par email à contact@izicode.fr.
`;

import { getFunctions, httpsCallable } from 'firebase/functions';

const REQUIRED_FIELDS = ['name', 'email', 'projectType', 'description'] as const;

class ChatHistory {
  private messages: ChatMessage[] = [];
  private projectInfo: ProjectInfo = {
    status: 'collecting',
  };

  constructor() {
    this.messages = [{ role: 'system', content: SYSTEM_PROMPT }];
  }

  addMessage(role: ChatMessage['role'], content: string) {
    this.messages.push({ role, content });
    this.checkProjectCompletion();
  }

  getMessages(): ChatMessage[] {
    return this.messages;
  }

  updateProjectInfo(newInfo: Partial<ProjectInfo>) {
    this.projectInfo = { ...this.projectInfo, ...newInfo };
    this.checkProjectCompletion();
  }

  getProjectInfo(): ProjectInfo {
    return this.projectInfo;
  }

  private checkProjectCompletion() {
    console.log('Checking project completion with info:', this.projectInfo);
    
    const missingFields = REQUIRED_FIELDS.filter(
      field => !this.projectInfo[field] || this.projectInfo[field]!.length === 0
    );

    console.log('Missing fields:', missingFields);
    
    const hasAllRequiredFields = missingFields.length === 0;

    console.log('Has all required fields:', hasAllRequiredFields);
    console.log('Current status:', this.projectInfo.status);

    if (hasAllRequiredFields) {
      console.log('All fields collected, updating status to completed');
      this.projectInfo.status = 'completed';
      
      // Log the final project info
      console.log('Final project info:', JSON.stringify(this.projectInfo, null, 2));
    }
  }

  clear() {
    this.messages = [{ role: 'system', content: SYSTEM_PROMPT }];
    this.projectInfo = { status: 'collecting' };
  }

  truncateHistory(maxMessages: number) {
    if (this.messages.length > maxMessages) {
      this.messages = this.messages.slice(-maxMessages);
    }
  }
}

export const chatHistory = new ChatHistory();

export async function getChatCompletion(message: string) {
  try {
    // Ajouter le message utilisateur à l'historique
    chatHistory.addMessage('user', message);

    // Troncature pour limiter les coûts
    chatHistory.truncateHistory(20);

    console.log('Sending messages:', chatHistory.getMessages()); // Ajout de logs

    const response = await fetch('https://us-central1-izicode-fe7fc.cloudfunctions.net/chatCompletion', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: chatHistory.getMessages(),
        projectInfo: chatHistory.getProjectInfo(),
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      console.error('Server response:', errorText);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const completion = await response.json();
    console.log('Server response:', completion);

    // Vérifier si la réponse est valide
    const responseText = completion.response || completion.message;
    if (!completion.success || !responseText) {
      console.error('Invalid server response:', completion);
      throw new Error(completion.error || 'Invalid response format from server');
    }

    // Ajouter la réponse de l'assistant à l'historique
    chatHistory.addMessage('assistant', responseText);

    // Analyser le message pour extraire les informations du projet
    await processMessage(message, responseText);

    return responseText;
  } catch (error) {
    console.error('Error in getChatCompletion:', error);
    return "Je rencontre des difficultés techniques pour répondre. Vous pouvez aussi nous contacter directement à contact@izicode.fr.";
  }
}

async function processMessage(userMessage: string, assistantResponse: string) {
  const projectInfo = chatHistory.getProjectInfo();
  console.log('Processing message with current project info:', projectInfo);

  let updates: Partial<ProjectInfo> = {};

  // Détection du nom uniquement si la question précédente demandait le prénom
  const previousMessageIndex = chatHistory.getMessages().length - 3;
  if (!projectInfo.name && previousMessageIndex >= 0) {
    const previousMessage = chatHistory.getMessages()[previousMessageIndex];
    if (previousMessage && 
        previousMessage.role === 'assistant' &&
        previousMessage.content.toLowerCase().includes('prénom')) {
      // Prendre le premier mot comme prénom, en ignorant les caractères spéciaux
      const words = userMessage.split(/[,\s]+/);
      const name = words[0].trim();
      if (name && name.length > 1) {
        updates.name = name;
        console.log('Name detected:', name);
      }
    }
  }

  // Détection de l'email avec validation
  const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/;
  const emailMatch = userMessage.match(emailRegex);
  if (emailMatch && !projectInfo.email) {
    const email = emailMatch[0].toLowerCase();
    updates.email = email;
    console.log('Email detected:', email);
  }

  // Détection du type de projet
  if (!projectInfo.projectType) {
    const projectTypes = ['site web', 'application mobile', 'application web', 'e-commerce'];
    const detectedType = projectTypes.find(type => 
      userMessage.toLowerCase().includes(type)
    );
    if (detectedType) {
      updates.projectType = detectedType;
      console.log('Project type detected:', detectedType);
    }
  }

  // Détection de la description du projet
  if (!projectInfo.description) {
    const previousMessage = chatHistory.getMessages()[previousMessageIndex];
    if (previousMessage && 
        previousMessage.role === 'assistant' &&
        (previousMessage.content.toLowerCase().includes('projet') ||
         previousMessage.content.toLowerCase().includes('besoin'))) {
      updates.description = userMessage.trim();
      console.log('Description detected:', userMessage.trim());
    }
  }

  // Mise à jour des informations du projet
  if (Object.keys(updates).length > 0) {
    console.log('Updating project info with:', updates);
    chatHistory.updateProjectInfo(updates);
  }

  // Vérifier si toutes les informations requises sont collectées
  chatHistory.checkProjectCompletion();

  return chatHistory.getProjectInfo();
}

export async function getInitialMessage(): Promise<string> {
  chatHistory.clear();
  return "Bonjour ! Je suis là pour vous aider à créer votre devis personnalisé. Pour commencer, pourriez-vous me dire votre prénom ?";
}

export function getCurrentProjectInfo(): ProjectInfo {
  return chatHistory.getProjectInfo();
}

export const sendEmail = async (params: EmailParams): Promise<void> => {
  try {
    console.log('Starting email send process');
    console.log('Email parameters:', params);

    const response = await fetch('http://localhost:5001/izicode-c6e87/us-central1/sendEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: params.to,
        subject: params.subject,
        htmlContent: `
          <div style="font-family: Arial, sans-serif; max-width: 600px; margin: 0 auto;">
            <h1 style="color: #E91E63;">Votre devis Izicode</h1>
            <div style="white-space: pre-line; margin-top: 20px;">
              ${params.content}
            </div>
            <div style="margin-top: 30px; color: #666;">
              <p>Pour toute question, n'hésitez pas à nous contacter à contact@izicode.fr</p>
            </div>
          </div>
        `
      }),
    });

    console.log('Response status:', response.status);
    const responseData = await response.text();
    console.log('Response data:', responseData);

    if (!response.ok) {
      throw new Error(`Erreur lors de l'envoi de l'email: ${response.status} ${responseData}`);
    }

    console.log('Email sent successfully');
  } catch (error) {
    console.error('Error in sendEmail:', error);
    throw error;
  }
};

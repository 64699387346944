import React from 'react';
import SEO from '../components/SEO/SEO';
import { pageSEOConfigs } from '../config/seo.config';
import Hero from '../components/Hero';
import ServicesSection from '../components/Services';
import ServicesScroll from '../components/ServicesScroll';
import Process from '../components/Process';
import Technologies from '../components/Technologies';
import ProjectsSection from '../components/Projects';
// import TestimonialsSection from '../components/Testimonials';
import AboutSection from '../components/About';
import Contact from '../components/Contact';
import LatestPosts from '../components/LatestPosts';
import ServiceOfferings from '../components/ServiceOfferings';
import CallToAction from '../components/CallToAction';

const Home = () => {
  return (
    <>
      <SEO {...pageSEOConfigs.home} />
      <Hero />
      {/* <ServiceOfferings /> */}
      <ServicesSection />
      <ServicesScroll />
      <CallToAction />
      <Process />
      <Technologies />
      <ProjectsSection />
      <AboutSection />
      <LatestPosts />
      {/* <TestimonialsSection /> */}
      <Contact />
    </>
  );
};

export default Home;

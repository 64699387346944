import React from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Rocket, Target, Clock } from 'lucide-react';

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <section className="py-20 px-4" id="about">
      <div className="max-w-7xl mx-auto px-4">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6 }}
          className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center"
        >
          <div className="text-center md:text-left">
            <h2 className="text-2xl sm:text-4xl font-bold mb-4 sm:mb-6 font-unbounded">Notre Vision</h2>
            <p className="text-gray-400 text-sm sm:text-base mb-6">
              Chez Izicode, nous croyons en la puissance du digital pour transformer vos idées en solutions concrètes. Nous nous engageons à concevoir des projets qui dépassent vos attentes.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="flex flex-col items-center text-center p-4">
                <div className="w-16 h-16 bg-[#FF3366]/10 rounded-2xl flex items-center justify-center mb-4">
                  <Rocket className="w-8 h-8 text-[#FF3366]" />
                </div>
                <h3 className="text-2xl font-bold mb-2">10+</h3>
                <p className="text-gray-400">Projets livrés avec succès</p>
              </div>
              <div className="flex flex-col items-center text-center p-4">
                <div className="w-16 h-16 bg-[#FF3366]/10 rounded-2xl flex items-center justify-center mb-4">
                  <Target className="w-8 h-8 text-[#FF3366]" />
                </div>
                <h3 className="text-2xl font-bold mb-2">15+</h3>
                <p className="text-gray-400">Collaborations Réussies</p>
              </div>
              <div className="flex flex-col items-center text-center p-4">
                <div className="w-16 h-16 bg-[#FF3366]/10 rounded-2xl flex items-center justify-center mb-4">
                  <Clock className="w-8 h-8 text-[#FF3366]" />
                </div>
                <h3 className="text-2xl font-bold mb-2">4+</h3>
                <p className="text-gray-400">Années d'Expérience</p>
              </div>
            </div>
          </div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={inView ? { opacity: 1, x: 0 } : { opacity: 0, x: 20 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="relative w-full h-[400px] rounded-2xl overflow-hidden"
          >
            <img
              src="https://images.unsplash.com/photo-1522071820081-009f0129c71c?auto=format&fit=crop&w=1200&q=80"
              alt="Notre équipe au travail"
              className="w-full h-full object-cover rounded-2xl"
            />
            <div className="absolute inset-0 bg-gradient-to-br from-red-500/20 to-transparent rounded-2xl" />
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default About;
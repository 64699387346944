import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { Laptop, Server, Smartphone, Database, RotateCw } from 'lucide-react';

// Technologies data
const technologiesData = [
  {
    category: 'Frontend',
    icon: Laptop,
    color: 'from-blue-500/20',
    description: "Interfaces interactives et captivantes.",
    technologies: [
      { name: 'React.js', image: '/Images/logos/react.svg' },
      { name: 'Next.js', image: '/Images/logos/nextjs.svg' },
      { name: 'Vite.js', image: '/Images/logos/vite.svg' },
      { name: 'Tailwind CSS', image: '/Images/logos/tailwind.svg' },
    ],
  },
  {
    category: 'Backend',
    icon: Server,
    color: 'from-green-500/20',
    description: "Solutions robustes et performantes pour gérer vos données",
    technologies: [
      { name: 'Node.js', image: '/Images/logos/nodejs.svg' },
      { name: 'Express.js', image: '/Images/logos/express.svg' },
      { name: 'MongoDB', image: '/Images/logos/mongodb.svg' },
      { name: 'Firebase', image: '/Images/logos/firebase.svg' },
    ],
  },
  {
    category: 'Mobile',
    icon: Smartphone,
    color: 'from-purple-500/20',
    description: "Applications mobiles rapides et performantes.",
    technologies: [
      { name: 'React Native', image: '/Images/logos/react.svg' },
      { name: 'Expo', image: '/Images/logos/expo.svg' },
      { name: 'Kotlin', image: '/Images/logos/kotlin.svg' },
      { name: 'Swift', image: '/Images/logos/swift.svg' },
    ],
  },
  {
    category: 'Database',
    icon: Database,
    color: 'from-yellow-500/20',
    description: "Des solutions de gestion de données sûres et performantes",
    technologies: [
      { name: 'MongoDB', image: '/Images/logos/mongodb.svg' },
      { name: 'MySQL', image: '/Images/logos/mysql.svg' },
      { name: 'PostgreSQL', image: '/Images/logos/postgresql.svg' },
      { name: 'Firebase', image: '/Images/logos/firebase.svg' },
    ],
  },
];

const Technologies = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <section className="py-20 px-4" id="technologies">
      <div className="max-w-7xl mx-auto">
        <motion.div
          ref={ref}
          initial={{ opacity: 0, y: 20 }}
          animate={inView ? { opacity: 1, y: 0 } : { opacity: 0, y: 20 }}
          transition={{ duration: 0.6 }}
          className="text-center mb-16"
        >
          <h2 className="text-2xl sm:text-4xl font-unbounded font-bold mb-4">
            Nos outils pour concrétiser vos projets
          </h2>
          <p className="text-gray-400 max-w-2xl mx-auto text-sm sm:text-base">
            Nous utilisons les meilleures technologies pour garantir des résultats performants, sécurisés et évolutifs.
          </p>
        </motion.div>

        <motion.div
          variants={containerVariants}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6"
        >
          {technologiesData.map((tech, index) => (
            <motion.div
              key={index}
              variants={itemVariants}
            >
              <TechnologyCard {...tech} />
            </motion.div>
          ))}
        </motion.div>
      </div>
    </section>
  );
};

const TechnologyCard = ({ category, icon: Icon, color, description, technologies }) => {
  const [isFlipped, setIsFlipped] = useState(false);

  return (
    <>
      <div
        className="relative h-[300px] sm:h-[400px] cursor-pointer group perspective"
        onClick={() => setIsFlipped(!isFlipped)}
        onMouseEnter={() => setIsFlipped(true)}
        onMouseLeave={() => setIsFlipped(false)}
      >
        <div
          className={`preserve-3d relative w-full h-full transition-all duration-500 ${
            isFlipped ? '[transform:rotateY(180deg)]' : ''
          }`}
          style={{ transformStyle: 'preserve-3d' }}
        >
          {/* Face avant */}
          <div 
            className="absolute inset-0 w-full h-full backface-hidden"
            style={{ backfaceVisibility: 'hidden' }}
          >
            <div className="w-full h-full bg-black/50 backdrop-blur-lg p-6 sm:p-8 rounded-2xl relative flex flex-col items-center justify-center">
              <div className={`absolute inset-0 bg-gradient-to-br ${color} to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500`} />
              <RotateCw className="absolute top-4 right-4 w-5 h-5 sm:w-6 sm:h-6 text-gray-400 transition-transform duration-300 group-hover:rotate-180" />
              <Icon className={`w-12 h-12 sm:w-16 sm:h-16 mb-4 sm:mb-6 ${
                category === 'Frontend' ? 'text-blue-400' :
                category === 'Backend' ? 'text-green-400' :
                category === 'Mobile' ? 'text-purple-400' :
                'text-yellow-400'
              } opacity-80`} />
              <h3 className="text-xl sm:text-2xl font-bold mb-2 sm:mb-4">{category}</h3>
              <p className="text-gray-400 text-sm sm:text-base text-center">{description}</p>
            </div>
          </div>

          {/* Face arrière */}
          <div 
            className="absolute inset-0 w-full h-full backface-hidden [transform:rotateY(180deg)]"
            style={{ backfaceVisibility: 'hidden' }}
          >
            <div className="w-full h-full bg-black/50 backdrop-blur-lg p-6 sm:p-8 rounded-2xl relative flex flex-col">
              <div className={`absolute inset-0 bg-gradient-to-br ${color} to-transparent rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500`} />
              <RotateCw className="absolute top-4 right-4 w-5 h-5 sm:w-6 sm:h-6 text-gray-400 transition-transform duration-300 group-hover:rotate-180" />
              <h3 className="text-lg sm:text-xl font-bold mb-4 sm:mb-6">{category}</h3>
              <div className="grid grid-cols-2 gap-3 sm:gap-4 flex-1 place-content-center">
                {technologies.map((tech, index) => (
                  <div key={index} className="flex flex-col items-center">
                    <div className="w-10 h-10 sm:w-12 sm:h-12 relative mb-1 sm:mb-2">
                      <img
                        src={tech.image}
                        alt={tech.name}
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <span className="text-xs sm:text-sm text-gray-400">{tech.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{`
        .preserve-3d {
          transform-style: preserve-3d;
        }
        .backface-hidden {
          backface-visibility: hidden;
          -webkit-backface-visibility: hidden;
        }
      `}</style>
    </>
  );
};

export default Technologies;
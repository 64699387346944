import React from 'react';
import { motion } from 'framer-motion';
import { 
  Coins, 
  Rocket, 
  Zap, 
  Layers, 
  HelpCircle,
  MessageCircle,
  Sliders
} from 'lucide-react';
import { FormData } from '../types/form';

type Props = {
  data: FormData;
  onChange: (field: keyof FormData, value: any) => void;
};

export const FormStep5: React.FC<Props> = ({ data, onChange }) => {
  const budgetOptions = [
    { 
      icon: Coins, 
      label: '1-3k €', 
      value: 'small',
      description: 'Projet de démarrage'
    },
    { 
      icon: Rocket, 
      label: '3-5k €', 
      value: 'medium',
      description: 'Projet en croissance'
    },
    { 
      icon: Zap, 
      label: '5-10k €', 
      value: 'large',
      description: 'Projet ambitieux'
    },
    { 
      icon: Layers, 
      label: '10-20k €', 
      value: 'enterprise',
      description: 'Solution d\'entreprise'
    },
    { 
      icon: Sliders, 
      label: 'À définir', 
      value: 'undefined',
      description: 'Budget flexible'
    },
  ];

  const toggleSingleField = (value: string) => {
    // Pour le budget, on ne peut sélectionner qu'une seule option
    onChange('budget', [value]);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-8"
    >
      <div>
        <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
          Étape Budget
        </h2>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
          className="
            bg-black/40 
            backdrop-blur-sm 
            rounded-xl 
            p-4 
            border-2 
            border-transparent 
            hover:border-white/20
            mb-6
          "
        >
          <div className="flex items-start space-x-4">
            <div className="p-2 rounded-lg bg-white/10">
              <Coins className="w-6 h-6 text-white/60" />
            </div>
            <div>
              <h3 className="text-white font-semibold mb-2">
                À propos du budget
              </h3>
              <p className="text-white/70 text-sm mb-3">
                Si vous avez une idée approximative de votre budget, cela nous aidera à personnaliser notre proposition et à vous proposer les meilleures solutions adaptées à vos besoins.
              </p>
              <p className="text-white/60 text-xs italic">
                Pas encore décidé ? → Pas de soucis, vous pouvez passer à l'étape suivante
              </p>
            </div>
          </div>
        </motion.div>

        <div className="grid grid-cols-5 gap-3 mb-6">
          {budgetOptions.map((option) => (
            <motion.div
              key={option.value}
              onClick={() => toggleSingleField(option.value)}
              whileTap={{ scale: 0.95 }}
              className={`
                cursor-pointer 
                rounded-xl 
                p-3 
                transition-all 
                duration-300 
                flex 
                flex-col 
                items-start 
                space-y-2
                ${data.budget?.includes(option.value) 
                  ? 'bg-[#370C15] border-2 border-[#FF3367]' 
                  : 'bg-black/40 backdrop-blur-sm hover:bg-black/50'}
              `}
            >
              <div className={`
                p-2 rounded-lg
                ${data.budget?.includes(option.value) 
                  ? 'bg-[#FF3367]/20' 
                  : 'bg-white/10'}
              `}>
                <option.icon className="w-6 h-6 text-white/60" />
              </div>
              <div>
                <h3 className={`
                  text-sm font-semibold 
                  ${data.budget?.includes(option.value) 
                    ? 'text-white' 
                    : 'text-white/70'}
                `}>
                  {option.label}
                </h3>
                <p className={`
                  text-xs 
                  ${data.budget?.includes(option.value) 
                    ? 'text-white/80' 
                    : 'text-white/50'}
                `}>
                  {option.description}
                </p>
              </div>
            </motion.div>
          ))}
        </div>

        <div>
          <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
            Informations supplémentaires
          </h2>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
            className="flex items-start space-x-4 mb-4"
          >
            <div className="p-2 rounded-lg bg-white/10">
              <MessageCircle className="w-6 h-6 text-white/60" />
            </div>
            <div>
              <p className="text-white/70 text-sm">
                Avez-vous des informations complémentaires à nous communiquer concernant votre projet ? Toute précision nous aidera à mieux comprendre vos besoins.
              </p>
            </div>
          </motion.div>
          
          <textarea
            value={data.additionalInfo}
            onChange={(e) => onChange('additionalInfo', e.target.value)}
            placeholder="Détails supplémentaires, contraintes particulières, délais, etc."
            className="
              w-full 
              h-32 
              p-3 
              bg-black/40 
              backdrop-blur-sm 
              rounded-xl 
              border-2 
              border-transparent 
              hover:border-white/20 
              text-white 
              placeholder-white/50 
              focus:border-[#FF3367] 
              transition-colors
            "
          />
        </div>
      </div>
    </motion.div>
  );
};

import React, { useState, useCallback } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Globe, Smartphone, Code, Layout, Zap, ShoppingCart, Clock, FileText, AlertCircle, CheckCircle, Upload, X, File, Check, AlertTriangle, Paperclip } from 'lucide-react';
import { useDropzone } from 'react-dropzone';
import { FormData } from '../types/form';

const projectTypes = [
  { 
    icon: Globe, 
    label: 'Site Web', 
    value: 'website',
    description: 'Vitrine, e-commerce ou application web'
  },
  { 
    icon: Smartphone, 
    label: 'Application Mobile', 
    value: 'mobile',
    description: 'iOS, Android ou application hybride'
  },
  { 
    icon: Code, 
    label: 'Application Web', 
    value: 'web-app',
    description: 'Application complexe, SaaS, dashboard'
  },
  { 
    icon: Layout, 
    label: 'Design UI/UX', 
    value: 'design',
    description: 'Identité visuelle, maquettes, wireframes'
  },
  { 
    icon: Zap, 
    label: 'Développement Spécifique', 
    value: 'custom',
    description: 'Solution sur-mesure, intégration'
  },
  { 
    icon: ShoppingCart, 
    label: 'E-commerce', 
    value: 'ecommerce',
    description: 'Boutique en ligne, marketplace'
  }
];

const deadlineOptions = [
  { value: '1month', label: '1 mois', description: 'Projet court et urgent' },
  { value: '3months', label: '3 mois', description: 'Projet de taille moyenne' },
  { value: '6months', label: '6 mois', description: 'Projet complexe et détaillé' },
  { value: 'flexible', label: 'Flexible', description: 'Aucune contrainte de temps' }
];

type FormStep1Props = {
  data: FormData;
  onChange: (field: keyof FormData, value: any) => void;
};

export const FormStep1: React.FC<FormStep1Props> = ({ data, onChange }) => {
  const [descriptionLength, setDescriptionLength] = useState(data.projectDescription.length);
  const [descriptionError, setDescriptionError] = useState('');
  const [specificationFiles, setSpecificationFiles] = useState<File[]>([]);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<'idle' | 'uploading' | 'success' | 'error'>('idle');
  const [uploadError, setUploadError] = useState<string | null>(null);

  const handleProjectTypeToggle = (value: string) => {
    const currentTypes = data.projectType || [];
    const newTypes = currentTypes.includes(value)
      ? currentTypes.filter(type => type !== value)
      : [...currentTypes, value];
    
    onChange('projectType', newTypes);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const description = e.target.value;
    onChange('projectDescription', description);
    setDescriptionLength(description.length);

    // Validation
    if (description.length < 50) {
      setDescriptionError('La description est trop courte (minimum 50 caractères)');
    } else if (description.length > 1500) {
      setDescriptionError('La description est trop longue (maximum 1500 caractères)');
    } else {
      setDescriptionError('');
    }
  };

  const onDrop = useCallback((acceptedFiles: File[]) => {
    // Reset previous errors
    setUploadError(null);

    // Check file type and size
    const allowedTypes = [
      'application/pdf', 
      'application/msword', 
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 
      'text/plain'
    ];
    
    const validFiles = acceptedFiles.filter(file => {
      // Check file type
      if (!allowedTypes.includes(file.type)) {
        setUploadError(`Type de fichier non pris en charge pour ${file.name}. Utilisez PDF, DOC, DOCX ou TXT.`);
        return false;
      }

      // Check file size
      if (file.size > 10 * 1024 * 1024) { // 10MB max
        setUploadError(`Le fichier ${file.name} est trop volumineux. Taille maximale : 10 Mo.`);
        return false;
      }

      return true;
    });

    // Limit total number of files
    const newFiles = [...specificationFiles, ...validFiles].slice(0, 5);
    
    // Update state and form data
    setSpecificationFiles(newFiles);
    onChange('specificationFiles', newFiles);

    // Start upload simulation if files are valid
    if (validFiles.length > 0) {
      setUploadStatus('uploading');
      setUploadProgress(0);
      
      // Simulate upload progress
      let progress = 0;
      const uploadInterval = setInterval(() => {
        progress += 10;
        setUploadProgress(progress);
        
        if (progress >= 100) {
          clearInterval(uploadInterval);
          setTimeout(() => {
            setUploadStatus('success');
            
            // Reset after 2 seconds
            setTimeout(() => {
              setUploadStatus('idle');
            }, 2000);
          }, 500);
        }
      }, 100);
    }
  }, [onChange, specificationFiles]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc', '.docx'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': ['.docx'],
      'text/plain': ['.txt']
    },
    maxSize: 10 * 1024 * 1024, // 10MB
    multiple: true
  });

  const removeFile = (fileToRemove: File) => {
    const updatedFiles = specificationFiles.filter(file => file !== fileToRemove);
    setSpecificationFiles(updatedFiles);
    onChange('specificationFiles', updatedFiles);

    // Reset upload status if no files remain
    if (updatedFiles.length === 0) {
      setUploadStatus('idle');
      setUploadError(null);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="space-y-6"
    >
      <div>
        <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
          Type de projet
        </h2>
        <p className="text-sm text-white/60 mb-6">
          Sélectionnez un ou plusieurs types de projet qui correspondent à vos besoins.
        </p>
      </div>

      <div className="grid grid-cols-2 sm:grid-cols-3 gap-4">
        {projectTypes.map((type) => (
          <motion.div
            key={type.value}
            onClick={() => handleProjectTypeToggle(type.value)}
            whileTap={{ scale: 0.95 }}
            className={`
              cursor-pointer 
              rounded-xl 
              p-4 
              transition-all 
              duration-300 
              flex 
              flex-col 
              items-start 
              space-y-3
              ${data.projectType?.includes(type.value) 
                ? 'bg-[#370C15] border-2 border-[#FF3367]' 
                : 'bg-black/40 backdrop-blur-sm hover:bg-black/50'}
            `}
          >
            <div className={`
              p-2 rounded-lg
              ${data.projectType?.includes(type.value) 
                ? 'bg-[#FF3367]/20' 
                : 'bg-white/10'}
            `}>
              <type.icon 
                className={`
                  w-6 h-6
                  ${data.projectType?.includes(type.value) 
                    ? 'text-[#FF3367]' 
                    : 'text-white/60'}
                `} 
              />
            </div>
            <div>
              <h3 className={`
                font-semibold text-sm
                ${data.projectType?.includes(type.value) 
                  ? 'text-white' 
                  : 'text-white/70'}
              `}>
                {type.label}
              </h3>
              <p className={`
                text-xs mt-1
                ${data.projectType?.includes(type.value) 
                  ? 'text-white/70' 
                  : 'text-white/50'}
              `}>
                {type.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>

      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: data.projectType?.length ? 1 : 0 }}
        transition={{ duration: 0.3 }}
        className="mt-6"
      >
        {data.projectType?.length > 0 && (
          <div className="bg-white/5 rounded-xl p-4">
            <h3 className="text-sm font-semibold text-white mb-2">
              Projet(s) sélectionné(s) :
            </h3>
            <p className="text-xs text-white/60">
              {data.projectType.map(type => 
                projectTypes.find(pt => pt.value === type)?.label
              ).join(', ')}
            </p>
          </div>
        )}
      </motion.div>

      <div className="space-y-6 mt-6">
        <div>
          <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
            Description du projet
          </h2>
          <div className="relative">
            <textarea
              value={data.projectDescription}
              onChange={handleDescriptionChange}
              placeholder="Décrivez votre projet en détail..."
              className={`
                w-full 
                min-h-[120px] 
                p-4 
                rounded-xl 
                bg-black/40 
                backdrop-blur-sm
                text-white 
                placeholder-white/50 
                border-2 
                transition-colors 
                duration-300
                ${descriptionError 
                  ? 'border-red-500' 
                  : descriptionLength >= 50 && descriptionLength <= 1500
                    ? 'border-transparent' 
                    : 'border-transparent'}
                focus:outline-none
              `}
            />
            <div className="flex justify-between items-center mt-2">
              <div className="flex items-center space-x-2">
                {descriptionError ? (
                  <AlertCircle className="w-5 h-5 text-red-500" />
                ) : descriptionLength >= 50 && descriptionLength <= 1500 ? (
                  <CheckCircle className="w-5 h-5 text-white/50" />
                ) : null}
                <span className={`
                  text-xs 
                  ${descriptionError 
                    ? 'text-red-500' 
                    : descriptionLength >= 50 && descriptionLength <= 1500
                      ? 'text-white/50' 
                      : 'text-white/50'}
                `}>
                  {descriptionError || `${descriptionLength}/1500 caractères`}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
            Délai souhaité
          </h2>
          <div className="grid grid-cols-2 sm:grid-cols-4 gap-3">
            {deadlineOptions.map((option) => (
              <motion.div
                key={option.value}
                onClick={() => onChange('deadline', option.value)}
                whileTap={{ scale: 0.95 }}
                className={`
                  cursor-pointer 
                  rounded-xl 
                  p-3 
                  transition-all 
                  duration-300 
                  flex 
                  flex-col 
                  items-start 
                  space-y-2
                  ${data.deadline === option.value 
                    ? 'bg-[#370C15] border-2 border-[#FF3367]' 
                    : 'bg-black/40 backdrop-blur-sm hover:bg-black/50'}
                `}
              >
                <div className={`
                  p-2 rounded-lg
                  ${data.deadline === option.value 
                    ? 'bg-[#FF3367]/20' 
                    : 'bg-white/10'}
                `}>
                  <Clock 
                    className={`
                      w-5 h-5
                      ${data.deadline === option.value 
                        ? 'text-[#FF3367]' 
                        : 'text-white/60'}
                    `} 
                  />
                </div>
                <div>
                  <h3 className={`
                    font-semibold text-sm
                    ${data.deadline === option.value 
                      ? 'text-white' 
                      : 'text-white/70'}
                  `}>
                    {option.label}
                  </h3>
                  <p className={`
                    text-xs mt-1
                    ${data.deadline === option.value 
                      ? 'text-white/70' 
                      : 'text-white/50'}
                  `}>
                    {option.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>
        </div>

        <div>
          <h2 className="text-xl sm:text-2xl font-bold text-white mb-4">
            Cahier des charges
          </h2>
          <div className="grid grid-cols-2 gap-3">
            {[
              { value: true, label: 'Oui', icon: FileText, description: 'Un cahier des charges détaillé' },
              { value: false, label: 'Non', icon: FileText, description: 'Pas de cahier des charges' }
            ].map((option) => (
              <motion.div
                key={option.label}
                onClick={() => {
                  onChange('hasSpecifications', option.value);
                  if (!option.value) {
                    setSpecificationFiles([]);
                    onChange('specificationFiles', []);
                  }
                }}
                whileTap={{ scale: 0.95 }}
                className={`
                  cursor-pointer 
                  rounded-xl 
                  p-3 
                  transition-all 
                  duration-300 
                  flex 
                  flex-col 
                  items-start 
                  space-y-2
                  ${data.hasSpecifications === option.value 
                    ? 'bg-[#370C15] border-2 border-[#FF3367]' 
                    : 'bg-black/40 backdrop-blur-sm hover:bg-black/50'}
                `}
              >
                <div className={`
                  p-2 rounded-lg
                  ${data.hasSpecifications === option.value 
                    ? 'bg-[#FF3367]/20' 
                    : 'bg-white/10'}
                `}>
                  <option.icon 
                    className={`
                      w-5 h-5
                      ${data.hasSpecifications === option.value 
                        ? 'text-[#FF3367]' 
                        : 'text-white/60'}
                    `} 
                  />
                </div>
                <div>
                  <h3 className={`
                    font-semibold text-sm
                    ${data.hasSpecifications === option.value 
                      ? 'text-white' 
                      : 'text-white/70'}
                  `}>
                    {option.label}
                  </h3>
                  <p className={`
                    text-xs mt-1
                    ${data.hasSpecifications === option.value 
                      ? 'text-white/70' 
                      : 'text-white/50'}
                  `}>
                    {option.description}
                  </p>
                </div>
              </motion.div>
            ))}
          </div>

          <AnimatePresence>
            {data.hasSpecifications && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: 'auto' }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.3 }}
                className="mt-4"
              >
                <div
                  {...getRootProps()}
                  className={`
                    border-2 border-dashed rounded-xl p-6 text-center cursor-pointer transition-all duration-300 relative overflow-hidden
                    ${isDragActive 
                      ? 'border-[#FF3367] bg-[#FF3367]/10' 
                      : 'border-white/20 hover:border-[#FF3367]/50'}
                    ${specificationFiles.length >= 5 ? 'opacity-50 pointer-events-none' : ''}
                  `}
                >
                  <input {...getInputProps()} />
                  
                  {/* Upload Progress Overlay */}
                  {uploadStatus === 'uploading' && (
                    <motion.div 
                      initial={{ width: 0 }}
                      animate={{ width: '100%' }}
                      className="absolute inset-0 bg-[#FF3367]/20 z-10"
                      style={{ 
                        clipPath: `polygon(0 0, ${uploadProgress}% 0, ${uploadProgress}% 100%, 0 100%)` 
                      }}
                    />
                  )}

                  {specificationFiles.length > 0 ? (
                    <div className="space-y-2">
                      {specificationFiles.map((file, index) => (
                        <motion.div 
                          key={`${file.name}-${index}`}
                          initial={{ opacity: 0, x: -20 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: 20 }}
                          className="flex items-center justify-between bg-black/20 rounded-lg p-2"
                        >
                          <div className="flex items-center space-x-3">
                            <File className="w-6 h-6 text-[#FF3367]" />
                            <div className="text-left">
                              <p className="text-sm font-semibold text-white">{file.name}</p>
                              <p className="text-xs text-white/60">
                                {(file.size / 1024).toFixed(1)} Ko
                              </p>
                            </div>
                          </div>
                          <button 
                            onClick={(e) => {
                              e.stopPropagation();
                              removeFile(file);
                            }}
                            className="text-red-500 hover:text-red-600"
                          >
                            <X className="w-5 h-5" />
                          </button>
                        </motion.div>
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center space-y-3">
                      {uploadStatus === 'success' ? (
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ type: 'spring', stiffness: 300 }}
                        >
                          <Check className="w-10 h-10 text-[#FF3367]" />
                        </motion.div>
                      ) : uploadStatus === 'error' ? (
                        <motion.div
                          initial={{ rotate: 0 }}
                          animate={{ rotate: [0, 10, -10, 0] }}
                          transition={{ duration: 0.3 }}
                        >
                          <AlertTriangle className="w-10 h-10 text-red-500" />
                        </motion.div>
                      ) : (
                        <Upload className="w-10 h-10 text-white/50" />
                      )}
                      
                      <p className="text-sm text-white/70">
                        {uploadStatus === 'success' 
                          ? 'Fichiers téléchargés' 
                          : uploadStatus === 'error'
                            ? 'Erreur de téléchargement' 
                            : isDragActive 
                              ? 'Déposez les fichiers ici' 
                              : 'Glissez et déposez vos cahiers des charges ou cliquez pour sélectionner'}
                      </p>
                      
                      {uploadError && (
                        <motion.p 
                          initial={{ opacity: 0, y: -10 }}
                          animate={{ opacity: 1, y: 0 }}
                          className="text-xs text-red-500 mt-2 text-center"
                        >
                          {uploadError}
                        </motion.p>
                      )}
                      
                      <p className="text-xs text-white/50">
                        PDF, DOC, DOCX, TXT (max 10 Mo, max 5 fichiers)
                      </p>
                    </div>
                  )}
                </div>
                {data.hasSpecifications && specificationFiles.length === 0 && (
                  <p className="text-xs text-white/50 mt-2 text-center">
                    Si vous cliquez sur "Oui", vous devez téléverser un cahier des charges pour poursuivre le formulaire. 
                    Sinon, répondez "Non".
                  </p>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </motion.div>
  );
};

import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Send, Mic, ClipboardList, Euro, Clock, Phone, User, Bot } from 'lucide-react';
import { Helmet } from 'react-helmet';
import SEO from '../components/SEO/SEO';
import { pageSEOConfigs } from '../config/seo.config';
import { getChatCompletion, getInitialMessage, getCurrentProjectInfo } from '../services/openai';
import { sendProjectEmails } from '../services/brevo';
import { useNavigate, useLocation } from 'react-router-dom';
import BetaInfoModal from '../components/BetaInfoModal';

interface Message {
  content: string;
  role: 'user' | 'assistant';
  timestamp?: Date;
  isForm?: boolean;
  formState?: FormFields;
}

interface FormFields {
  prenom: string;
  telephone: string;
  email: string;
}

interface FormErrors {
  prenom?: string;
  telephone?: string;
  email?: string;
}

const DevisChat: React.FC = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputMessage, setInputMessage] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [isTyping, setIsTyping] = useState(false);
  const [formFields, setFormFields] = useState<FormFields>({
    prenom: '',
    telephone: '',
    email: ''
  });
  const [formErrors, setFormErrors] = useState<FormErrors>({});
  const [isValidating, setIsValidating] = useState(false);
  const [isTransforming, setIsTransforming] = useState(false);
  const [hasFormBeenSubmitted, setHasFormBeenSubmitted] = useState(false);
  const [showEndChat, setShowEndChat] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const [isCountdownActive, setIsCountdownActive] = useState(false);
  const [showFormAlert, setShowFormAlert] = useState(false);
  const [isFormShaking, setIsFormShaking] = useState(false);
  const [hasStartedChat, setHasStartedChat] = useState(false);
  const [showExitConfirmation, setShowExitConfirmation] = useState(false);
  const [intendedPath, setIntendedPath] = useState<string | null>(null);
  const [showBetaInfo, setShowBetaInfo] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Fonction pour vérifier si le message demande des informations personnelles
  const isAskingForInfo = (content: string): boolean => {
    const triggers = [
      'informations suivantes',
      'pourriez-vous me fournir',
      'préciser',
      'votre prénom',
      'numéro de téléphone',
      'adresse email'
    ];
    return triggers.some(trigger => content.toLowerCase().includes(trigger.toLowerCase()));
  };

  // Fonction pour créer automatiquement la bulle de réponse avec le formulaire
  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    
    if (!lastMessage || !lastMessage.content) return;

    if (lastMessage.role === 'assistant' && 
        isAskingForInfo(lastMessage.content) && 
        !hasFormBeenSubmitted) {
      
      // Vérifier si un formulaire existe déjà
      const formExists = messages.some(msg => msg.isForm);
      if (formExists) return;

      // Supprimer tous les formulaires existants et ajouter le nouveau
      setMessages(prev => {
        const messagesWithoutForms = prev.filter(msg => !msg.isForm);
        return [...messagesWithoutForms, {
          role: 'user',
          isForm: true,
          formState: {
            prenom: '',
            telephone: '',
            email: ''
          },
          timestamp: new Date()
        }];
      });
    }
  }, [messages, hasFormBeenSubmitted]);

  useEffect(() => {
    scrollToBottom();
  }, [messages, isExpanded]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];
    if (lastMessage?.role === 'assistant' && 
        lastMessage.content.includes('Merci de nous avoir choisis')) {
      setShowEndChat(true);
    }
  }, [messages]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isCountdownActive && countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    } else if (countdown === 0) {
      handleEndChat();
    }
    return () => clearTimeout(timer);
  }, [countdown, isCountdownActive]);

  useEffect(() => {
    // Vérifier si c'est la première visite
    const hasSeenBetaInfo = localStorage.getItem('hasSeenBetaInfo');
    if (!hasSeenBetaInfo) {
      setShowBetaInfo(true);
    }
  }, []);

  const handleCloseBetaInfo = () => {
    setShowBetaInfo(false);
    localStorage.setItem('hasSeenBetaInfo', 'true');
  };

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      const chatContainer = messagesEndRef.current.parentElement;
      if (chatContainer) {
        chatContainer.scrollTop = chatContainer.scrollHeight;
      }
    }
  };

  const handleSendMessage = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Vérifier si un formulaire est présent et non soumis
    const hasUnsubmittedForm = messages.some(msg => msg.isForm && !hasFormBeenSubmitted);
    
    if (hasUnsubmittedForm) {
      setIsFormShaking(true);
      setShowFormAlert(true);
      setTimeout(() => setIsFormShaking(false), 650);
      setTimeout(() => setShowFormAlert(false), 3000);
      return;
    }

    if (!inputMessage.trim()) return;

    let messageToSend = inputMessage;
    
    // Si des champs sont remplis, les ajouter au message
    if (formFields.prenom || formFields.telephone || formFields.email) {
      const filledFields = [];
      if (formFields.prenom) filledFields.push(`Prénom: ${formFields.prenom}`);
      if (formFields.telephone) filledFields.push(`Téléphone: ${formFields.telephone}`);
      if (formFields.email) filledFields.push(`Email: ${formFields.email}`);
      
      messageToSend = filledFields.join('\n');
      
      // Réinitialiser les champs après envoi
      setFormFields({
        prenom: '',
        telephone: '',
        email: ''
      });
    }

    if (!isExpanded) {
      setIsExpanded(true);
    }

    // Add user message
    const userMessage: Message = {
      content: messageToSend,
      role: 'user',
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setInputMessage('');
    setIsTyping(true);

    try {
      // Get response from OpenAI
      const response = await getChatCompletion(messageToSend);
      
      const aiMessage: Message = {
        content: response,
        role: 'assistant',
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, aiMessage]);
      scrollToBottom();
    } catch (error) {
      console.error('Error getting AI response:', error);
      // Add error message to chat
      const errorMessage: Message = {
        content: "Désolé, je rencontre des difficultés techniques. Veuillez réessayer plus tard.",
        role: 'assistant',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleSuggestionClick = async (text: string) => {
    setIsExpanded(true);
    
    const userMessage: Message = {
      content: text,
      role: 'user',
      timestamp: new Date()
    };

    setMessages(prev => [...prev, userMessage]);
    setIsTyping(true);

    try {
      const response = await getChatCompletion(text);
      
      const aiMessage: Message = {
        content: response,
        role: 'assistant',
        timestamp: new Date()
      };
      
      setMessages(prev => [...prev, aiMessage]);
      scrollToBottom();
    } catch (error) {
      console.error('Error getting AI response:', error);
      const errorMessage: Message = {
        content: "Désolé, je rencontre des difficultés techniques. Veuillez réessayer plus tard.",
        role: 'assistant',
        timestamp: new Date()
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setIsTyping(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage(e);
    }
  };

  const features = [
    { icon: ClipboardList, text: "Demander un devis" },
    { icon: Euro, text: "Combien coûte un projet" },
    { icon: Clock, text: "Quelle durée pour un projet" },
    { icon: Phone, text: "Je souhaite être recontacté" },
  ];

  // Fonction utilitaire pour formater le contenu des messages
  const formatMessageContent = (content: string) => {
    return content.split('\n').map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const validateField = (name: string, value: string): string => {
    switch (name) {
      case 'prenom':
        return value.length < 2 ? 'Le prénom doit contenir au moins 2 caractères' : '';
      case 'telephone':
        return !/^[0-9]{10}$/.test(value) ? 'Le numéro de téléphone doit contenir 10 chiffres' : '';
      case 'email':
        return !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) ? 'Veuillez entrer une adresse email valide' : '';
      default:
        return '';
    }
  };

  const handleFieldChange = (name: keyof FormFields, value: string) => {
    setFormFields(prev => ({
      ...prev,
      [name]: value
    }));

    const error = validateField(name, value);
    setFormErrors(prev => ({
      ...prev,
      [name]: error
    }));
  };

  const isFormValid = () => {
    return Object.keys(formFields).every(key => {
      const fieldName = key as keyof FormFields;
      return formFields[fieldName].length > 0 && !formErrors[fieldName];
    });
  };

  const handleSubmit = (e: React.FormEvent | KeyboardEvent) => {
    e.preventDefault();
    if (isFormValid()) {
      handleAutoSendForm();
    }
  };

  const handleAutoSendForm = async () => {
    // Vérifier si on est déjà en train de traiter une soumission
    if (isTransforming || isTyping) {
      return;
    }

    setIsTransforming(true);
    setHasFormBeenSubmitted(true);
    
    // Attendre que l'animation de transformation soit terminée
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // Formater les informations avec des points et des retours à la ligne
    const messageToSend = [
      `• Prénom: ${formFields.prenom}`,
      `• Téléphone: ${formFields.telephone}`,
      `• Email: ${formFields.email}`
    ].join('\n');

    // Supprimer les doublons et ajouter le nouveau message
    setMessages(prev => {
      // Filtrer les messages pour enlever les formulaires et les doublons potentiels
      const uniqueMessages = prev
        .filter(msg => !msg.isForm)
        .filter((msg, index, self) => 
          index === self.findIndex((m) => 
            m.content === msg.content && m.role === msg.role
          )
        );

      return [...uniqueMessages, {
        content: messageToSend,
        role: 'user',
        timestamp: new Date()
      }];
    });

    setIsTyping(true);
    setIsTransforming(false);

    try {
      const response = await getChatCompletion(messageToSend);
      
      // Ajouter la réponse de l'assistant
      setMessages(prev => {
        // Vérifier si cette réponse n'existe pas déjà
        const responseExists = prev.some(msg => 
          msg.role === 'assistant' && msg.content === response
        );
        
        if (responseExists) {
          return prev;
        }

        return [...prev, {
          content: response,
          role: 'assistant',
          timestamp: new Date()
        }];
      });
    } catch (error) {
      console.error('Error getting AI response:', error);
      setMessages(prev => [...prev, {
        content: "Désolé, je rencontre des difficultés techniques. Veuillez réessayer plus tard.",
        role: 'assistant',
        timestamp: new Date()
      }]);
    } finally {
      setIsTyping(false);
      setFormFields({
        prenom: '',
        telephone: '',
        email: ''
      });
      setFormErrors({});
    }
  };

  const renderMessage = (message: Message) => {
    if (message.isForm) {
      return (
        <motion.div 
          className="space-y-3"
          initial={{ opacity: 1 }}
          animate={isTransforming ? { opacity: 0 } : { opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <style jsx>{`
            .submit-button {
              width: 100%;
              padding: 12px;
              margin-top: 16px;
              background-color: #E91E63;
              color: white;
              border: none;
              border-radius: 12px;
              font-weight: 500;
              transition: all 0.2s ease;
            }

            .submit-button:hover:not(:disabled) {
              background-color: #D81B60;
            }

            .submit-button:disabled {
              background-color: #ccc;
              cursor: not-allowed;
            }

            .form-container {
              display: flex;
              flex-direction: column;
              gap: 16px;
            }

            .form-field {
              display: flex;
              flex-direction: column;
            }

            .error-message {
              color: #E91E63;
              font-size: 12px;
              margin-top: 4px;
            }
          `}</style>
          <div className="flex flex-col space-y-4">
            <div className={`flex flex-col space-y-4 ${isFormShaking ? 'shake-animation' : ''}`}>
              <div className="flex items-center justify-between gap-4">
                <span className="whitespace-nowrap">• Votre prénom</span>
                <input
                  type="text"
                  value={formFields.prenom}
                  onChange={(e) => handleFieldChange('prenom', e.target.value)}
                  className={`w-64 px-4 py-3 bg-white/10 backdrop-blur-md rounded-xl border ${
                    formErrors.prenom ? 'border-red-500' : 'border-white/20'
                  } text-white focus:outline-none focus:border-[#FF3366]/50 transition-colors`}
                  placeholder="Votre prénom"
                />
              </div>
              {formErrors.prenom && (
                <div className="text-red-500 text-sm text-right">{formErrors.prenom}</div>
              )}

              <div className="flex items-center justify-between gap-4">
                <span className="whitespace-nowrap">• Votre numéro</span>
                <input
                  type="tel"
                  value={formFields.telephone}
                  onChange={(e) => handleFieldChange('telephone', e.target.value)}
                  className={`w-64 px-4 py-3 bg-white/10 backdrop-blur-md rounded-xl border ${
                    formErrors.telephone ? 'border-red-500' : 'border-white/20'
                  } text-white focus:outline-none focus:border-[#FF3366]/50 transition-colors`}
                  placeholder="Votre téléphone"
                />
              </div>
              {formErrors.telephone && (
                <div className="text-red-500 text-sm text-right">{formErrors.telephone}</div>
              )}

              <div className="flex items-center justify-between gap-4">
                <span className="whitespace-nowrap">• Votre email</span>
                <input
                  type="email"
                  value={formFields.email}
                  onChange={(e) => handleFieldChange('email', e.target.value)}
                  className={`w-64 px-4 py-3 bg-white/10 backdrop-blur-md rounded-xl border ${
                    formErrors.email ? 'border-red-500' : 'border-white/20'
                  } text-white focus:outline-none focus:border-[#FF3366]/50 transition-colors`}
                  placeholder="Votre email"
                />
              </div>
              {formErrors.email && (
                <div className="text-red-500 text-sm text-right">{formErrors.email}</div>
              )}
            </div>
            <button 
              className="submit-button"
              onClick={handleSubmit}
              disabled={!isFormValid()}
            >
              Envoyer
            </button>
          </div>
        </motion.div>
      );
    }

    return (
      <motion.div 
        className="whitespace-pre-wrap"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        {formatMessageContent(message.content)}
      </motion.div>
    );
  };

  // Reset le formulaire quand on commence une nouvelle conversation
  useEffect(() => {
    if (messages.length === 0) {
      setHasFormBeenSubmitted(false);
      setFormFields({
        prenom: '',
        telephone: '',
        email: ''
      });
      setFormErrors({});
    }
  }, [messages]);

  const startCountdown = () => {
    setIsCountdownActive(true);
  };

  const cancelCountdown = () => {
    setIsCountdownActive(false);
    setCountdown(10);
    setShowEndChat(false);
    // Réinitialiser la conversation
    setMessages([]);
    setHasFormBeenSubmitted(false);
  };

  // Fonction pour vérifier si c'est un message de fin
  const isEndMessage = (content: string): boolean => {
    const endPhrases = [
      'excellente journée',
      'à bientôt',
      'nous réjouissons de travailler avec vous',
      'nous vous souhaitons',
      'si vous avez d\'autres questions'
    ];
    return endPhrases.some(phrase => 
      content.toLowerCase().includes(phrase.toLowerCase())
    );
  };

  // Détecter la fin de la conversation
  useEffect(() => {
    const projectInfo = getCurrentProjectInfo();
    const lastMessage = messages[messages.length - 1];
    
    // Afficher le bouton si le statut est completed ou si on détecte un message de fin
    if (projectInfo.status === 'completed' || 
        (lastMessage?.role === 'assistant' && isEndMessage(lastMessage.content))) {
      console.log('Showing end chat button. Status:', projectInfo.status);
      setTimeout(() => {
        setShowEndChat(true);
      }, 1000);
    }
  }, [messages]);

  const handleEndChat = async () => {
    try {
      const projectInfo = getCurrentProjectInfo();
      
      console.log('Handling end chat. Project info:', JSON.stringify(projectInfo, null, 2));

      if (!projectInfo.email || !projectInfo.name) {
        console.error('Missing required project info:', { email: projectInfo.email, name: projectInfo.name });
        return;
      }

      // Récupérer le résumé fait par l'assistant
      const summaryMessage = messages.find(msg => 
        msg.role === 'assistant' && 
        msg.content.includes('Voici un récapitulatif de votre demande')
      );

      if (summaryMessage) {
        // Parser le contenu du résumé pour extraire les informations
        const content = summaryMessage.content;
        const lines = content.split('\n');
        const projectDetails: any = {};

        lines.forEach(line => {
          if (line.includes('•')) {
            const [key, value] = line.split(':').map(s => s.trim());
            const cleanKey = key.replace('•', '').trim();
            if (value) {
              projectDetails[cleanKey] = value;
            }
          }
        });

        // Mettre à jour projectInfo avec les informations du résumé
        projectInfo.name = projectDetails['Prénom'] || projectInfo.name;
        projectInfo.phone = projectDetails['Téléphone'] || projectInfo.phone;
        projectInfo.email = projectDetails['Email'] || projectInfo.email;
        projectInfo.projectType = projectDetails['Description du projet'] || projectInfo.projectType;
        projectInfo.description = `
Type de projet : ${projectDetails['Description du projet'] || 'Non spécifié'}
Spécifications techniques : ${projectDetails['Spécifications techniques'] || 'Non spécifié'}
Design/Charte graphique : ${projectDetails['Design/Charte graphique'] || 'Non spécifié'}
Besoins spécifiques : ${projectDetails['Besoins spécifiques'] || 'Non spécifié'}
Délai souhaité : ${projectDetails['Délai souhaité'] || 'Non spécifié'}
Budget : ${projectDetails['Budget'] || 'Non spécifié'}
Remarques : ${projectDetails['Remarques supplémentaires'] || 'Aucune'}`;
      }

      // Filtrer l'historique du chat pour exclure les messages de formulaire
      const chatHistory = messages
        .filter(msg => !msg.isForm)
        .map(msg => {
          const role = msg.role === 'user' ? 'Client' : 'Assistant';
          const timestamp = msg.timestamp ? 
            new Intl.DateTimeFormat('fr-FR', {
              hour: '2-digit',
              minute: '2-digit',
              second: '2-digit'
            }).format(msg.timestamp) : '';
          return `[${timestamp}] ${role}:\n${msg.content}\n`;
        })
        .join('\n');

      console.log('Sending emails with data:', {
        projectInfo,
        chatHistoryLength: chatHistory.length
      });
      
      await sendProjectEmails(projectInfo, chatHistory);
      
      console.log('Emails sent successfully');

      // Rediriger vers la page d'accueil après un court délai
      setTimeout(() => {
        navigate('/');
      }, 1000);

    } catch (error) {
      console.error('Error in handleEndChat:', error);
      if (error instanceof Error) {
        console.error('Error details:', error.message);
      }
    } finally {
      setIsCountdownActive(false);
      setCountdown(10);
    }
  };

  const handleNavigation = (path: string) => {
    if (hasStartedChat && messages.length > 0) {
      setIntendedPath(path);
      setShowExitConfirmation(true);
    } else {
      navigate(path);
    }
  };

  const handleConfirmExit = () => {
    setShowExitConfirmation(false);
    if (intendedPath) {
      navigate(intendedPath);
    }
  };

  const handleCancelExit = () => {
    setShowExitConfirmation(false);
    setIntendedPath(null);
  };

  const handleBeforeUnload = (e: BeforeUnloadEvent) => {
    if (hasStartedChat && messages.length > 0) {
      e.preventDefault();
      e.returnValue = '';
      return '';
    }
  };

  useEffect(() => {
    if (hasStartedChat) {
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }
  }, [hasStartedChat, messages.length]);

  useEffect(() => {
    if (messages.length > 0 && !hasStartedChat) {
      setHasStartedChat(true);
    }
  }, [messages]);

  const renderEndChatButton = () => {
    if (!showEndChat) return null;

    return (
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="end-chat-container"
      >
        <button 
          className="end-chat-button"
          onClick={startCountdown}
        >
          Terminer la conversation
        </button>
        {isCountdownActive && (
          <div className="countdown-container">
            <div className="countdown">
              Redirection automatique dans {countdown} secondes...
            </div>
            <button 
              className="cancel-button"
              onClick={cancelCountdown}
            >
              Annuler et continuer la conversation
            </button>
          </div>
        )}
      </motion.div>
    );
  };

  return (
    <>
      <BetaInfoModal isOpen={showBetaInfo} onClose={handleCloseBetaInfo} />
      <SEO 
        title="Devis en ligne avec IA | IziCode - Assistance par chat"
        description="Obtenez un devis personnalisé pour votre projet digital grâce à notre assistant virtuel intelligent. Estimation rapide et précise pour vos besoins en développement web, mobile et design."
        ogType="website"
        canonical="https://izicode.fr/devis-chat"
        keywords={[
          "devis en ligne",
          "assistant virtuel",
          "chat IA",
          "estimation projet",
          "développement web",
          "développement mobile",
          "design",
          "devis personnalisé"
        ]}
      />
      <Helmet>
        <title>Devis en ligne avec IA | IziCode - Assistance par chat</title>
        <meta name="description" content="Obtenez un devis personnalisé pour votre projet digital grâce à notre assistant virtuel intelligent. Estimation rapide et précise pour vos besoins en développement web, mobile et design." />
      </Helmet>

      <div className="relative min-h-screen flex items-center justify-center overflow-hidden">
        {/* Fond avec dégradé */}
        <div className="absolute inset-0 bg-gradient-to-b from-[#FF3366]/20 via-[#FF3366]/10 to-transparent z-0" />
        
        {/* Motif de points avec masque de dégradé */}
        <div 
          className="absolute inset-0 z-0 opacity-40"
          style={{
            backgroundImage: `radial-gradient(circle at 1px 1px, rgb(75, 85, 99) 1px, transparent 0)`,
            backgroundSize: '40px 40px',
            maskImage: 'linear-gradient(to bottom, transparent, black 10%, black)',
            WebkitMaskImage: 'linear-gradient(to bottom, transparent, black 10%, black)'
          }}
        />

        <div className="relative z-10 w-full max-w-[90%] sm:max-w-2xl md:max-w-4xl lg:max-w-5xl mx-auto">
          {!isExpanded ? (
            <motion.div 
              className="text-center space-y-8"
              initial={{ opacity: 1 }}
              exit={{ opacity: 0 }}
            >
              <h1 className="font-unbounded text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-center mb-4 sm:mb-6 md:mb-8 text-white">
                Créez votre devis personnalisé
              </h1>
              <p className="text-gray-400 text-sm sm:text-base md:text-lg max-w-2xl mx-auto">
                Notre assistant IA vous guide étape par étape pour créer un devis adapté à votre projet. Posez vos questions et obtenez des réponses instantanées.
              </p>
              <div className="bg-black/20 backdrop-blur-sm rounded-xl sm:rounded-2xl p-4 sm:p-6 md:p-8 shadow-xl">
                <input
                  type="text"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder="Message à l'assistant izicode"
                  className="w-full bg-transparent text-white text-sm sm:text-base px-2 py-2 focus:outline-none placeholder-gray-500"
                />
                <div className="flex items-center justify-between mt-4 pt-4 border-t border-white/10">
                  <div className="flex gap-4">
                    <button className="text-gray-400 hover:text-white transition-colors">
                      <Mic className="w-5 h-5" />
                    </button>
                  </div>
                  <button
                    type="submit"
                    className="text-gray-400 hover:text-white transition-colors"
                    onClick={handleSendMessage}
                  >
                    <Send className="w-5 h-5" />
                  </button>
                </div>
              </div>
              <div className="flex flex-wrap justify-center gap-4 mt-8">
                {features.map((feature, index) => (
                  <motion.button
                    key={index}
                    onClick={() => handleSuggestionClick(feature.text)}
                    className="flex items-center gap-2 px-4 py-2 rounded-full bg-black/40 backdrop-blur-sm hover:bg-white/5 transition-colors"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <feature.icon className="w-5 h-5" />
                    <span>{feature.text}</span>
                  </motion.button>
                ))}
              </div>
            </motion.div>
          ) : (
            <motion.div 
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="flex flex-col items-center justify-center h-[calc(100vh-6rem)] sm:h-[calc(100vh-8rem)]"
            >
              <motion.div 
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                transition={{ duration: 0.5 }}
                className="w-full max-w-4xl mx-auto flex flex-col mt-32 sm:mt-36 mb-4 sm:mb-8"
                style={{
                  minHeight: 'calc(100vh - 12rem)',
                  maxHeight: 'calc(100vh - 10rem)',
                }}
              >
                <div 
                  className="flex-1 overflow-y-auto px-4 sm:px-6 md:px-8 space-y-6 sm:space-y-8 custom-scrollbar"
                  ref={chatContainerRef}
                >
                  <style jsx>{`
                    .custom-scrollbar::-webkit-scrollbar {
                      width: 3px !important;
                    }
                    .custom-scrollbar::-webkit-scrollbar-track {
                      background: transparent !important;
                      border: none !important;
                      box-shadow: none !important;
                    }
                    .custom-scrollbar::-webkit-scrollbar-thumb {
                      background: rgba(255, 255, 255, 0.08) !important;
                      border-radius: 3px !important;
                      border: none !important;
                    }
                    .custom-scrollbar {
                      scrollbar-width: thin;
                      scrollbar-color: rgba(255, 255, 255, 0.08) transparent;
                    }
                  `}</style>
                  
                  {messages.map((message, index) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5 }}
                      className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'} mb-4 items-end gap-2`}
                    >
                      {message.role === 'assistant' && (
                        <div className="w-8 h-8 rounded-full bg-[#FF3366] flex items-center justify-center">
                          <Bot className="w-5 h-5 text-white" />
                        </div>
                      )}
                      <div
                        className={`max-w-[80%] p-4 ${
                          message.role === 'user'
                            ? 'bg-[#FF3366]/30 backdrop-blur-md border border-[#FF3366]/40 text-white rounded-t-2xl rounded-bl-2xl rounded-br-lg'
                            : 'bg-white/10 backdrop-blur-sm text-white rounded-t-2xl rounded-br-2xl rounded-bl-lg'
                        }`}
                      >
                        {renderMessage(message)}
                        {message.timestamp && (
                          <div className="text-xs mt-2 opacity-60">
                            {message.timestamp.toLocaleTimeString()}
                          </div>
                        )}
                      </div>
                      {message.role === 'user' && (
                        <div className="w-8 h-8 rounded-full bg-white/10 backdrop-blur-md border border-white/20 flex items-center justify-center">
                          <User className="w-5 h-5 text-white" />
                        </div>
                      )}
                    </motion.div>
                  ))}
                  {isTyping && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      className="flex items-center gap-2 text-white/50"
                    >
                      <div className="flex gap-1">
                        <motion.div
                          animate={{
                            scale: [1, 1.2, 1],
                            opacity: [0.5, 1, 0.5]
                          }}
                          transition={{
                            duration: 1,
                            repeat: Infinity,
                            delay: 0
                          }}
                          className="w-2 h-2 bg-white/50 rounded-full"
                        />
                        <motion.div
                          animate={{
                            scale: [1, 1.2, 1],
                            opacity: [0.5, 1, 0.5]
                          }}
                          transition={{
                            duration: 1,
                            repeat: Infinity,
                            delay: 0.2
                          }}
                          className="w-2 h-2 bg-white/50 rounded-full"
                        />
                        <motion.div
                          animate={{
                            scale: [1, 1.2, 1],
                            opacity: [0.5, 1, 0.5]
                          }}
                          transition={{
                            duration: 1,
                            repeat: Infinity,
                            delay: 0.4
                          }}
                          className="w-2 h-2 bg-white/50 rounded-full"
                        />
                      </div>
                      <span className="text-sm">
                        {isValidating ? "Validation en cours..." : "En train d'écrire..."}
                      </span>
                    </motion.div>
                  )}
                  {renderEndChatButton()}
                  <div ref={messagesEndRef} />
                </div>

                <motion.form
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  onSubmit={handleSendMessage}
                  className="sticky bottom-0 px-4 sm:px-6 py-4"
                >
                  <div className="relative flex items-center gap-2 sm:gap-3 bg-[#1C1C1E] rounded-2xl p-4">
                    {showFormAlert && (
                      <motion.div 
                        className="absolute left-0 right-0 mx-auto bottom-full mb-4 w-fit"
                        initial={{ opacity: 0, y: 10 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 10 }}
                      >
                        <div className="bg-[#E91E63] backdrop-blur-md px-4 py-3 rounded-xl flex items-center gap-2 text-white shadow-lg">
                          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          Veuillez remplir vos informations avant de continuer
                        </div>
                      </motion.div>
                    )}
                    <button
                      type="button"
                      className="p-2.5 hover:bg-white/5 rounded-xl transition-colors group"
                    >
                      <Mic className="w-5 h-5 text-gray-400 group-hover:text-[#FF3366]" />
                    </button>
                    <input
                      type="text"
                      value={inputMessage}
                      onChange={(e) => setInputMessage(e.target.value)}
                      onKeyDown={handleKeyDown}
                      placeholder="Message à l'assistant izicode"
                      className="flex-grow bg-transparent text-white text-sm sm:text-base px-2 py-2 focus:outline-none placeholder-gray-500"
                    />
                    <button
                      type="submit"
                      className="text-gray-400 hover:text-white transition-colors"
                      onClick={handleSendMessage}
                    >
                      <Send className="w-5 h-5" />
                    </button>
                  </div>
                </motion.form>
              </motion.div>
            </motion.div>
          )}
        </div>
      </div>
      {showExitConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full mx-4">
            <h2 className="text-xl font-semibold mb-4">Êtes-vous sûr de vouloir quitter ?</h2>
            <p className="text-gray-600 mb-6">
              Vous êtes en pleine conversation. Si vous quittez maintenant, votre progression ne sera pas sauvegardée.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleCancelExit}
                className="px-4 py-2 text-gray-600 hover:text-gray-800"
              >
                Annuler
              </button>
              <button
                onClick={handleConfirmExit}
                className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
              >
                Quitter quand même
              </button>
            </div>
          </div>
        </div>
      )}
      <style jsx>{`
        .end-chat-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 20px auto;
          padding: 20px;
          max-width: 400px;
          gap: 15px;
        }
        .end-chat-button {
          background-color: #E91E63;
          color: white;
          padding: 12px 24px;
          border-radius: 4px;
          border: none;
          font-weight: 500;
          font-size: 16px;
          cursor: pointer;
          transition: all 0.2s ease;
          width: 100%;
        }
        .end-chat-button:hover {
          background-color: #D81B60;
          transform: translateY(-1px);
        }
        .countdown-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 12px;
          width: 100%;
        }
        .countdown {
          color: #666;
          font-size: 14px;
          text-align: center;
        }
        .cancel-button {
          background-color: transparent;
          color: #E91E63;
          border: 1px solid #E91E63;
          padding: 8px 16px;
          border-radius: 4px;
          cursor: pointer;
          transition: all 0.2s;
          font-size: 14px;
          width: 100%;
        }
        .cancel-button:hover {
          background-color: rgba(233, 30, 99, 0.1);
        }
        @keyframes shake {
          0%, 100% { transform: translateX(0); }
          20%, 60% { transform: translateX(-5px); }
          40%, 80% { transform: translateX(5px); }
        }
        
        .shake-animation {
          animation: shake 0.65s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
        }

        .form-alert {
          position: absolute;
          left: 0;
          right: 0;
          bottom: 100%;
          margin: 0 auto 1rem;
          width: fit-content;
          background: #E91E63;
          backdrop-filter: blur(8px);
          padding: 0.75rem 1rem;
          border-radius: 0.75rem;
          color: white;
          font-size: 14px;
          z-index: 50;
          box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
          display: flex;
          align-items: center;
          gap: 0.5rem;
        }
      `}</style>
    </>
  );
};

export default DevisChat;

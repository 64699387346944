// Firebase configuration and initialization
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { 
  getFirestore, 
  collection, 
  addDoc, 
  getDocs, 
  deleteDoc, 
  doc, 
  updateDoc, 
  connectFirestoreEmulator,
  query,
  orderBy,
  limit,
  where
} from 'firebase/firestore';
import { getStorage, connectStorageEmulator } from 'firebase/storage';
import { getAuth, connectAuthEmulator } from 'firebase/auth';

const getEnvVar = (key: string): string => {
  const value = import.meta.env[key] || process.env[key];
  if (!value) {
    console.error(`Missing environment variable: ${key}`);
    throw new Error(`Missing required environment variable: ${key}`);
  }
  return value;
};

const firebaseConfig = {
  apiKey: getEnvVar('VITE_FIREBASE_API_KEY'),
  authDomain: getEnvVar('VITE_FIREBASE_AUTH_DOMAIN'),
  projectId: getEnvVar('VITE_FIREBASE_PROJECT_ID'),
  storageBucket: getEnvVar('VITE_FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: getEnvVar('VITE_FIREBASE_MESSAGING_SENDER_ID'),
  appId: getEnvVar('VITE_FIREBASE_APP_ID'),
  measurementId: getEnvVar('VITE_FIREBASE_MEASUREMENT_ID')
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = typeof window !== 'undefined' ? getAnalytics(app) : null;
export const functions = getFunctions(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const auth = getAuth(app);

// Emulator configuration for local development only
const useEmulator = false; // Set this to true to use emulators

if (useEmulator && import.meta.env.DEV) {
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectStorageEmulator(storage, 'localhost', 9199);
  connectAuthEmulator(auth, 'http://localhost:9099');
}

// Collection references
export const blogPostsRef = collection(db, 'blog-posts');
export const projectsRef = collection(db, 'projects');

// Type definitions
export interface BlogPost {
  id?: string;
  title: string;
  content: string;
  excerpt: string;
  author: string;
  date: string;
  readTime: string;
  category: string;
  image: string;
}

export interface Project {
  id?: string;
  title: string;
  description: string;
  image: string;
  technologies: string[];
  github?: string;
  demo?: string;
  category: string;
  featured: boolean;
}

// Utility function to normalize slugs
export const normalizeSlug = (title: string): string => {
  return title
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
    .replace(/[^a-z0-9]+/g, '-')     // Replace non-alphanumeric with hyphens
    .replace(/(^-|-$)/g, '');        // Remove leading/trailing hyphens
};

// Firebase operations for blog posts
export const addBlogPost = async (post: Omit<BlogPost, 'id'>) => {
  try {
    const docRef = await addDoc(blogPostsRef, post);
    return { ...post, id: docRef.id };
  } catch (error) {
    console.error("Error adding blog post:", error);
    throw error;
  }
};

export const getBlogPosts = async (): Promise<BlogPost[]> => {
  try {
    const q = query(blogPostsRef, orderBy('date', 'desc'));
    const snapshot = await getDocs(q);
    const posts = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as BlogPost[];
    return posts;
  } catch (error) {
    console.error("Error getting blog posts:", error);
    throw error;
  }
};

export const getBlogPost = async (slug: string): Promise<BlogPost | null> => {
  try {
    const q = query(blogPostsRef);
    const snapshot = await getDocs(q);
    const posts = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as BlogPost[];
    
    const normalizedInputSlug = normalizeSlug(slug);
    const post = posts.find(p => {
      const postSlug = normalizeSlug(p.title);
      return postSlug === normalizedInputSlug;
    });
    
    return post || null;
  } catch (error) {
    console.error("Error getting blog post:", error);
    throw error;
  }
};

export const getLatestBlogPosts = async (limitCount: number = 3): Promise<BlogPost[]> => {
  try {
    const q = query(blogPostsRef, orderBy('date', 'desc'), limit(limitCount));
    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as BlogPost[];
  } catch (error) {
    console.error("Error getting latest blog posts:", error);
    throw error;
  }
};

export const updateBlogPost = async (id: string, post: Partial<BlogPost>) => {
  try {
    const docRef = doc(db, 'blog-posts', id);
    await updateDoc(docRef, post);
  } catch (error) {
    console.error("Error updating blog post:", error);
    throw error;
  }
};

export const deleteBlogPost = async (id: string) => {
  try {
    const docRef = doc(db, 'blog-posts', id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error("Error deleting blog post:", error);
    throw error;
  }
};

// Firebase operations for projects
export const getProjects = async (): Promise<Project[]> => {
  try {
    const q = query(projectsRef);
    const snapshot = await getDocs(q);
    const projects = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    })) as Project[];
    return projects;
  } catch (error) {
    console.error("Error getting projects:", error);
    throw error;
  }
};

export const addProject = async (project: Omit<Project, 'id'>) => {
  try {
    const docRef = await addDoc(projectsRef, project);
    return { ...project, id: docRef.id };
  } catch (error) {
    console.error("Error adding project:", error);
    throw error;
  }
};

export const updateProject = async (id: string, project: Partial<Project>) => {
  try {
    const docRef = doc(db, 'projects', id);
    await updateDoc(docRef, project);
  } catch (error) {
    console.error("Error updating project:", error);
    throw error;
  }
};

export const deleteProject = async (id: string) => {
  try {
    const docRef = doc(db, 'projects', id);
    await deleteDoc(docRef);
  } catch (error) {
    console.error("Error deleting project:", error);
    throw error;
  }
};
